import { Typography } from '@mui/material'
import React from 'react'
import UserAvatar from './UserAvatar'

export const ConversationHeader = props => {
  const { currentConversation, userId } = props
  const multiple = currentConversation?.users?.length > 2 || false
  const otherUser = !multiple ? currentConversation?.users?.find(user => user.id != userId) : {}
  const lastSeenDate = otherUser?.lastSeen
  return multiple ? (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: 10, }}>
      {currentConversation.users.map(user => (
        <UserAvatar key={user.id} user={user} />
      ))}
      <Typography fontFamily='roboto' sx={{ minWidth: 100 }}>
        {currentConversation.name}
      </Typography>
    </div>
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: 10 }}>
      <UserAvatar user={otherUser} />
      <Typography fontFamily='roboto'>
        {otherUser?.fullName}
      </Typography>
      <Typography fontFamily='roboto' fontSize={11}>
        {lastSeenDate ? (
          Math.abs(
            (Math.floor(Date.now() / 1000)) - (Math.floor(Date.parse(lastSeenDate) / 1000))
          ) < 600 ? 'Activo ahora' : `Ultima conexión ${new Date(Date.parse(lastSeenDate)).toLocaleString()}`
        ) : ('')}
      </Typography>
    </div>
  )
}
