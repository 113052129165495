import axios from 'axios'
import { createSelector } from 'redux-bundler'
import { tokenHeader } from '../utils'

export const types = {
  OPEN_ECG_DIALOG: 'OPEN_ECG_DIALOG',
  CLOSE_ECG_DIALOG: 'CLOSE_ECG_DIALOG',
  SET_SELECTED_ECG_DIALOG: 'SET_SELECTED_ECG_DIALOG',
  SET_SELECTED_ECG_ID_DIALOG: 'SET_SELECTED_ECG_ID_DIALOG',
  FETCH_DIALOG_DATA_START: 'FETCH_DIALOG_DATA_START',
  FETCH_DIALOG_DATA_SUCCESS: 'FETCH_DIALOG_DATA_SUCCESS',
  FETCH_DIALOG_DATA_ERROR: 'FETCH_DIALOG_DATA_ERROR',
  FETCH_HISTORIC_DATA_START: 'FETCH_HISTORIC_DATA_START',
  FETCH_HISTORIC_DATA_SUCCESS: 'FETCH_HISTORIC_DATA_SUCCESS',
  FETCH_HISTORIC_DATA_ERROR: 'FETCH_HISTORIC_DATA_ERROR',
  FETCH_CHANGE_HISTORIES_START: 'FETCH_CHANGE_HISTORIES_START',
  FETCH_CHANGE_HISTORIES_SUCCESS: 'FETCH_CHANGE_HISTORIES_SUCCESS',
  FETCH_CHANGE_HISTORIES_ERROR: 'FETCH_CHANGE_HISTORIES_ERROR',
  REACTED_TO_URL_CHANGE: 'REACTED_TO_URL_CHANGE',
  SET_OPENED_FROM_TABLE: 'SET_OPENED_FROM_TABLE',
  SET_UNSAVED_ECGS: 'SET_UNSAVED_ECGS',
  SET_ECG_DIALOG_UNSAVED: 'SET_ECG_DIALOG_UNSAVED',
  SET_ECG_DIALOG_INITIAL_STATE: 'SET_ECG_DIALOG_INITIAL_STATE',
}

const initialState = {
  open: false,
  selected: null,
  selectedId: null,
  shouldFetch: false,
  ecgDialogHistoricEcgs: [],
  ecgDialogChangeHistories: [],
  loading: false,
  lastFetch: null,
  lastError: null,
  previousSelectedId: null,
  openedFromTable: false,
  unsavedEcgs: {},
  unsaved: false,
}

export default {
  name: 'ecgDialog',
  reducer: (state = initialState, action) => {
    let aux
    switch (action.type) {
    case types.SET_ECG_DIALOG_INITIAL_STATE:
      return { ...initialState }
    case types.REACTED_TO_URL_CHANGE:
      return {
        ...state
      }
    case types.OPEN_ECG_DIALOG:
      return {
        ...state,
        open: true,
      }
    case types.CLOSE_ECG_DIALOG:
      return {
        ...state,
        open: false,
        openedFromTable: false,
        previousSelectedId: null,
        ecgDialogHistoricEcgs: [],
        ecgDialogChangeHistories: [],
      }
    case types.SET_OPENED_FROM_TABLE:
      return {
        ...state,
        openedFromTable: action.payload,
      }
    case types.SET_SELECTED_ECG_DIALOG:
      return {
        ...state,
        selected: action.payload,
        selectedId: action.payload?.id,
        previousSelectedId: state?.selectedId,
      }
    case types.SET_SELECTED_ECG_ID_DIALOG:
      return {
        ...state,
        selectedId: action.payload,
        previousSelectedId: state?.selectedId,
      }
    case types.FETCH_DIALOG_DATA_START:
      return {
        ...state,
        loading: true,
      }
    case types.FETCH_DIALOG_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: action.payload,
        selectedId: action.payload?.id,
        lastFetch: Date.now(),
      }
    case types.FETCH_DIALOG_DATA_ERROR:
      return {
        ...state,
        loading: false,
        selected: null,
        selectedId: null,
        lastError: Date.now(),
        shouldFetch: true,
      }
    case types.FETCH_HISTORIC_DATA_START:
      return {
        ...state,
        loading: true,
      }
    case types.FETCH_HISTORIC_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        lastFetch: Date.now(),
        ecgDialogHistoricEcgs: action.payload,
      }
    case types.FETCH_HISTORIC_DATA_ERROR:
      return {
        ...state,
        loading: false,
        ecgDialogHistoricEcgs: [],
        lastError: Date.now(),
        shouldFetch: true,
      }
    case types.FETCH_CHANGE_HISTORIES_START:
      return {
        ...state,
        loading: true,
      }
    case types.FETCH_CHANGE_HISTORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        lastFetch: Date.now(),
        ecgDialogChangeHistories: action.payload,
      }
    case types.FETCH_CHANGE_HISTORIES_ERROR:
      return {
        ...state,
        loading: false,
        ecgDialogChangeHistories: [],
        lastError: Date.now(),
        shouldFetch: true,
      }
    case types.SET_UNSAVED_ECGS:
      localStorage.setItem('unsavedEcgs', JSON.stringify(action.payload))
      aux = action.payload[state.selectedId] ?? false
      return {
        ...state,
        unsavedEcgs: action.payload,
        unsaved: !!aux,
      }
    case types.SET_ECG_DIALOG_UNSAVED:
      return {
        ...state,
        unsaved: action.payload,
      }
    default:
      return state
    }
  },
  selectEcgDialogRaw: state => state.ecgDialog,
  selectCurrentEcg: state => state.ecgDialog.selected,
  selectCurrentEcgId: state => state.ecgDialog.selectedId,
  selectOpenEcgDialog: state => state.ecgDialog.open,
  selectLoadingSelectedEcg: state => state.ecgDialog.loading,
  selectEcgDialogHistoricEcgs: state => state.ecgDialog.ecgDialogHistoricEcgs,
  selectEcgDialogChangeHistories: state => state.ecgDialog.ecgDialogChangeHistories,
  selectUnsavedEcgs: state => state.ecgDialog.unsavedEcgs,
  selectEcgDialogUnsaved: state => state.ecgDialog.unsaved,
  doSetUnsavedEcgs: unsavedEcgs => ({ dispatch }) => dispatch({ type: types.SET_UNSAVED_ECGS, payload: unsavedEcgs }),
  doDeleteEcgFromUnsaveds: id => ({ store }) => {
    const currentUnsavedsBuffer = store.selectUnsavedEcgs()
    const unsavedData = currentUnsavedsBuffer[id] ?? false
    if (!unsavedData) return
    delete currentUnsavedsBuffer[id]
    store.doSetUnsavedEcgs(currentUnsavedsBuffer)
  },
  doSetEcgDialogInitialState: () => ({ dispatch }) => dispatch({ type: types.SET_ECG_DIALOG_INITIAL_STATE }),
  doSetEcgDialogUnsaved: bool => ({ dispatch }) => dispatch({ type: types.SET_ECG_DIALOG_UNSAVED, payload: bool }),
  doOpenEcgDialog: () => ({ dispatch }) => dispatch({ type: types.OPEN_ECG_DIALOG }),
  doCloseEcgDialog: () => ({ dispatch }) => dispatch({ type: types.CLOSE_ECG_DIALOG }),
  doSetSelectedEcg: ecg => ({ dispatch }) => dispatch({ type: types.SET_SELECTED_ECG_DIALOG, payload: ecg }),
  doSetSelectedEcgId: id => ({ dispatch }) => dispatch({ type: types.SET_SELECTED_ECG_ID_DIALOG, payload: id }),
  doSetOpenedFromTable: value => ({ dispatch }) => dispatch({ type: types.SET_OPENED_FROM_TABLE, payload: value }),
  doResetEcgDialogData: ecg => ({ store }) => {
    const cachedEcgs = store.selectCachedEcgs()
    const data = cachedEcgs[ecg.id] ?? false
    if (!data) {
      store.doSetSnackbarFail('Error al reestablecer datos.')
    }
    const { unsaved, open } = store.selectEcgDialogRaw()
    if (unsaved && open) store.doSetEcgDialogUnsaved(false)
    store.doDeleteEcgFromUnsaveds(ecg.id)
    store.doSetSelectedEcg(data)
    store.doSetSnackbarInfo('Se deshicieron los cambios.')
    return data
  },
  doFetchEcgDialogData: (ecg = null) => async ({ dispatch, store }) => {
    dispatch({ type: types.FETCH_DIALOG_DATA_START })
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    let res
    let validId = store.selectCurrentEcgId()
    if (validId === null && ecg?.id !== undefined) validId = ecg.id
    else store.doSetAppIdle()
    if (!validId) return
    const currentUnsavedsBuffer = store.selectUnsavedEcgs()

    let ecgData
    const cachedEcgs = store.selectCachedEcgs()
    let correct = true
    try {
      res = await axios.get(`/api/ecg/${validId}/`, tokenHeader(access))
      ecgData = res.data
      cachedEcgs[ecgData.id] = ecgData
      store.doSetCachedEcgs(cachedEcgs)
      dispatch({ type: types.FETCH_DIALOG_DATA_SUCCESS, payload: ecgData })
      store.doUpdateECGData(ecgData)
      await store.doFetchEcgChangeHistory()
      const unsavedData = currentUnsavedsBuffer[ecgData.id] ?? false
      if (unsavedData) store.doSetEcgDialogUnsaved(true)
      store.doSetAppIdle()
    } catch (error) {
      dispatch({ type: types.FETCH_DIALOG_DATA_ERROR })
      store.doSetSnackbarFail(`Error al cargar información de estudio. ID: ${validId}`)
      if (store.selectIsOnline()) {
        store.doCloseEcgDialog()
      }
      store.doSetAppIdle()
      correct = false
    }

    dispatch({ type: types.FETCH_HISTORIC_DATA_START })
    try {
      res = await axios.get(`/api/patientHistory/?id=${ecgData.patient.id}`, tokenHeader(access))
      const patientHistory = res.data
      dispatch({ type: types.FETCH_HISTORIC_DATA_SUCCESS, payload: patientHistory })
    } catch (error) {
      dispatch({ type: types.FETCH_HISTORIC_DATA_ERROR })
      store.doSetAppIdle()
      correct = false
    }
    return correct
  },
  doFetchHistoricData: (ecg = null) => async ({ dispatch, store }) => {
    store.doSetAppLoading()
    const access = store.selectAccessToken()
    if (!store.selectIsOnline()) return
    let res
    let validId = ecg?.patient?.id
    dispatch({ type: types.FETCH_HISTORIC_DATA_START })
    try {
      res = await axios.get(`/api/patientHistory/?id=${validId}`, tokenHeader(access))
      const patientHistory = res.data
      store.doSetAppIdle()
      dispatch({ type: types.FETCH_HISTORIC_DATA_SUCCESS, payload: patientHistory })
    } catch (error) {
      dispatch({ type: types.FETCH_HISTORIC_DATA_ERROR })
      store.doSetAppIdle()
    }
  },
  doFetchEcgChangeHistory: (ecg = null) => async ({ dispatch, store }) => {
    const kind = store.selectKind()
    if (kind > 1) return
    const access = store.selectAccessToken()
    let validId = store.selectCurrentEcgId()
    if (validId === null && ecg?.id !== undefined) validId = ecg.id
    if (!validId) return
    let res
    dispatch({ type: types.FETCH_CHANGE_HISTORIES_START })
    store.doSetAppLoading()
    try {
      res = await axios.get(`/api/ecg/${validId}/changeHistory/`, tokenHeader(access))
      dispatch({ type: types.FETCH_CHANGE_HISTORIES_SUCCESS, payload: res.data })
      store.doSetAppIdle()
    } catch (error) {
      dispatch({ type: types.FETCH_CHANGE_HISTORIES_ERROR })
      store.doSetAppIdle()
    }
  },
  doOpenEcgDialogOnUrlUpdate: (id, fromTable=false) => async ({ store }) => {
    store.doSetOpenedFromTable(fromTable)
    store.doSetSelectedEcgId(id)
    await store.doFetchEcgDialogData()
    const kind = store.selectKind()
    if (kind <= 1) await store.doFetchEcgChangeHistory()
    const selected = store.selectCurrentEcg()
    if (!selected) {
      store.doSetSelectedEcgId(null)
      store.doSetSelectedEcg(null)
      store.doUpdateUrl('/')
      store.doSetSnackbarFail(`ECG con ID: ${id} no encontrado.`)
      return
    }
    store.doOpenEcgDialog()
  },
  // reactShouldFetchSelected: createSelector(
  //   'selectOpenEcgDialog',
  //   'selectCurrentEcgId',
  //   'selectCurrentEcg',
  //   (open, currentId, current) => {
  //     if (currentId === null) return
  //     if (!open) return
  //     if (current !== null) return
  //     return { actionCreator: 'doFetchEcgDialogData' }
  //   },
  // ),
  reactToUrlChange: createSelector(
    'selectIsAuthenticated',
    'selectRouteParams',
    'selectEcgDialogRaw',
    (isAuthenticated, params, ecgDialogRaw) => {
      if (!isAuthenticated) return
      if (ecgDialogRaw.loading) return
      if (!params?.ecgId) return
      if (params.ecgId === ecgDialogRaw.previousSelectedId) return
      if (ecgDialogRaw.openedFromTable) return
      if (ecgDialogRaw.open) return
      return { actionCreator: 'doOpenEcgDialogOnUrlUpdate', args: [parseInt(params.ecgId, 10)] }
    },
  ),
  init: store => {
    const cachedUnsavedEcgs = localStorage.getItem('unsavedEcgs') ?? '{}'
    const cachedUnsavedEntities = JSON.parse(cachedUnsavedEcgs)
    store.doSetUnsavedEcgs(cachedUnsavedEntities)
  },
}
