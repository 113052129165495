import React from 'react'
import PatientDataFormEdit from './EditForm'
import { chewAPIEcgDataToForm } from '../../../../utils/validationSchemas'

const PatientData = props => {
  const { ecgData } = props
  const chewed = chewAPIEcgDataToForm(ecgData)
  return (
    <div style={{ minWidth: '100%', maxWidth: '100%', padding: 12 }}>
      <PatientDataFormEdit ecg={chewed} />
    </div>
  )
}

export default PatientData
