import React from 'react'
import { Backdrop as MaterialBackdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import { useConnect } from 'redux-bundler-hook'

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 10000,
    color: '#fff',
  },
}))

export const Backdrop = props => {
  const styles = useStyles()
  const { appState } = props
  const { loading } = appState
  const open = loading
  return (
    <MaterialBackdrop
      className={styles.backdrop}
      open={open}
      transitionDuration={200}
      sx={{
        backdropFilter: 'blur(1px)',  // Apply the blur effect
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <CircularProgress />
    </MaterialBackdrop>
  )
}

export default () => {
  const connectedProps = useConnect(
    'selectAppState',
  )
  return <Backdrop {...connectedProps} />
}

Backdrop.defaultProps = {
  appState: {
    loading: false,
  }
}
