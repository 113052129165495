import React from 'react'
import DiffItem from './DiffItem'
import { makeStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import { getDiffArray } from '../../utils/misc'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  historyItemsDiv: {
    padding: 10,
  },
  noDataDiv: {
    display: 'flex',
    gap: 20,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}))

const DiffViewComponent = props => {
  const {
    ecgChangeHistories,
  } = props
  const classes = useStyles()
  const data = getDiffArray(ecgChangeHistories)
  return (
    <div className={classes.root}>
      {ecgChangeHistories.length > 1 ? (
        <div className={classes.historyItemsDiv}>
          {data?.map((elements, index) => (
            <DiffItem key={index} elements={elements} />
          ))}
        </div>
      ) : (
        <div className={classes.noDataDiv}>
          <FontAwesomeIcon
            icon='fa-sharp fa-solid fa-clipboard'
            size='3x'
            opacity={0.1}
          />
          <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
            No hay más elementos en el historial.
          </Typography>
        </div>
      )}
    </div>
  )
}

export default React.memo(DiffViewComponent)
