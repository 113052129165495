import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import T from '../../components/common/Typography'
import { makeStyles } from '@mui/styles'
import Tabs from '@mui/material/Tabs'
import PropTypes from 'prop-types'
import Tab from '@mui/material/Tab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CentersAdmin from './Centers'
import ScienceIcon from '@mui/icons-material/Science'

export const TABS = [
  { value: 'centros', displayValue: 'Centros' },
  // { value: 'plantillas', displayValue: 'Plantillas' },
]

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: [
      '"title title title title title"',
      '"tabs tabs tabs tabs tabs"',
      '"tabContent tabContent tabContent tabContent tabContent"',
    ].join('\n'),
    gap: 5,
    gridTemplateRows: '50px 60px auto',
    width: '100%',
    height: '100%',
  },
  adminPanelTitle: {
    gridArea: 'title',
    display: 'flex',
    gap: 20,
    justifyContent: 'left',
    alignItems: 'center',
  },
  adminPanelTabs: {
    gridArea: 'tabs',
  },
  adminTabContent: {
    gridArea: 'tabContent',
  },
}))

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabPanel = props => {
  const { children, value, index } = props
  return value === index && (
    <Box sx={{ height: '100%' }}>
      {children}
    </Box>
  )
}

const AdminPanelComponent = props => {
  const {
    routeInfo,
    doUpdateUrl,
  } = props
  const { params } = routeInfo
  const { tab } = params
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.adminPanelTitle}>
        <Tooltip title='Atrás'>
          <IconButton onClick={() => doUpdateUrl('/')}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <T.H4 style={{ display: 'flex', gap: 15, alignItems: 'baseline' }}>
          Panel de Administración
          <Tooltip title='Experimental'>
            <ScienceIcon color='secondary' />
          </Tooltip>
        </T.H4>
      </div>
      <div className={classes.adminPanelTabs}>
        <Tabs value={TABS.findIndex(t => t.value === tab)} onChange={(event, index) => doUpdateUrl(`/administracion/${TABS[index].value}`)}>
          {TABS.map((tab, index) => (
            <Tab key={tab.value} label={tab.displayValue} {...a11yProps(index)} />
          ))}
        </Tabs>
      </div>
      <div className={classes.adminTabContent}>
        <TabPanel value={TABS.findIndex(t => t.value === tab)} index={0}>
          <CentersAdmin />
        </TabPanel>
        <TabPanel value={TABS.findIndex(t => t.value === tab)} index={1}>
          Plantillas
        </TabPanel>
      </div>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectRouteInfo',
    'doSetAdminPanelTab',
    'doUpdateUrl',
  )
  return <AdminPanelComponent {...props} {...connectedProps} />
}
