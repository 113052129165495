import { makeStyles } from '@mui/styles'
import React, { useMemo } from 'react'
import { orderedAilments } from '../../../utils/formVars'
import { chunkify, noop } from '../../../utils/misc'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Checkbox from '../../common/Checkbox'
import StatusLedComponent from '../../common/StatusLed'
import { Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { getParametersObj } from '../../../utils/ecg'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  patientData: {
    display: 'flex',
    marginBottom: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  tableCell: {
    fontSize: '1rem',
  }
}))

const HistoryElement = props => {
  const { ecg, currentId } = props
  const isSelected = currentId === ecg.id
  const classes = useStyles()
  const parameters = getParametersObj(ecg)
  const chunks = chunkify(orderedAilments, 5, true)
  const ailmentCheckboxes = useMemo(() => chunks.map((chunk, index) => (
    <div key={`mobile-ailments-col-${index + 1}`} className={classes.row}>
      {chunk.map(ailment => (
        <div key={ailment.name} style={{ maxWidth: '50%', minWidth: '50%' }}>
          <Checkbox
            key={ailment.name}
            name={ailment.name}
            checked={ecg[ailment.name]}
            label={ailment.displayName}
            labelPlacement='end'
            color='secondary'
            onChange={noop}
            size='small'
          />
        </div>
      ))}
    </div>
  )), [ecg])

  return (
    <Accordion sx={{ marginBottom: 1, borderRadius: 2, backgroundColor: isSelected ? '#f0f0f0' : null }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <div className={classes.row}>
          <StatusLedComponent ecg={ecg} />
          <Typography fontWeight={isSelected ? 500 : 300} fontSize={16} fontFamily='roboto'>
            <div className={classes.row}>
              {ecg.localizedTs}
            </div>
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div name='root' className={classes.root}>
          <div name='patient-data' className={classes.patientData}>
            <TableContainer>
              <Table size='small'>
                <TableBody>
                  {parameters.map(data => (
                    <TableRow key={data.param}>
                      <TableCell width='10%'>
                        <Typography fontSize='1rem'>
                          {data.param}
                        </Typography>
                      </TableCell>
                      <TableCell width='90%'>
                        <Typography fontSize='calc(1rem - 2px)' fontWeight={300} fontFamily='roboto'>
                          {data.value}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div name='ailments' className={classes.column}>
            {ailmentCheckboxes}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default HistoryElement
