// import { createSelector } from 'redux-bundler'
// import { replaceStringTemplate } from '../utils/string'

import { createSelector } from 'redux-bundler'

export const types = {
  SET_APP_IDLE: 'SET_APP_IDLE',
  SET_APP_LOADING: 'SET_APP_LOADING',
  SET_IS_MOBILE: 'SET_IS_MOBILE',
  SET_TITLE: 'SET_TITLE',
}

const publicUrls = ['/login', '/forgot', '/reset', '/reportarFraude', '/p/r/']

const initialState = {
  loading: false,
  isMobile: window.matchMedia('screen and (max-width: 760px)').matches,
  title: 'CIDC',
  lastTitle: 'CIDC',
}

export default {
  name: 'appState',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.SET_APP_IDLE:
      return {
        ...state,
        loading: false,
      }
    case types.SET_APP_LOADING:
      return {
        ...state,
        loading: true,
      }
    case types.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      }
    case types.SET_TITLE:
      return {
        ...state,
        title: action.payload,
        lastTitle: state.title,
      }
    default:
      break
    }
    return state
  },
  selectAppState: state => state.appState,
  selectIsMobile: state => state.appState.isMobile,
  selectAppTitle: state => state.appState.title,
  selectLastAppTitle: state => state.appState.lastTitle,
  selectAppLoading: state => state.appState.loading,
  selectIsPublicRoute: createSelector(
    'selectRoute',
    (route) => route?.isPublic ?? false,
  ),
  doSetAppIdle: () => ({ dispatch }) => dispatch({ type: types.SET_APP_IDLE }),
  doSetAppLoading: () => ({ dispatch }) => dispatch({ type: types.SET_APP_LOADING }),
  doSetIsMobile: isMobile => ({ dispatch }) => dispatch({ type: types.SET_IS_MOBILE, payload: isMobile }),
  doUpdateAppTitle: title => ({ dispatch }) => dispatch({ type: types.SET_TITLE, payload: title }),
  reactChangeRoute: createSelector(
    'selectIsAuthenticated',
    'selectPathname',
    'selectAuthLoading',
    (isAuth, pathname, authLoading) => {
      if (authLoading) return
      if (pathname.startsWith('/p/')) return
      if (isAuth && publicUrls.includes(pathname)) {
        return { actionCreator: 'doUpdateUrl', args: ['/'] }
      }
      if (!isAuth && pathname === '/') {
        return { actionCreator: 'doUpdateUrl', args: ['/login'] }
      }
      if (!isAuth && !publicUrls.includes(pathname)) {
        return { actionCreator: 'doUpdateUrl', args: ['/login'] }
      }
    },
  ),
}
