import createTableBundle from '../tableBundle'

const config = {
  bundleName: 'ReportTemplateAdmin',
  camelCaseBundleName: 'reportTemplateTable',
  verboseName: 'plantilla de reporte',
  url: 'reportTemplatesList',
  allowDelete: true,
}

const baseBundle = createTableBundle(config)

export default {
  ...baseBundle,
}
