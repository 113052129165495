import { Button, Typography } from '@mui/material'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import Backdrop from '../components/common/Backdrop'
import Snackbar from '../components/common/Snackbar'

const pkg = require('../../package.json')

const versionBannerSyles = {
  height: 48,
  position: 'absolute',
  width: '100vw',
  backgroundColor: '#a2ffb6',
  color: '#444',
  '@media (maxHeight: 767px)': {
    width: '25%',
    position: 'fixed',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1202,
    padding: '0 1rem',
    '& h6': {
      lineHeight: 'normal',
    },
    '$prodBanner + &': {
      transform: 'none',
    }
  },
  '@media print': {
    display: 'none'
  }
}


const UpdateComponent = props => (
  pkg?.version === localStorage.getItem('latestVersion') || props.server.loading ? null : process.env.REACT_APP_ENVIRONMENT === 'production' && (
    <Button style={versionBannerSyles} onClick={() => window.location.reload(true)}>
      <Typography variant="subtitle1" color="inherit" align="center">
        Nueva&nbsp;version&nbsp;disponible.&nbsp;Haga&nbsp;click&nbsp;para&nbsp;actualizar.
      </Typography>
    </Button>
  )
)

const VersionComponent = () => {
  const {
    server,
    isAuthenticated,
    isMobile,
  } = useConnect(
    'selectServer',
    'selectIsAuthenticated',
    'selectIsMobile',
  )
  const { backendVersion } = server
  return isAuthenticated && !isMobile && (
    <div style={{ position: 'fixed', bottom: 0, right: 10, zIndex: 10000 }}>
      <Typography variant="subtitle2">
        {`v${pkg?.version} / v${backendVersion}`}
      </Typography>
    </div>
  )
}

const Globals = props => {
  const { isPublicRoute, server } = props
  return (
    <>
      <Backdrop />
      <Snackbar />
      {isPublicRoute ? null : <VersionComponent />}
      {isPublicRoute ? null : <UpdateComponent server={server} />}
    </>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectServer',
    'selectIsAuthenticated',
    'selectIsMobile',
    'selectRoutes',
    'selectIsPublicRoute',
  )
  return <Globals {...props} {...connectedProps} />
}
