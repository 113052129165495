import React from 'react'
import PropTypes from 'prop-types'
import { getLedColor } from '../../utils/ecg'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import { Tooltip } from '@mui/material'
import { useConnect } from 'redux-bundler-hook'

const StatusLedComponent = props => {
  const { ecg, unsavedIds = [] } = props
  const isUnsaved = unsavedIds.includes(String(ecg.id))
  const ledColor = getLedColor(ecg)
  return (
    <div
      style={{
        backgroundColor: ledColor,
        borderRadius: 100,
        width: 30,
        height: '100%',
        marginRight: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isUnsaved && (
        <Tooltip arrow placement='top' title='Cambios sin guardar'>
          <HistoryToggleOffIcon color='#222222' />
        </Tooltip>
      )}
    </div>
  )
}

StatusLedComponent.propTypes = {
  ecg: PropTypes.shape({
    redCode: PropTypes.bool.isRequired,
    isReported: PropTypes.bool.isRequired,
    hasTrace: PropTypes.bool.isRequired,
  }),
  unsavedIds: PropTypes.array,
}

export default props => {
  const connectedProps = useConnect(
    'selectUnsavedEcgs',
  )
  const { unsavedEcgs } = connectedProps
  const unsavedIds = Object.keys(unsavedEcgs)
  return <StatusLedComponent {...props} unsavedIds={unsavedIds} />
}
