import axios from 'axios'
import { createSelector } from 'redux-bundler'

export const types = {
  SET_REPORT_UUID: 'SET_REPORT_UUID',
  FETCH_REPORT: 'FETCH_REPORT',
  FETCH_REPORT_SUCCESS: 'FETCH_REPORT_SUCCESS',
  FETCH_REPORT_ERROR: 'FETCH_REPORT_ERROR',
}

export const initialState = {
  report: null,
  reportUuid: null,
  reportError: null,
  reportLoading: false,
}

export default {
  name: 'publicReports',
  getReducer: () => {
    return (state = initialState, { type, payload }) => {
      switch (type) {
      case types.SET_REPORT_UUID:
        return {
          ...state,
          reportUuid: payload,
        }
      case types.FETCH_REPORT:
        return {
          ...state,
          reportLoading: true,
          reportError: null,
        }
      case types.FETCH_REPORT_SUCCESS:
        return {
          ...state,
          report: payload.report,
          reportLoading: false,
        }
      case types.FETCH_REPORT_ERROR:
        return {
          ...state,
          reportError: payload?.error ?? 'Error desconocido. Contacte a soporte. 0x16.',
          reportLoading: false,
        }
      default:
        return state
      }
    }
  },
  doFetchPublicReport: () => async ({ dispatch, store }) => {
    dispatch({ type: types.FETCH_REPORT })
    const uuid = store.selectReportUuid()
    let res
    try {
      res = await axios.post('/api/ecg/public/report/', { uuid })
      dispatch({ type: types.FETCH_REPORT_SUCCESS, payload: res.data })
      return true
    } catch (error) {
      dispatch({ type: types.FETCH_REPORT_ERROR, payload: error?.response?.data })
      const errorMessage = 'Error al cargar el reporte.'
      store.doSetSnackbarFail(errorMessage)
      return false
    }
  },
  doSetReportUuid: (uuid) => ({ type: types.SET_REPORT_UUID, payload: uuid }),
  selectPublicReportsRaw: state => state.publicReports,
  selectReport: state => state.publicReports.report,
  selectReportUuid: state => state.publicReports.reportUuid,
  selectReportError: state => state.publicReports.reportError,
  selectReportLoading: state => state.publicReports.reportLoading,
  reactFetchReport: createSelector(
    'selectPublicReportsRaw',
    (raw) => {
      if (raw.reportError) return
      if (raw.reportLoading) return
      if (!raw.reportUuid) return
      if (raw.report !== null) return
      return { actionCreator: 'doFetchPublicReport' }
    }
  ),
  init: store => {
    const params = store.selectRouteParams()
    if (params.encodedId && !store.selectReportUuid()) {
      store.doSetReportUuid(params.encodedId)
    }
  }
}
