
import createModelBundle from './modelBundle'


const config = {
  bundleName: 'ReportTemplate',
  camelCaseBundleName: 'reportTemplate',
  verboseName: 'plantilla de reporte',
  url: 'reportTemplates',
  allowDelete: true,
}

const baseBundle = createModelBundle(config)

export default {
  ...baseBundle,
}
