import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import TextField from '../components/common/TextField'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Typography } from '@mui/material'
import { useConnect } from 'redux-bundler-hook'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgb(247,247,247)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
}))

const ForgotFormComponent = props => {
  const classes = useStyles()
  const [sent, setSent] = useState(false)
  const {
    doSendResetLink,
  } = props

  const helperTextStylesError = {
    color: 'red'
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, actions) => {
      const success = await doSendResetLink(values.email)
      actions.setSubmitting(false)
      setSent(success)
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Campo requerido'),
    }),
    validateOnChange: true,
  })
  return (
    <div className={classes.root}>
      {sent ? (
        <Typography fontSize={20}>Hemos enviado un link al email para reestablecer su contraseña.</Typography>
      ) : (
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Typography fontSize={20}>Escriba el email registrado con su cuenta</Typography>
          <TextField
            autoFocus
            placeholder='Correo electrónico'
            adornmentPosition='start'
            name='email'
            id='email'
            onValueChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperTextStyles={helperTextStylesError}
            helperText={formik.touched.email && formik.errors.email ? (
              formik.errors.email
            ) : null}
            styles={{
              texttransform: 'lowercase',
              minWidth: '50vw',
            }}
            disabled={formik.isSubmitting}
            autoCapitalize='off'
          />
          <Button
            type='submit'
            variant='outlined'
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
          >
            Enviar
          </Button>
        </form>
      )}
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'doSendResetLink',
  )
  return <ForgotFormComponent {...props} {...connectedProps} />
}
