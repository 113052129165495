import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import Layout from './LayoutWrapper'
import { Helmet } from 'react-helmet'
import Spinner from '../components/common/Spinner'
import { kindsById } from '../bundles/auth'


export const getDataByKey = (context, key) => {
  switch (key) {
  case 'username':
    return context.user?.username
  case 'patientName':
    return context.currentEcg?.patient?.name ?? 'Cargando...'
  case 'ecgId':
    return context.params?.ecgId
  case 'fullName':
    return context.user?.fullName ?? 'Cargando...'
  case 'kind':
    return kindsById[context.kind]
  default:
    return ''
  }
}

const App = props => {
  const {
    route,
    isAuthenticated,
    authLoading,
    appLoading,
    kind,
    routeParams,
    currentEcg,
    // doUpdateUrl,
    // appTitle,
    user,
  } = props
  const {
    component: Component,
    titleTemplate,
    // permission = 3,
  } = route
  // if (permission < kind) {
  //   doUpdateUrl('/not-found')
  //   return null
  // }
  // TODO: add an extra permissions prop to each route and check if the current user (if authenticated) can be admitted
  // title template is a js template string, replace it with a context object
  // use the context object to replace the template string
  const context = {
    user,
    route,
    kind,
    currentEcg,
    params: routeParams,
  }
  const title = titleTemplate.replace(/\${(.*?)}/g, (_, key) => getDataByKey(context, key))

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isAuthenticated ? (
        <Layout component={Component} />
      ) : authLoading || appLoading ? (
        <div
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <Component />
      )}
    </>
  )
}

export default () => {
  const connectedProps = useConnect(
    'selectRoute',
    'selectAuthLoading',
    'selectAppLoading',
    'selectPathname',
    'selectIsAuthenticated',
    'doUpdateUrl',
    'selectRouteParams',
    'selectUser',
    'selectAppTitle',
    'selectKind',
    'selectCurrentEcgId',
    'selectCurrentEcg',
  )
  return <App {...connectedProps} />
}
