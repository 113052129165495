import axios from 'axios'
import { tokenHeader } from '../utils'
import { utf8ToBase64 } from '../utils/string'

export const types = {
  SET_EDIT_EXISTING: 'SET_EDIT_EXISTING',
  SET_SELECTED: 'SET_SELECTED',
  SAVE_SELECTED: 'SAVE_SELECTED',
  GENERATE_START: 'GENERATE_START',
  GENERATE_SUCCESS: 'GENERATE_SUCCESS',
  GENERATE_FAILURE: 'GENERATE_FAILURE',
  SET_CONTENT: 'SET_CONTENT',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
  SET_LAST_ERROR: 'SET_LAST_ERROR',
  CLEAR_CONTENT: 'CLEAR_CONTENT',
  CLEAR_RESULT: 'CLEAR_RESULT',
  SET_RESULT: 'SET_RESULT',
  RESET_STATE: 'RESET_STATE',
}

const initialState = {
  editExisting: false,
  selected: null,
  selectedId: null,
  content: '',
  loading: false,
  previousSelectedId: null,
  lastGenerated: null,
  error: null,
  lastError: null,
  result: null,
}

export default {
  // TODO: add bundle to control the state of the text editor
  name: 'reportSimulator',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.RESET_STATE:
      return {
        ...initialState,
      }
    case types.SET_EDIT_EXISTING:
      return {
        ...state,
        editExisting: action.payload,
      }
    case types.SET_SELECTED:
      return {
        ...state,
        editExisting: true,
        selected: action.payload,
        selectedId: action.payload.id,
        content: action.payload?.content ?? '',
      }
    case types.GENERATE_START:
      return {
        ...state,
        loading: true,
      }
    case types.GENERATE_SUCCESS:
      return {
        ...state,
        loading: false,
        lastGenerated: Date.now(),
        result: action.payload,
        error: null,
        lastError: null,
      }
    case types.GENERATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        lastError: Date.now(),
      }
    case types.CLEAR_CONTENT:
      return {
        ...state,
        content: '',
      }
    case types.SET_CONTENT:
      return {
        ...state,
        content: action.payload,
      }
    default:
      return state
    }
  },
  selectReportSimulatorRaw: state => state.reportSimulator,
  doSimulatorSetSelectedHTMLTemplate: selected => ({
    type: types.SET_SELECTED,
    payload: selected,
  }),
  doSimulatorClearContent: () => ({
    type: types.CLEAR_CONTENT,
  }),
  doSimulatorSetContent: content => ({
    type: types.SET_CONTENT,
    payload: content,
  }),
  doResetSimulatorState: () => ({
    type: types.RESET_STATE,
  }),
  doSimulatorGenerateReport: (htmlString) => async ({ dispatch, store }) => {
    dispatch({ type: types.GENERATE_START })
    const access = store.selectAccessToken()
    let res
    // base64 encode the html string
    const encodedHtml = utf8ToBase64(htmlString)
    const payload = {
      html: encodedHtml,
    }
    const opts = tokenHeader(access)
    try {
      res = await axios.post('/api/reportPreview/', payload, opts)
      dispatch({ type: types.GENERATE_SUCCESS, payload: res.data.url })
      return res.data
    } catch (error) {
      dispatch({ type: types.GENERATE_FAILURE, payload: error })
      store.doSetSnackbarFail('Error al generar el reporte' + String(error))
      return false
    }
  },
}
