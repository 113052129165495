import React from 'react'
import ReactDiffViewer from 'react-diff-viewer'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const DiffItem = props => {
  const {
    elements,
  } = props
  const { before, after } = elements
  const afterDate = new Date(Date.parse(after.historyDate))
  return (
    <Accordion sx={{ marginBottom: 1, borderRadius: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {afterDate.toLocaleString()}
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <ReactDiffViewer
            oldValue={JSON.stringify(before, null, 2)}
            newValue={JSON.stringify(after, null, 2)}
            splitView
            hideLineNumbers
            styles={{ contentText: { fontSize: 13 } }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default DiffItem
