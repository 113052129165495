/* eslint-disable max-len */
import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  List,
  Dialog,
  Paper,
  DialogContent,
} from '@mui/material'

export const ModalPolitics = props => {
  const {
    handleSubmit,
    open,
    handleClose,
  } = props
  const [checked, setChecked] = useState(false)

  const breaks = (
    <>
      <br />
      <br />
    </>
  )

  return (
    <Dialog
      open={open}
      onClose={() => {
        setChecked(false)
        handleClose()
      }}
      fullWidth
      maxWidth='sm'
    >
      <DialogContent>
        <Paper style={{ overflow: 'auto', padding: '15px', textAlign: 'justify', fontFamily: 'roboto' }}>
          <List>
            <p>
              <span className="nunito titlePolitics">Política de Privacidad</span>
              <br />
              <span className="nunito title1Politics">Aviso de Privacidad</span>
              {breaks}
              <span className="nunito subtitlePolitics b1">Introducción</span>
              {breaks}
              <span className="nunito subtitlePolitics">
                Dentro de nuestra Política de privacidad (“Política de Privacidad”) explicamos las prácticas que llevamos a cabo para la protección de la privacidad y el uso de datos, los cuales son una cuestión de máxima prioridad para el
                {' '}
                <b>Centro Integral De Diagnóstico y Terapia Cardiovascular</b>
                {' '}
                (“nosotros” o “Centro Integral De Diagnóstico y Terapia Cardiovascular”). De esta forma, exponemos cómo recopilamos, usamos y almacenamos sus datos personales, los cuales aplican para nuestro sitio web.
                {breaks}
                Recomendamos leer atentamente el Aviso de Privacidad antes de hacer uso de nuestros servicios. Así mismo, este Aviso de Privacidad se atiene a modificaciones, en particular si, por cambios en nuestras operaciones o en las leyes y regulaciones aplicables, sus derechos y libertades se ven implicados.
                {breaks}
              </span>
              <span className="nunito subtitlePolitics b1">Quiénes somos</span>
              {breaks}
              <span className="nunito subtitlePolitics">
                El Centro Integral De Diagnóstico y Terapia Cardiovascular es un sistema de Telemedicina que opera desde la ciudad de Puebla, ubicado en Calle 20 Sur 705-305, Colonia Azcarate, C.P. 72501, Puebla, Puebla, México.
                {breaks}

                Tomando en cuenta las leyes de protección de datos de nuestro país, México, referente a la Ley Federal de Protección de Datos Personales en Posesión de Particulares sobre la protección de las personas físicas, nosotros somos los únicos responsables del tratamiento y uso de los datos obtenidos a través de los Laboratorios asociados (Family Labs, Asesores Diagnóstico Clínico, Garmar y Microgen).
                {breaks}
              </span>
              <span className="nunito subtitlePolitics b1">
                Apartados de los usuarios
              </span>
              {breaks}
              <ol type="I" className="nunito subtitlePolitics" style={{ paddingLeft: '10px' }}>
                <li style={{ paddingLeft: '15px' }}>
                  Para pacientes (usuarios solicitantes del servicio) Si es el caso de un paciente que ha solicitado los servicios para la toma de sus estudios, se aplican las siguientes condiciones:
                  {' '}
                  {breaks}
                  <ol type="A" style={{ paddingLeft: '15px' }}>
                    <li>
                      Obtención, uso y almacenamiento de datos.
                      <br />
                      <br />
                      <ol type="1" style={{ paddingLeft: '15px' }}>
                        <li>
                          Obtención, uso y almacenamiento de datos
                          {' '}
                          {breaks}
                          La obtención de estos datos personales se lleva a cabo a través del llenado del formato del Consentimiento Informado, documento que el mismo paciente responde con su propia información con el apoyo del personal capacitado de los laboratorios. La información requerida incluye:
                          {' '}
                          {breaks}
                          <ul style={{ paddingLeft: '20px' }}>
                            <li>- Nombre</li>
                            <li>- Fecha de nacimiento</li>
                            <li>- Sexo</li>
                            <li>- Presión arterial</li>
                            <li>- Estatura</li>
                            <li>- Peso</li>
                            <li>- Padecimientos </li>
                            <li>- Si cuenta con estudio de electrocardiograma previo</li>
                            <li>- Motivo del estudio</li>
                            <li>- Autorización a terceros para recoger los resultados</li>
                            <li>- Autorización del estudio</li>
                          </ul>
                          <br />
                          Esta información es requerida para contar con la aprobación del paciente para llevar a cabo sus análisis y, además, el especialista responsable de la revisión de los estudios, cuente con información relevante que pueda influir en el diagnóstico final.
                          {' '}
                          {breaks}
                          Al autorizar el estudio, está aceptando las Políticas de Privacidad de nuestros Servicios. Estas políticas constituyen un fundamento legal válido para el tratamiento de sus datos personales, de conformidad con la LFPDPPP (Ley Federal de Protección de Datos Personales en Posesión de Particulares).
                          {breaks}
                        </li>
                        <li>
                          Almacenamiento de datos y relación con el especialista.
                          {' '}
                          {breaks}
                          Para el caso del almacenamiento, una vez que se realice el registro de los datos, se va formando una una base de datos de cada paciente, el cual incluye:
                          {' '}
                          {breaks}
                          <ol type="1" style={{ paddingLeft: '30px' }}>
                            <li>Consentimiento Informado</li>
                            <li>Estudio tomado por el laboratorio</li>
                            <li>Reporte con las conclusiones del especialista</li>
                          </ol>
                          {' '}
                          <br />
                          El almacenamiento de los análisis de cada paciente se encuentra bajo el cuidado de un sistema de seguridad dedicado específicamente al resguardo de los mismos, y así evitar su difusión o que personas no autorizadas tengan acceso a las mismas.
                          {breaks}
                          En caso de solicitar un nuevo estudio, ya sea del mismo tipo u otro, el especialista tendrá acceso a un historial del paciente con la finalidad de contar con un expediente que sirva para dar seguimiento al estado de salud del paciente.
                          {breaks}
                          En caso de retirar sus estudios, el paciente tiene todo el derecho a compartir sus datos y resultados con quien sea de su voluntad. Compartir su información con su propio especialista también es su decisión, y en caso de requerir contacto directo con este último de especialista a especialista, por dudas o aclaraciones, igualmente será necesario contar con el consentimiento del paciente.
                          {breaks}
                          Bajo ninguna circunstancia sus datos están a la disposición y/o venta para algún otro servicio ajeno al que ofrecemos.
                          {breaks}
                        </li>
                      </ol>
                      <br />
                    </li>
                    <li>
                      Acceso a los resultados
                      <br />
                      <br />
                      Reiterando, únicamente el centro de la toma de los datos del paciente será el que tenga permitido el acceso a los resultados para que pueda hacérselos llegar a sus pacientes. Estos resultados se mantendrán en una base de datos, los cuales los Laboratorios también tendrán acceso en caso de que lo requieran en otro momento.
                      {breaks}
                    </li>
                  </ol>
                </li>
                <li style={{ paddingLeft: '15px' }}>
                  Para los Laboratorios (usuarios encargados de la toma de los estudios) Si es el caso de ser el Laboratorio encargado de la toma de los estudios del paciente, se aplican las siguientes condiciones:
                  {' '}
                  {breaks}
                  <ol type="A" style={{ paddingLeft: '15px' }}>
                    <li>
                      Registro y uso de nuestros servicios.
                      {breaks}
                      Cada Laboratorio tiene derecho a su propia sesión dentro de la plataforma, de esta manera solo el Centro tendrá acceso a los resultados de sus propios pacientes. Para tener acceso a su sesión, nosotros brindaremos y estableceremos las claves de acceso, las cuales sólo deberán ser conocidas por el responsable encargado de la toma de datos de Consentimientos y entrega de resultados.
                      {breaks}
                    </li>
                    <li>
                      Acceso a los resultados.
                      {breaks}
                      Reiterando, únicamente el centro de la toma de los datos del paciente será el que tenga permitido el acceso a los resultados para que pueda hacérselos llegar a sus pacientes. Estos resultados se mantendrán en una base de datos, los cuales los Laboratorios también tendrán acceso en caso de que lo requieran en otro momento.
                      {breaks}
                    </li>
                  </ol>
                </li>
              </ol>
              <span className="nunito subtitlePolitics b1">Conservación y resguardo de información </span>
              {breaks}
              <span className="nunito subtitlePolitics">
                Como parte de las políticas regidas por nuestro país, estamos obligados a mantener la información de nuestros pacientes por un periodo equivalente a 5 años.
                {' '}
                {breaks}
                {' '}
                Durante este plazo será posible que el paciente pueda hacer solicitud de estudios previos, además, al médico que interpreta los resultados de los estudios le servirá como historial del paciente para un mejor seguimiento.
                {breaks}
              </span>
              <span className="nunito subtitlePolitics b1">¿Cuáles son mis derechos respecto al tratamiento de mis datos?</span>
              {breaks}
              <span className="nunito subtitlePolitics">
                Bajo la LFPDPPP tiene los siguientes derechos:
                {breaks}
                <ol type="A" style={{ paddingLeft: '40px' }}>
                  <li>
                    Tiene derecho a tener información sobre el tratamiento de sus datos personales: tipo de datos requeridos, fines de  uso, divulgación a terceros, periodo de almacenamiento, constatación de decisiones automatizadas, elaboración de resultados. La consulta de esta Política de Privacidad forma parte de su derecho a estar informado.
                    {breaks}
                  </li>
                  <li>
                    Tiene derecho a presentar una queja ante nosotros por correo electrónico
                    {' '}
                    <a href="#">interpretaciones.ecg@gmail.com</a>
                    {' '}
                    o ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI),
                    {' '}
                    <a href="https://home.inai.org.mx/">https://home.inai.org.mx/</a>
                    , si considera que tratamos sus datos personales de manera ilegal.
                    {breaks}
                  </li>
                  <li>
                    Tiene derecho a recibir una copia de los datos personales que fueron requeridos sobre usted.
                    {breaks}
                  </li>
                  <li>
                    Tiene derecho a solicitar la rectificación y/o eliminación de sus datos personales.
                    {breaks}
                  </li>
                  <li>
                    Tiene derecho a oponerse a brindar sus datos personales, de manera que no dé su consentimiento para la realización de sus estudios médicos.
                    {breaks}
                  </li>
                  <li>
                    Tiene derecho a solicitar copias de estudios previos realizados con nosotros mientras se encuentre en el plazo de conservación y resguardo de datos.
                    {breaks}
                  </li>
                </ol>
                Puede ponerse en contacto con nosotros (consulte los datos de contacto al final de esta política) si desea hacer valer alguno de estos derechos. Cumpliremos con nuestras obligaciones legales en lo que respecta a sus derechos como interesado.
                {breaks}

                Nos reservamos el derecho de negarnos a responder cuando las solicitudes sean manifiestamente infundadas o excesivas: en este caso, le explicaremos la situación y le informaremos sobre sus derechos.
                {breaks}

                Nuestro objetivo es garantizar que la información que tenemos sobre usted sea precisa en todo momento.
                {breaks}
              </span>
              <span className="nunito subtitlePolitics b1">Datos de contacto</span>
              {breaks}
              <span className="nunito subtitlePolitics">
                Para cualquier pregunta relacionada con sus datos personales, puede contactarnos por los siguientes medios:
                {breaks}
                <ul style={{ paddingLeft: '20px' }}>
                  <li>
                    - Correo electrónico:
                    <a href="#">interpretaciones.ecg@gmail.com</a>
                  </li>
                  <li>- Ubicación física: Calle 20 Sur 705-305. Colonia Azcárate. C.P. 72040. Puebla, Puebla, México.</li>
                </ul>
                {' '}
                <br />
              </span>
            </p>
          </List>
        </Paper>
        <br />

        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
            label="He leído y estoy de acuerdo con la Política de Protección de Privacidad"
          />

          {checked && <Button variant="outlined" onClick={handleSubmit}>Aceptar</Button>}

        </FormGroup>
      </DialogContent>
    </Dialog>
  )
}
