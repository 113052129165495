import React from 'react'
import TablePatientDetail from './TablePatientDetail'
import { makeStyles } from '@mui/styles'
import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material'
import ActionButton from './ActionButton'
import { getEcgStatus } from '../../utils/ecg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { noop } from '../../utils/misc'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 65,
  },
  row: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    paddingLeft: 3,
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    maxHeight: 100,
    padding: 0,
    width: '100%',
  },
}))

const TableRow = props => {
  const { ecg, getValidActions = noop, isLastRow, userCenter, ...rest } = props
  const classes = useStyles()
  const validActions = getValidActions(ecg, userCenter)
  const status = getEcgStatus(ecg)

  return (
    <Accordion
      key={`row-mobile-${ecg?.id}`}
      disableGutters
      elevation={0}
      sx={{
        padding: 0,
        // margin: 0,
        backgroundColor: '#ffffff',
        borderRadius: 5,
        border: '1px solid 0px',
        '&:before': {
          display: 'none',
        },
        marginBottom: isLastRow ? 30 : 0,
      }}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          margin: 0,
          marginRight: 1,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <TablePatientDetail ecgData={ecg} />
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.35)' }} />
        <div className={classes.actionButtons}>
          {validActions.map(action => (
            <ActionButton
              key={`row-${action.key}${ecg?.id}`}
              isFirstRow={false}
              action={action}
              status={status}
              ecg={ecg}
              iconWidth={action?.mobileIconWidth || 30}
              {...rest}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default TableRow
