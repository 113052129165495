import React from 'react'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import { getInitials } from '../../utils/misc'
import { Tooltip } from '@mui/material'

const avatarSizes = {
  'small': { width: 12, height: 12 },
  'medium': { width: 20, height: 20 },
  'large': { width: 40, height: 40 },
}

const UserAvatar = props => {
  const { user, size } = props
  const sizeProps = avatarSizes[size] ?? {}
  const lastSeenDate = Date.parse(user.lastSeen)
  const isInvalidDate = isNaN(lastSeenDate)
  let isOnline
  if (isInvalidDate) isOnline = false
  else {
    isOnline = Math.abs((Math.floor(Date.now() / 1000)) - (Math.floor(lastSeenDate / 1000))) < 600
  }
  return (
    <Tooltip title={user.fullName || user.username}>
      <Badge color={isOnline ? 'online' : 'offline'} variant='dot' overlap='circular' badgeContent=' '>
        <Avatar sx={{ bgcolor: '#F94654', fontFamily: 'roboto', ...sizeProps }}>{getInitials(user.fullName || user.username)}</Avatar>
      </Badge>
    </Tooltip>
  )
}

export default UserAvatar
