import {
  composeBundles,
  // createCacheBundle,
} from 'redux-bundler'
import appState from './appState'
import routes from './routes'
import server from './server'
import auth from './auth'
import ecgs from './ecgs'
import centers from './centers'
import notifications from './notifications'
import snackbar from './snackbar'
import websockets from './websockets'
import drawer from './drawer'
import patients from './patients'
import ecgDialog from './ecgDialog'
import filters from './filters'
import conversations from './conversations'
import onlineBundle from 'redux-bundler/dist/online-bundle'
import fileDialog from './fileDialog'
import preferences from './preferences'
import fileBrowserDialog from './fileBrowserDialog'
import organizations from './organizations'
import { centersAdmin } from './adminPanel'
import reportTemplates from './reportTemplates'
import discountTag from './discountTag'
import reports from './public/reports'
import htmlTemplates from './htmlTemplates'
import reportSimulator from './reportSimulator'
// import cache from './cache'
// import { noop } from '../utils/misc'

export default composeBundles(
  appState,
  discountTag,
  // createCacheBundle(cache.set),
  fileDialog,
  centersAdmin,
  reports,
  reportSimulator,
  reportTemplates,
  fileBrowserDialog,
  drawer,
  preferences,
  routes,
  server,
  htmlTemplates,
  filters,
  auth,
  ecgs,
  patients,
  centers,
  snackbar,
  notifications,
  websockets,
  ecgDialog,
  conversations,
  onlineBundle,
  organizations,
)
