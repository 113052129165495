import axios from 'axios'
import { createSelector } from 'redux-bundler'
import { tokenHeader } from '../utils'

export const types = {
  GET_NOTIFICATIONS_START: 'GET_NOTIFICATIONS_START',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_ERROR: 'GET_NOTIFICATIONS_ERROR',
  SNOOZE_NOTIFICATION: 'SNOOZE_NOTIFICATION',
  UNSNOOZE_NOTIFICATION: 'UNSNOOZE_NOTIFICATION',
  NOTIFICATION_RECEIVED: 'NOTIFICATION_RECEIVED',
}

const initialState = {
  next: null,
  previous: null,
  currentPage: null,
  total: null,
  perPage: null,
  totalPages: null,
  snoozed: [],
  loading: false,
  lastFetch: null,
  lastError: null,
  notificationDialogOpen: false,
  selected: null,
  entities: [],
}

export default {
  name: 'notifications',
  reducer: (state = initialState, action) => {
    let index = null
    let newArr = null
    switch (action.type) {
    case types.GET_NOTIFICATIONS_START:
      return {
        ...state,
        loading: true,
      }
    case types.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        lastError: Date.now(),
        lastFetch: null,
      }
    case types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        lastError: null,
        lastFetch: Date.now(),
        ...action.payload,
      }
    case types.SNOOZE_NOTIFICATION:
      index = state.entities.findIndex(n => n.id === action.payload.id)
      newArr = [...state.entities]
      newArr[index] = {
        ...action.payload,
      }
      return {
        ...state,
        entities: newArr,
      }
    case types.NOTIFICATION_RECEIVED:
      return {
        ...state,
        entities: [action.payload, ...state.entities]
      }
    default:
      return state
    }
  },
  selectNotifications: state => state.notifications.entities,
  selectActiveNotifications: state => {
    let count = 0
    const entities = state.notifications.entities
    entities.forEach(n => {
      if (!n.dismissed) count += 1
    })
    return count
  },
  selectNotificationsRaw: state => state.notifications,
  doAppendNewNotification: notification => ({ dispatch }) => dispatch({ type: types.NOTIFICATION_RECEIVED, payload: notification }),
  doFetchNotifications: () => async ({ store, dispatch }) => {
    dispatch({ type: types.GET_NOTIFICATIONS_START })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/notifications/', tokenHeader(access))
      dispatch({ type: types.GET_NOTIFICATIONS_SUCCESS, payload: res.data })
    } catch (error) {
      dispatch({ type: types.GET_NOTIFICATIONS_ERROR })
    }
  },
  doSnoozeNotification: id => async ({ dispatch, store }) => {
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post(
        `/api/notifications/${id}/snooze/`,
        {},
        tokenHeader(access),
      )
      dispatch({ type: types.SNOOZE_NOTIFICATION, payload: res.data })
      return !!res?.data
    } catch (error) {
      store.doSetSnackbarFail(error?.response?.data?.error)
      return false
    }
  },
  doUnsnoozeNotification: id => async ({ dispatch, store }) => {
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.post(
        `/api/notifications/${id}/unsnooze/`,
        {},
        tokenHeader(access),
      )
      dispatch({ type: types.SNOOZE_NOTIFICATION, payload: res.data })
      return !!res?.data
    } catch (error) {
      return false
    }
  },
  reactShouldFetchNotifications: createSelector(
    'selectIsAuthenticated',
    'selectAppTime',
    'selectNotificationsRaw',
    'selectUserFlags',
    'selectIsOnline',
    (isAuth, appTime, notificationsRaw, flags, isOnline) => {
      if (!isOnline) return
      if (!isAuth) return
      if (notificationsRaw.loading) return
      if (notificationsRaw.lastError) {
        if (appTime - notificationsRaw.lastError > 10000) {
          return { actionCreator: 'doFetchNotifications' }
        }
      }
      if(notificationsRaw.lastFetch) {
        if (flags?.includes('REAL_TIME_WEBSOCKET')) return
        if (appTime - notificationsRaw.lastFetch > 60000) {
          return { actionCreator: 'doFetchNotifications' }
        }
      } else return { actionCreator: 'doFetchNotifications' }
    },
  ),
  init: store => {
    store.doFetchNotifications()
  },
}
