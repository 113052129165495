export const orderedAilments = [
  { displayName: 'Fumador (a)', name: 'smoker' },
  { displayName: 'Alcohólico (a)', name: 'alcoholic' },
  { displayName: 'Diabetes', name: 'diabetic' },
  { displayName: 'Hipertensión', name: 'hypertensive' },
  { displayName: 'Cirugía previa', name: 'previousSurgery' },
  { displayName: 'Transfusión previa', name: 'transfusions' },
  { displayName: 'Hepatitis', name: 'hepatitis' },
  { displayName: 'Enfermedad cardiaca', name: 'heartDiseaseHistory' },
  { displayName: 'Colesterol alto', name: 'cholesterol' },
]

export const parametersDisableInitialState = {
  pAxis: false,
  qrsAxis: false,
  tAxis: false,
  rR: false,
  pR: false,
  qrs: false,
  qtm: false,
  qtc: false,
  stT: false,
}
export const getParamtersDisabledInitialState = ecg => {
  const newObj = {...parametersDisableInitialState}
  Object.keys(parametersDisableInitialState).map(key => {
    if (ecg[key] === '-') newObj[key] = true
  })
  return newObj
}

export const parametersKeys = Object.keys(parametersDisableInitialState)

export const backupParamsValues = ecg => {
  if (!ecg) return parametersDisableInitialState
  const paramsKeys = Object.keys(parametersDisableInitialState)
  const entries = paramsKeys.map(key => {
    let value = ecg[key]
    if (value === '-') value = 0
    return [key, value]
  })
  return Object.fromEntries(entries)
}

export const checkReasonOptions = [
  'CHEQUEO',
  'PREOPERATORIO',
  'REVISIÓN GENERAL',
  'DOLOR PRECORDIAL',
  'DOLOR ENFERMEDAD CARDIACA',
]

export const frequencyUnitsOptions = [
  'FRECUENCIA',
  'FVM',
]

export const rythmOptions = [
  'SINUSAL',
  'NO SINUSAL',
  'FIBRILACIÓN AURICULAR',
  'MARCAPASO AURICULAR',
  'MARCAPASO VENTRICULAR',
  'MARCAPASO DOBLE',
  'MARCAPASO INTERMITENTE'
]

export const AILMENT_NUM_OF_COLUMNS = [0, 1, 2]
export const MOBILE_AILMENT_NUM_OF_COLUMNS = [0, 1, 2]
