import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useConnect } from 'redux-bundler-hook'
import Spinner from '../components/common/Spinner'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgb(247,247,247)',
  },
}))

export const ReportComponent = props => {
  const classes = useStyles()
  const { doReportReset, urlObject } = props
  const [reported, setReported] = useState(false)
  const [loading, setLoading] = useState(false)
  const { search } = urlObject
  const urlParams = new URLSearchParams(search)
  const token = urlParams.has('token') ? urlParams.get('token') : undefined
  let message = reported ? 'Gracias por reportar el incidente' : 'Error al reportar. Intente de nuevo mas tarde.'
  if (token === undefined) {
    message = 'Link inválido.'
  }
  useEffect(() => {
    const reportAsync = async () => {
      if (token === undefined) return
      setLoading(true)
      const res = await doReportReset(token)
      setReported(res)
      setLoading(false)
    }
    reportAsync()
  }, [])
  return (
    <div className={classes.root}>
      {loading ? <Spinner /> : <Typography fontSize={20}>{message}</Typography>}
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectUrlObject',
    'doReportReset',
  )
  return <ReportComponent {...props} {...connectedProps} />
}
