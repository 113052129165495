import React from 'react'
import { makeStyles } from '@mui/styles'
import ConversationItem from './ConversationItem'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    height: '100%',
    maxHeight: '70vh',
  },
}))

const Conversations = props => {
  const { conversationsData = [], doSetSelectedConversation, userId, currentConversation } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {conversationsData?.map(conv => (
        <ConversationItem
          key={conv?.id}
          conversation={conv}
          doSetSelectedConversation={doSetSelectedConversation}
          userId={userId}
          currentConversation={currentConversation}
        />
      ))}
    </div>
  )
}

export default Conversations
