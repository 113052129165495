import { pick } from 'ramda'

export const chewDataProvisionCenterAPI = states => {
  const [firstState, secondState] = states
  return {
    newOrganization: firstState.newOrg,
    newReportTemplate: secondState.newTemplate,
    payload: {
      organization: firstState.newOrg ? { name: firstState.name, key: firstState.key } : firstState.organizationId,
      center: {
        ...pick(['name', 'city', 'address', 'timezone'], secondState),
      },
      user: {
        ...pick(['username', 'email'], secondState),
        firstName: secondState.name,
      },
      reportTemplate: secondState.newTemplate
        ? { ...pick(['templateKey', 'templateNumber'], secondState) }
        : secondState.templateId,
      profile: {
        phoneNumber: secondState.phoneNumber,
      },
    },
  }
}
