import axios from 'axios'
// import { createSelector } from 'redux-bundler'
import { tokenHeader } from '../utils'
import { createSelector } from 'redux-bundler'

export const types = {
  FETCH_CENTERS_START: 'FETCH_CENTERS_START',
  FETCH_CENTERS_SUCCESS: 'FETCH_CENTERS_SUCCESS',
  FETCH_CENTERS_ERROR: 'FETCH_CENTERS_ERROR',
}

const initialState = {
  entities: [],
  loading: false,
  lastFetch: null,
  lastError: null,
  cachedEntities: {},
}

export default {
  name: 'centers',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.FETCH_CENTERS_START:
      return {
        ...state,
        loading: true,
      }
    case types.FETCH_CENTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        lastFetch: Date.now(),
        lastError: null,
        entities: action.payload,
      }
    case types.FETCH_CENTERS_ERROR:
      return {
        ...state,
        lastError: Date.now(),
        loading: false,
      }
    default:
      return state
    }
  },
  selectCenters: state => state.centers.entities,
  selectCentersRaw: state => state.centers,
  doFetchCenters: () => async ({ dispatch, store }) => {
    dispatch({ type: types.FETCH_CENTERS_START })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/centers', tokenHeader(access))
      dispatch({
        type: types.FETCH_CENTERS_SUCCESS,
        payload: res.data,
      })
    } catch (error) {
      dispatch({ type: types.FETCH_CENTERS_ERROR })
    }
  },
  reactShouldFetchCenters: createSelector(
    'selectCentersRaw',
    'selectIsAuthenticated',
    'selectAppTime',
    'selectIsOnline',
    (centersRaw, isAuthenticated, appTime, isOnline) => {
      if (!isAuthenticated) return
      if (centersRaw.loading) return
      if (!isOnline) return
      let shouldFetch = false
      let timePassedLastFetch = null
      // if there are no entities, fetch
      if (centersRaw.lastFetch) {
        timePassedLastFetch = appTime - centersRaw.lastFetch
        if (centersRaw.entities.length === 0 && timePassedLastFetch > 60000) shouldFetch = true
        if (timePassedLastFetch > 60000 * 5) shouldFetch = true
      } else if (!centersRaw.entities.length) shouldFetch = true
      if (centersRaw.lastError) {
        const timePassed = appTime - centersRaw.lastError
        if (timePassed > 10000) {
          shouldFetch = true
        }
      }
      if (shouldFetch) return { actionCreator: 'doFetchCenters' }
    },
  ),
  init: store => {
    const isAuth = store.selectIsAuthenticated()
    if (isAuth) store.doFetchCenters()
  }
}
