import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel } from '@mui/material'
import MuiCheckbox from '@mui/material/Checkbox'

const Checkbox = props => {
  const {
    label,
    checked,
    onChange,
    color,
    disabled,
    labelPlacement,
    ...rest
  } = props
  return (
    <FormControlLabel
      label={label}
      checked={checked}
      onChange={onChange}
      labelPlacement={labelPlacement}
      control={
        <MuiCheckbox
          color={color}
          disabled={disabled}
          {...rest}
        />
      }
    />
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelPlacement: PropTypes.oneOf([
    'bottom',
    'end',
    'start',
    'top',
  ]),
  disabled: PropTypes.bool,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'error',
    'info',
    'success',
    'warning',
  ]),
}

export default Checkbox
