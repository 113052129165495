import createTableBundle from './tableBundle'

const config = {
  bundleName: 'HTMLReportTemplateAdmin',
  camelCaseBundleName: 'htmlReportTemplateTable',
  verboseName: 'plantilla de reporte',
  url: 'htmlReportTemplates',
  allowDelete: false,
  isVerboseFemale: true,
  permission: 1,
}

const baseBundle = createTableBundle(config)

export default {
  ...baseBundle,
}
