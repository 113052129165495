import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'none',
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: '#dbd9d9',
      color: 'black',
    },
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}))

const ECGButton = props => {
  const classes = useStyles()
  const {
    children,
    icon: Icon,
    textColor,
    backgroundColor,
    iconProps,
    tooltip,
    tooltipTitle,
    tooltipStyles,
    onClick,
    styles,
    iconStyles,
    ...rest
  } = props

  const TooltipTitle = () => (
    <Typography
      sx={{
        ...tooltipStyles,
      }}
    >
      {tooltipTitle}
    </Typography>
  )

  return tooltip ? (
    <Tooltip
      title={<TooltipTitle />}
      placement='top'
      arrow
    >
      <Button
        className={classes.button}
        sx={{
          backgroundColor,
          color: textColor,
          borderRadius: '15px 15px 15px 15px',
          ...styles,
        }}
        variant='contained'
        onClick={onClick}
        {...rest}
      >
        <div className={classes.buttonContent}>
          {Icon ? <Icon style={{ marginRight: children && 5 }} {...iconProps} /> : null}
          {children}
        </div>
      </Button>
    </Tooltip>
  ) : (
    <Button
      className={classes.button}
      sx={{
        backgroundColor,
        color: textColor,
        borderRadius: '15px 15px 15px 15px',
        ...styles,
      }}
      variant='contained'
      onClick={onClick}
      {...rest}
    >
      <div className={classes.buttonContent}>
        {Icon ? <Icon sx={{ marginRight: children ? 20 : 0, ...iconStyles }} {...iconProps} /> : null}
        {children}
      </div>
    </Button>
  )
}

ECGButton.propTypes = {
  icon: PropTypes.elementType,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  onClick: PropTypes.func,
  styles: PropTypes.object,
}
ECGButton.defaultProps = {
  icon: null,
  backgroundColor: '#F94654',
  textColor: 'white',
  tooltip: false,
  tooltipTitle: '',
}

export default ECGButton
