import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TableRow from './TableRow'
import { makeStyles } from '@mui/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from '@mui/material/Pagination'
import { Typography } from '@mui/material'
import Select from '../common/Select'
import { validActionsPerKind } from '../../utils/ecg'
import Spinner from '../common/Spinner'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { waitForElm } from '../../utils/misc'

library.add(faMagnifyingGlass)

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 10,
    paddingBottom: 20,
    paddingRight: 10,
    paddingLeft: 10,
    overflowY: 'scroll',
    height: '100%',
    borderRadius: 20,
    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    width: 'inherit',
  },
  emptyDiv: {
    gridArea: 'empty',
  },
  noDataDiv: {
    display: 'flex',
    gap: 20,
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderRadius: 20,
  },
  spinner: {
    display: 'flex',
    gap: 20,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    justifyContent: 'space-between',
    zIndex: 400,
    top: 0,
    marginBottom: -40,
    backgroundColor: '#F7F7F7',
    width: '100%',
    paddingBottom: 10,
    paddingTop: 20,
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    gap: 30,
  },
}))

const TableWeb = props => {
  const {
    data,
    kind,
    ecgsLoading: loading = false,
    ...rest
  } = props
  const {
    ecgsRaw,
    doSetEcgsCurrentPage,
    doSetEcgsPageSize,
    ecgSearchValue,
  } = rest

  const getValidActions = validActionsPerKind[kind]
  const noDataIcon = ecgSearchValue.length > 0 ? faMagnifyingGlass : 'fa-solid fa-clipboard-check'
  const classes = useStyles()
  const [text, setText] = useState('')
  const fullText = 'Cargando...'
  const typingSpeed = 200 // Adjust typing speed in ms

  // Scroll to top when changing pages or searching
  useEffect(() => {
    waitForElm('#ecg-table-web').then(elm => {
      elm.scrollTop = 0
    })
  }, [ecgsRaw.currentPage, ecgSearchValue])

  useEffect(() => {
    let currentIndex = 0
    let intervalId

    if (loading) {
      setText('') // Ensure text starts fresh on loading

      intervalId = setInterval(() => {
        setText(() => {
          const nextText = fullText.slice(0, currentIndex + 1) // Get the substring up to the current index
          currentIndex++ // Increment the index

          // If we've reached the end of the text, restart the typing effect
          if (currentIndex > fullText.length) {
            currentIndex = 0
          }

          return nextText // Update the text to the current substring
        })
      }, typingSpeed)
    }

    // Cleanup function
    return () => {
      clearInterval(intervalId) // Clear the interval on unmount or when loading changes
      setText('') // Reset text when loading stops
    }
  }, [loading])

  if (data.length && !loading) return (
    <div className={classes.root} id='ecg-table-web'>
      <div className={classes.controls}>
        <div className={classes.pagination}>
          <Pagination
            count={ecgsRaw.totalPages}
            shape='rounded'
            color='primary'
            onChange={(_, page) => doSetEcgsCurrentPage(page)}
            page={ecgsRaw.currentPage}
            showFirstButton
            showLastButton
          />
          <Select
            displayEmpty
            size='small'
            placeholder='Tamaño de página'
            dense
            name='pageSize'
            options={[5, 10, 15, 20, 25, 30].map(size => (
              { key: size, displayValue: size, value: size }
            ))}
            value={ecgsRaw.pageSize}
            onValueChange={e => doSetEcgsPageSize(e.target.value)}
            width='100px'
          />
        </div>
        <Typography
          sx={{
            width: '20%',
            textAlign: 'right',
            marginRight: 2,
          }}
        >
          Total de estudios: {ecgsRaw.total}
        </Typography>
      </div>
      {loading ? (
        <div className={classes.spinner}>
          <Spinner />
          <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
            Cargando...
          </Typography>
        </div>
      ) : data.map((ecg, index) => (
        <TableRow
          key={`table-${ecg.id}`}
          index={index}
          ecg={ecg}
          getValidActions={getValidActions}
          kind={kind}
          {...rest}
        />
      ))}
    </div>
  )

  if (loading) return (
    <div className={classes.noDataDiv}>
      <Spinner />
      <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
        {text}
      </Typography>
    </div>
  )

  if (!data.length && !loading) return (
    <div className={classes.noDataDiv}>
      <FontAwesomeIcon
        icon={noDataIcon}
        size='3x'
        opacity={0.1}
        bounce
        style={{ animationIterationCount: 1 }}
      />
      <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
        {ecgSearchValue.length ? 'No hay resultados para ésta búsqueda' : '¡Nada más por hacer!'}
      </Typography>
    </div>
  )

  if (loading) return (
    <div className={classes.noDataDiv}>
      <Spinner />
      <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
        {text}
      </Typography>
    </div>
  )

  return (
    <div className={classes.noDataDiv} />
  )
}

TableWeb.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdOn: PropTypes.string.isRequired,
  })),
}

TableWeb.defaultProps = {
  data: [],
}

export default TableWeb
