import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import ReplayIcon from '@mui/icons-material/Replay'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import LeakAddIcon from '@mui/icons-material/LeakAdd'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { RETRY_TIME_AFTER_ERROR_MILISECONDS } from '../bundles/websockets'

const RealTimeConnectionStatus = props => {
  const {
    websocket,
    isOnline,
    isMobile,
    doWebsocketResetAttempts,
    doDispatchWsDummyAction,
    doWebsocketAbortConnection,
    doCreateSocket,
  } = props
  const [time, setTime] = useState(Date.now())
  const remainingTime = Math.floor((RETRY_TIME_AFTER_ERROR_MILISECONDS - (time - websocket.lastTry)) / 1000)
  const displayTime = remainingTime > 0 ? remainingTime : 0
  if (remainingTime < 1 && !websocket.abort && !websocket.connected && isOnline) doDispatchWsDummyAction()

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [websocket])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
        alignItems: 'center',
        gap: 2,
        color: isMobile ? 'white' : 'black'
      }}
    >
      {websocket.connecting && <CircularProgress size='1rem' />}
      {websocket.connecting && <Typography fontSize={12} fontFamily='roboto' fontStyle='italic'>Conectando...</Typography>}

      {websocket.connected && isOnline && <FiberManualRecordIcon sx={{ fontSize: 14, fill: '#00FF7F	' }} />}
      {websocket.connected && isOnline && <Typography fontSize={12} fontFamily='roboto'>En línea</Typography>}

      {(!websocket.connected && !websocket.error && !websocket.connecting) || !isOnline && (
        <FiberManualRecordIcon sx={{ fontSize: 14, fill: 'grey' }} />
      )}
      {(!websocket.connected && !websocket.error && !websocket.connecting) || !isOnline && (
        <Typography fontSize={12} fontFamily='roboto' fontStyle='italic'>No conectado. {!isOnline && 'Sin internet.'}</Typography>
      )}
      {!websocket.connected && !websocket.error && !websocket.connecting && isOnline && (
        <Tooltip arrow placement='top' title='Conectar'>
          <IconButton
            onClick={doCreateSocket}
          >
            <LeakAddIcon />
          </IconButton>
        </Tooltip>
      )}

      {websocket.error && !websocket.connecting && !websocket.abort && isOnline && (
        <FiberManualRecordIcon sx={{ fontSize: 14, fill: '#ff3333' }} />
      )}
      {websocket.error && !websocket.connecting && !websocket.abort && isOnline && (
        <Typography fontSize={12} fontFamily='roboto' fontStyle='italic'>Error. Reintentando conexión en {displayTime}s.</Typography>
      )}
      {websocket.error && !websocket.connecting && !websocket.abort && isOnline && (
        <Tooltip arrow placement='top' title='Cancelar conexión'>
          <IconButton onClick={doWebsocketAbortConnection}>
            <DoDisturbIcon />
          </IconButton>
        </Tooltip>
      )}

      {websocket.abort && websocket.error && websocket.failedAttempts > 10 && <FiberManualRecordIcon sx={{ fontSize: 14, fill: 'grey' }} />}
      {websocket.abort && websocket.error && websocket.failedAttempts > 10 && (
        <Typography fontSize={12} fontFamily='roboto' fontStyle='italic'>No conectado. Máximo número de intentos.</Typography>
      )}
      {websocket.abort && websocket.error && websocket.failedAttempts > 10 && (
        <Tooltip arrow placement='top' title='Reintentar'>
          <IconButton onClick={doWebsocketResetAttempts}>
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      )}

    </div>
  )
}

export default RealTimeConnectionStatus
