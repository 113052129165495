import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const Spinner = props => {
  const { size } = props
  return (
    <CircularProgress size={size} color="secondary" />
  )
}

Spinner.defaultProps = {
  size: 40,
}

export default Spinner
