import * as React from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

export const MultipleSelectChip = props => {
  const {
    options,
    selected,
    setSelected,
    label,
  } = props
  const theme = useTheme()
  const optionsByValue = Object.fromEntries(options.map(opt => ([opt.value, opt.displayValue])))

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  return (
    <div>
      <FormControl sx={{ m: 0, minWidth: 500, maxWidth: 500, gap: 10 }}>
        <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          size='medium'
          color='secondary'
          value={selected}
          fullWidth
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={optionsByValue[value]} color='secondary' />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((optData) => (
            <MenuItem
              key={optData.value}
              value={optData.value}
              style={getStyles(optData.displayValue, selected, theme)}
            >
              {optData.displayValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default MultipleSelectChip
