import React from 'react'
import PropTypes from 'prop-types'
import { useConnect } from 'redux-bundler-hook'
import PersonIcon from '@mui/icons-material/Person'
import { Typography } from '@mui/material'
import RealTimeConnectionStatus from '../RealTimeConnectionStatus'

export const ProfileComponent = props => {
  const {
    userCenter,
    auth,
    fontSize,
    fontColor,
    iconSize,
    iconColor,
    websocket,
    userFlags,
    isOnline,
    isMobile,
    doWebsocketResetAttempts,
    doDispatchWsDummyAction,
    doWebsocketAbortConnection,
    doCreateSocket,
  } = props

  const shouldSeeSocketStatus = userFlags?.includes('REAL_TIME_WEBSOCKET')
  let text = ''

  switch (auth?.user?.kind) {
  case 0:
    text = `DEVELOPER: ${auth.user.user.fullName}`.toUpperCase()
    break
  case 1:
    text = `Asistente: ${auth.user.user.fullName}`.toUpperCase()
    break
  case 2:
    text = `DR. ${auth.user.user.fullName}`.toUpperCase()
    break
  case 3:
    if (userCenter === undefined) text = 'Admin Centro Integral'
    else text = `${userCenter.name}`.toUpperCase()
    break
  default:
    break
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: shouldSeeSocketStatus ? 'space-between' : 'start',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          marginBottom: 10,
          gap: 10,
        }}
      >
        <PersonIcon sx={{ fontSize: iconSize , color: iconColor }} />
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: fontSize,
            color: fontColor,
          }}
        >
          {text}
        </Typography>
      </div>
      {shouldSeeSocketStatus && (
        <div>
          <RealTimeConnectionStatus
            websocket={websocket}
            doWebsocketResetAttempts={doWebsocketResetAttempts}
            doDispatchWsDummyAction={doDispatchWsDummyAction}
            doWebsocketAbortConnection={doWebsocketAbortConnection}
            doCreateSocket={doCreateSocket}
            isOnline={isOnline}
            isMobile={isMobile}
          />
        </div>
      )}
    </div>
  )
}

const Profile = props => {
  const connectedProps = useConnect(
    'selectAuth',
    'selectUserCenter',
    'selectWebsocket',
    'selectUserFlags',
    'selectIsOnline',
    'selectIsMobile',
    'doWebsocketResetAttempts',
    'doDispatchWsDummyAction',
    'doWebsocketAbortConnection',
    'doCreateSocket',
  )
  return <ProfileComponent {...props} {...connectedProps} />
}

Profile.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontColor: PropTypes.string,
  iconColor: PropTypes.string,
}

export default Profile
