export const toTitleCase = str => str.replace(
  /\w\S*/g,
  function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  }
)

export const stringToHexFormat = strNum => `0x${strNum}`

export const stringToMaxLength = (string = '', maxLen = 30) => {
  const length = string.length
  if (maxLen > length) return string
  const toRemovePerSide = Math.floor((length - maxLen) / 2)
  const half = Math.ceil(length / 2) - 1
  const head = string.slice(0, half - toRemovePerSide).trim()
  const tail = string.slice(half + toRemovePerSide + 4, length).trim()
  return `${head}...${tail}`
}

export const replaceStringTemplate = (s, replacements) => s.replace(
  /{\w+}/g,
  placeholderWithDelimiters => {
    const placeholderWithoutDelimiters = placeholderWithDelimiters.substring(
      1,
      placeholderWithDelimiters.length - 1,
    )
    const stringReplacement = replacements[placeholderWithoutDelimiters] || placeholderWithDelimiters
    return stringReplacement
  },
)

export const utf8ToBase64 = str => {
  return btoa(unescape(encodeURIComponent(str)))
}
