import { createRouteBundle } from 'redux-bundler'
import App from '../containers/App'
import Login from '../containers/Login'
import ForgotForm from '../containers/ForgotForm'
import ResetPassword from '../containers/ResetPassword'
import Report from '../containers/Report'
import AdminPanel from '../containers/AdminPanel'
import Reports from '../containers/Reports'
import Simulator from '../containers/Reports/Simulator'
import PublicReport from '../containers/PublicReport'
import NotFound from '../containers/NotFound'

const routes = {
  '/': {
    component: App,
    titleTemplate: 'Dashboard: ${kind} ${fullName}',
  },
  '/login': {
    component: Login,
    titleTemplate: 'CIDC - Iniciar Sesión',
    isPublic: true,
  },
  '/estudio/:ecgId/': {
    component: App,
    titleTemplate: 'Estudio ${ecgId} - ${patientName}',
  },
  '/forgot': {
    component: ForgotForm,
    titleTemplate: 'Olvidé mi contraseña',
    isPublic: true,
  },
  '/reset': {
    component: ResetPassword,
    titleTemplate: 'Reestablecer Contraseña',
    isPublic: true,
  },
  '/reportarFraude': {
    component: Report,
    titleTemplate: 'Reportado',
    isPublic: true,
  },
  '/administracion/:tab': {
    component: AdminPanel,
    titleTemplate: 'Panel de Administración',
    defaultTab: 'centros',
  },
  '/reportes/plantillas': {
    component: Reports,
    titleTemplate: 'Reportes - Plantillas',
  },
  '/reportes/plantillas/simulador/empty': {
    component: Simulator,
    titleTemplate: 'Reportes - Simulador',
    permission: 1,
  },
  '/reportes/plantillas/simulador/:htmlTemplateId': {
    component: Simulator,
    titleTemplate: 'Reportes - Simulador',
    permission: 1,
  },
  '/p/r/:encodedId/': {
    component: PublicReport,
    titleTemplate: 'CIDC - Reporte',
    isPublic: true,
  },
  '*': {
    component: NotFound,
    titleTemplate: '404'
  }
}

export default createRouteBundle(routes)
