import axios from 'axios'
import createTableBundle from '../tableBundle'
import { tokenHeader } from '../../utils'

const config = {
  bundleName: 'Center',
  camelCaseBundleName: 'centerAdminTable',
  verboseName: 'centro',
  url: 'centersList',
  allowDelete: true,
}

const baseBundle = createTableBundle(config)

export default {
  ...baseBundle,
  doProvisionCenterCreate: data => async ({ store }) => {
    let res
    try {
      res = await axios.post('/api/centers/provision/', data, tokenHeader('', store))
      console.log(res.data)
      store.doSetSnackbarSuccess('Centro creado correctamente.')
      return res.data
    } catch (error) {
      store.doSetSnackbarFail('Algo salió mal, favor de revisar que los datos estén correctos o contacte a soporte.')
      return false
    }
  },
  doSendNewCenterInvitation: user => async ({ store }) => {
    try {
      await axios.post('/api/users/sendEmailResetLink/?new=true', {user})
      store.doSetSnackbarSuccess('Invitación enviada correctamente')
      return true
    } catch (error) {
      const errorResponse = error.response.data
      if ('user' in errorResponse) {
        const errorStrings = errorResponse.user.join('\n')
        store.doSetSnackbarFail(errorStrings)
      } else {
        store.doSetSnackbarFail('Error al enviar link para reestablecer contraseña.')
      }
      return false
    }
  },
}
