import React, { useEffect } from 'react'
import { useConnect } from 'redux-bundler-hook'
import MobileLayout from '../containers/MobileLayout'
import WebLayout from '../containers/WebLayout'

function debounce(fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const LayoutWrapper = ({ component: Component, isMobile, doSetIsMobile }) => {
  const Layout = isMobile ? MobileLayout : WebLayout

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      doSetIsMobile(window.matchMedia('screen and (max-width: 500px)').matches)
    }, 200)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [screen])

  return (
    <Layout>
      <Component variant={isMobile ? 'mobile' : 'web'} />
    </Layout>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectIsMobile',
    'doSetIsMobile'
  )

  return <LayoutWrapper {...connectedProps} {...props} />
}
