import axios from 'axios'
import { createSelector } from 'redux-bundler'
import { tokenHeader } from '../utils'

export const types = {
  FETCH_PATIENTS_START: 'FETCH_PATIENTS_START',
  FETCH_PATIENTS_FINISHED: 'FETCH_PATIENTS_FINISHED',
  FETCH_PATIENTS_ERROR: 'FETCH_PATIENTS_ERROR',
  GET_PATIENTS: 'GET_PATIENTS',
  GET_PATIENT: 'GET_PATIENT',
  ADD_PATIENT: 'ADD_PATIENT',
  DELETE_PATIENT: 'DELETE_PATIENT',
  EDIT_PATIENT: 'EDIT_PATIENT',
  SET_SELECTOR_ENTITIES: 'SET_SELECTOR_ENTITIES',
}

const initialState = {
  entities: [],
  cachedEntitiesProxy: JSON.parse(globalThis.localStorage.getItem('cachedPatientsData')) || {},
  selectorEntities: [],
  loading: false,
  lastFetch: null,
  lastError: null,
}

export default {
  name: 'patients',
  reducer: (state = initialState, action) => {
    const initialEntitiesProxy = state.cachedEntitiesProxy
    switch (action.type) {
    case types.GET_PATIENTS:
      action.payload.forEach(patient => {
        const id = patient.id
        initialEntitiesProxy[id] = patient
      })
      globalThis.localStorage.setItem('cachedPatientsData', JSON.stringify(initialEntitiesProxy))
      return {
        ...state,
        entities: action.payload,
        lastFetch: Date.now(),
        cachedEntitiesProxy: initialEntitiesProxy,
      }
    case types.GET_PATIENT:
      initialEntitiesProxy[action.payload.id] = action.payload
      globalThis.localStorage.setItem('cachedPatientsData', JSON.stringify(initialEntitiesProxy))
      return {
        ...state,
        entities: [...state.entities, action.payload],
        lastFetch: Date.now(),
        cachedEntitiesProxy: initialEntitiesProxy,
      }
    case types.FETCH_PATIENTS_START:
      return {
        ...state,
        loading: true,
      }
    case types.FETCH_PATIENTS_FINISHED:
      return {
        ...state,
        loading: false,
      }
    case types.FETCH_PATIENTS_ERROR:
      return {
        ...state,
        lastError: Date.now(),
      }
    case types.SET_SELECTOR_ENTITIES:
      action.payload.forEach(patient => {
        const id = patient.id
        initialEntitiesProxy[id] = patient
      })
      globalThis.localStorage.setItem('cachedPatientsData', JSON.stringify(initialEntitiesProxy))
      return {
        ...state,
        selectorEntities: action.payload,
        cachedEntitiesProxy: initialEntitiesProxy,
      }
    default:
      return state
    }
  },
  selectPatientsRaw: state => state.patients,
  selectPatients: state => state.patients.entities,
  selectPatientsProxy: state => state.patients.cachedEntitiesProxy,
  selectSelectorEntities: state => state.patients.selectorEntities,
  selectPatientsLoading: state => state.patients.loading,
  doFetchPatients: () => async ({ dispatch, store }) => {
    dispatch({ type: types.FETCH_PATIENTS_START })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/patients/', tokenHeader(access))
      const entities = res.data?.items || []
      dispatch({
        type: types.GET_PATIENTS,
        payload: entities,
      })
      dispatch({ type: types.FETCH_PATIENTS_FINISHED })
    } catch (error) {
      dispatch({ type: types.FETCH_PATIENTS_ERROR })
    }
  },
  doGetPatient: patient => async ({ dispatch, store }) => {
    let id
    if (typeof patient === 'object') id = patient.id
    else if (typeof patient === 'number') id = patient
    else store.doSetSnackbarFail('ERROR 0x010: Incorrect type provided to patient')
    dispatch({ type: types.FETCH_PATIENTS_START })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get(`/api/patients/${id}`, tokenHeader(access))
      dispatch({
        type: types.GET_PATIENT,
        payload: res.data,
      })
      dispatch({ type: types.FETCH_PATIENTS_FINISHED })
      return res.data
    } catch (error) {
      dispatch({ type: types.FETCH_PATIENTS_ERROR })
    }
  },
  doSearchPatients: searchTerm => async ({ dispatch, store }) => {
    if (searchTerm === '') return
    dispatch({ type: types.FETCH_PATIENTS_START })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get(`/api/patients/?search=${searchTerm}`, tokenHeader(access))
      const entities = res.data?.items || []
      dispatch({
        type: types.SET_SELECTOR_ENTITIES,
        payload: entities,
      })
      dispatch({ type: types.FETCH_PATIENTS_FINISHED })
    } catch (error) {
      dispatch({ type: types.FETCH_PATIENTS_ERROR })
    }
  },
  reactShouldFetchPatients: createSelector(
    'selectPatientsRaw',
    'selectIsAuthenticated',
    'selectAppTime',
    (patientsRaw, isAuthenticated, appTime) => {
      if (patientsRaw.loading) return null
      if (!isAuthenticated) return null
      let shouldFetch = false
      // if there are no entities, fetch
      let timePassedLastFetch
      if (patientsRaw.lastFetch) {
        timePassedLastFetch = appTime - patientsRaw.lastFetch
        if (!patientsRaw.entities.length && timePassedLastFetch > 60000) shouldFetch = true
        if (timePassedLastFetch > 60000 * 5) shouldFetch = true
      } else shouldFetch = true
      // if data is stale (more than 1min old), fetch
      // if an error ocurred, wait 5s and retry
      if (patientsRaw.lastError) {
        const timePassed = appTime - patientsRaw.lastError
        if (timePassed > 10000) {
          shouldFetch = true
        }
      }
      // if its on loading state, bail out and don't fetch
      if (shouldFetch) return { actionCreator: 'doFetchPatients' }
    }
  ),
}
