import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import T from '../../components/common/Typography'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CodeArea from './CodeTextArea'
import Spinner from '../../components/common/Spinner'


const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: [
      '"title title title title title title title"',
      '"tabs tabs tabs tabs tabs tabs tabs"',
      '"codeArea codeArea codeArea generate results results results"',
    ].join('\n'),
    gap: 5,
    gridTemplateRows: '50px 60px auto',
    gridTemplateColumns: 'auto auto auto 100px auto auto auto',
    width: '100%',
    height: '100%',
  },
  title: {
    gridArea: 'title',
    display: 'flex',
    justifyContent: 'left',
    gap: 20,
    alignItems: 'center',
  },
  titleText: {
    display: 'flex',
    gap: 15,
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
  },
  tabs: {
    gridArea: 'tabs',
  },
  content: {
    gridArea: 'tabContent',
  },
  codeDiv: {
    height: '100%',
    width: '100%',
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  codeArea: {
    gridArea: 'codeArea',
  },
  generate: {
    gridArea: 'generate',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  results: {
    gridArea: 'results',
    maxWidth: '50vw',
    maxHeight: '75vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'scroll',
    backgroundColor: '#1e1e1e',
    borderRadius: 5,
  },
  responsiveImage: {
    maxWidth: '50vw',
    maxHeight: '75vh',
    overflow: 'scroll',
    padding: 20,
  },

}))

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}


const SimulatorComponent = props => {
  const {
    reportSimulatorRaw,
    selected,
    hTMLReportTemplateAdminLoading,
    doUpdateUrl,
    doSimulatorClearContent,
    doSimulatorSetContent,
    doResetSimulatorState,
    doUpdateHTMLReportTemplateAdminTableItem,
    doSimulatorGenerateReport,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Tooltip title='Atrás'>
          <IconButton onClick={() => {
            doResetSimulatorState()
            doUpdateUrl('/reportes/plantillas')
          }}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <div className={classes.titleText}>
          <T.H4 style={{ display: 'flex', gap: 15, alignItems: 'baseline' }}>
            {selected ? `Editar plantila: ${selected?.name ?? ''}` : 'Simulador de plantillas'}
          </T.H4>
        </div>
      </div>
      <div className={classes.codeArea}>
        <CodeArea
          code={reportSimulatorRaw.content}
          setCode={doSimulatorSetContent}
        />
      </div>
      <div className={classes.generate}>
        <Button
          variant='contained'
          color='primary'
          disabled={reportSimulatorRaw.loading || !reportSimulatorRaw.content || reportSimulatorRaw.content === '' || hTMLReportTemplateAdminLoading}
          onClick={async () => {
            await doSimulatorGenerateReport(reportSimulatorRaw.content)
          }}
        >
          Generar
        </Button>
        <Button variant='contained' color='secondary' onClick={doSimulatorClearContent}>
          Limpiar
        </Button>
        {selected && (
          <Tooltip title='Para guardar debe generar previamente la plantilla.'>
            <Button
              variant='contained'
              color='secondary'
              disabled={
                hTMLReportTemplateAdminLoading || reportSimulatorRaw.loading || !reportSimulatorRaw.result || reportSimulatorRaw.content === ''
              }
              onClick={async () => {
                await doUpdateHTMLReportTemplateAdminTableItem({
                  ...selected,
                  content: reportSimulatorRaw.content,
                })
              }}
            >
            Guardar
            </Button>
          </Tooltip>
        )}
      </div>
      <div className={classes.results}>
        {/* Put results here. Result is the signed s3 url for a png image */}
        {!reportSimulatorRaw.loading && reportSimulatorRaw.result && (
          <img src={reportSimulatorRaw?.result ?? ''} className={classes.responsiveImage} alt='Reporte generado' />
        )}
        {reportSimulatorRaw.loading && <Spinner />}
      </div>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectRouteParams',
    'selectPathname',
    'selectReportSimulatorRaw',
    'selectHTMLReportTemplateAdminCachedItems',
    'selectHTMLReportTemplateAdminLoading',
    'doUpdateUrl',
    'doSimulatorClearContent',
    'doSimulatorSetContent',
    'doResetSimulatorState',
    'doUpdateHTMLReportTemplateAdminTableItem',
    'doSimulatorGenerateReport',
  )
  const {
    routeParams,
    doUpdateUrl,
    hTMLReportTemplateAdminCachedItems,
    pathname,
  } = connectedProps
  const id = routeParams?.htmlTemplateId ?? null
  if (id === null && !pathname.includes('empty')) {
    doUpdateUrl('/reportes/plantillas')
  }
  const selected = hTMLReportTemplateAdminCachedItems[id] ?? null
  return <SimulatorComponent {...props} {...connectedProps} selected={selected} />
}
