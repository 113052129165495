import axios from 'axios'
// import { createSelector } from 'redux-bundler'
import { tokenHeader } from '../utils'
import { createSelector } from 'redux-bundler'

export const types = {
  FETCH_ORGANIZATIONS_START: 'FETCH_ORGANIZATIONS_START',
  FETCH_ORGANIZATIONS_SUCCESS: 'FETCH_ORGANIZATIONS_SUCCESS',
  FETCH_ORGANIZATIONS_ERROR: 'FETCH_ORGANIZATIONS_ERROR',
}

const initialState = {
  entities: [],
  loading: false,
  lastFetch: null,
  lastError: null,
  cachedEntities: {},
}

export default {
  name: 'organizations',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.FETCH_ORGANIZATIONS_START:
      return {
        ...state,
        loading: true,
      }
    case types.FETCH_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        lastFetch: Date.now(),
        lastError: null,
        entities: action.payload,
      }
    case types.FETCH_ORGANIZATIONS_ERROR:
      return {
        ...state,
        lastError: Date.now(),
        loading: false,
      }
    default:
      return state
    }
  },
  selectOrganizations: state => state.organizations.entities,
  selectOrganizationsById: createSelector(
    'selectOrganizations',
    organizations => Object.fromEntries(organizations.map(org => [org.id, org])),
  ),
  selectOrganizationsRaw: state => state.organizations,
  doFetchOrganizations: () => async ({ dispatch, store }) => {
    dispatch({ type: types.FETCH_ORGANIZATIONS_START })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/organizations', tokenHeader(access))
      dispatch({
        type: types.FETCH_ORGANIZATIONS_SUCCESS,
        payload: res.data,
      })
    } catch (error) {
      dispatch({ type: types.FETCH_ORGANIZATIONS_ERROR })
    }
  },
  reactShouldFetchOrganizations: createSelector(
    'selectOrganizationsRaw',
    'selectIsAuthenticated',
    'selectAppTime',
    'selectIsOnline',
    (organizationsRaw, isAuthenticated, appTime, isOnline) => {
      if (!isAuthenticated) return
      if (organizationsRaw.loading) return
      if (!isOnline) return
      let shouldFetch = false
      let timePassedLastFetch = null
      // if there are no entities, fetch
      if (organizationsRaw.lastFetch) {
        timePassedLastFetch = appTime - organizationsRaw.lastFetch
        if (organizationsRaw.entities.length === 0 && timePassedLastFetch > 60000) shouldFetch = true
        if (timePassedLastFetch > 60000 * 5) shouldFetch = true
      } else if (!organizationsRaw.entities.length) shouldFetch = true
      if (organizationsRaw.lastError) {
        const timePassed = appTime - organizationsRaw.lastError
        if (timePassed > 10000) {
          shouldFetch = true
        }
      }
      if (shouldFetch) return { actionCreator: 'doFetchOrganizations' }
    },
  ),
  init: store => {
    const isAuth = store.selectIsAuthenticated()
    if (isAuth) store.doFetchOrganizations()
  }
}
