import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useConnect } from 'redux-bundler-hook'
import Spinner from '../common/Spinner'
import T from '../common/Typography'
import { uploadConfirmedTrace, uploadOcrTrace } from '../../utils/actions'
import Offline from '../common/Offline'

export const onDropMapping = {
  uploadOcrTrace: uploadOcrTrace,
  uploadConfirmedTrace: uploadConfirmedTrace,
}

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: 200,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    minHeight: 'inherit',
    border: 'dashed 2px #486581',
    borderRadius: 20,
    minWidth: '100%',
    backgroundColor: '#F2F2F2',
    '&:hover': {
      backgroundColor: '#e0e0e0',
      cursor: 'pointer',
    },
  },
}))

const UploadFileDialog = props => {
  const {
    fileDialogOpen,
    fileDialogActionName,
    fileDialogLoading,
    fileDialogTitle,
    fileDialogRelatedEcg,
    doCloseDialog,
    doSetDialogLoading,
    doResetDialogState,
    doSetDialogIdle,
    isOnline,
  } = props
  const classes = useStyles()
  const onDropAction = onDropMapping[fileDialogActionName]

  const onDrop = useCallback(async acceptedFiles => {
    doSetDialogLoading()
    await onDropAction(acceptedFiles, fileDialogRelatedEcg)
    doSetDialogIdle()
    doCloseDialog()
  })

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({ onDrop })

  return (
    <Dialog
      open={fileDialogOpen}
      onClose={() => {
        doCloseDialog()
        doResetDialogState()
      }}
      maxWidth='md'
      fullWidth
    >
      {!isOnline ? (
        <Offline />
      ) : (
        <>
          <DialogTitle className={classes.title}>
            <T.H4>
              {fileDialogTitle}
            </T.H4>
          </DialogTitle>
          <DialogContent className={classes.content}>
            {fileDialogLoading ? (
              <Spinner size={50} />
            ) : (
              <div className={classes.dropzone} {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Typography fontFamily='freight-sans-pro'>Suelte archivo aquí</Typography>
                ) : (
                  <Typography fontFamily='freight-sans-pro'>Arrastre archivos o haga click para cargar un archivo.</Typography>
                )}
              </div>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectFileDialogOpen',
    'selectFileDialogOnClose',
    'selectFileDialogActionName',
    'selectFileDialogLoading',
    'selectFileDialogTitle',
    'selectFileDialogRelatedEcg',
    'selectIsOnline',
    'doCloseDialog',
    'doSetDialogLoading',
    'doSetDialogIdle',
    'doResetDialogState',
  )
  return <UploadFileDialog {...props} {...connectedProps} />
}
