import { stringToHexFormat } from './string'

export const parseRgbStringToValues = rgbString => {
  const result = { r: 0, g: 0, b:0 }
  if (typeof rgbString !== 'string') return {}
  const numbersOnly = rgbString.replace('#', '')
  if (numbersOnly.length !== 6) return result
  const rString = numbersOnly.slice(0, 2)
  const gString = numbersOnly.slice(2, 4)
  const bString = numbersOnly.slice(4, 6)
  result.r = Number(stringToHexFormat(rString))
  result.g = Number(stringToHexFormat(gString))
  result.b = Number(stringToHexFormat(bString))
  return result
}

export const  componentToHex = c => {
  var hex = c.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}

export const rgbToHex = rgbObj => {
  const { r, g, b } = rgbObj
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const shadeRGBValueToInt = (val, shadeVal) => Math.trunc(val * shadeVal)
export const shadeRGBVals = (rgb, shade) => (
  {
    r: shadeRGBValueToInt(rgb.r, shade),
    g: shadeRGBValueToInt(rgb.g, shade),
    b: shadeRGBValueToInt(rgb.b, shade),
  }
)
