import axios from 'axios'
import { createSelector } from 'redux-bundler'
import { tokenHeader } from '../../utils'
import { toTitleCase } from '../../utils/string'

const getTypes = (bundleName, customTypes = {}) => {
  const capsName = bundleName.toUpperCase()
  const get = {
    list: {
      start: `FETCH_${capsName}_TABLE_LIST_START`,
      succeed: `FETCH_${capsName}_TABLE_LIST_SUCCEED`,
      error: `FETCH_${capsName}_TABLE_LIST_ERROR`,
    },
    retrieve: {
      start: `FETCH_${capsName}_TABLE_ITEM_START`,
      succeed: `FETCH_${capsName}_TABLE_ITEM_SUCCEED`,
      error: `FETCH_${capsName}_TABLE_ITEM_ERROR`,
    },
  }
  const post = {
    create: {
      start: `CREATE_${capsName}_TABLE_ITEM_START`,
      succeed: `CREATE_${capsName}_TABLE_ITEM_SUCCEED`,
      error: `CREATE_${capsName}_TABLE_ITEM_ERROR`,
    },
    update: {
      start: `UPDATE_${capsName}_TABLE_ITEM_START`,
      succeed: `UPDATE_${capsName}_TABLE_ITEM_SUCCEED`,
      error: `UPDATE_${capsName}_TABLE_ITEM_ERROR`,
    },
  }
  const deleteMethod = {
    delete: `DELETE_${capsName}TABLE_ITEM`
  }
  const pagination = {
    setPage: `SET_PAGE_${capsName}_TABLE`,
    setPageSize: `SET_PAGE_SIZE_${capsName}_TABLE`,
  }
  const cache = {
    setCachedItems: `SET_CACHED_${capsName}_ITEMS`,
  }
  const selection = {
    setSelected: `SET_SELECTED_${capsName}_ITEMS`,
    setAllPageSelected: `SET_ALL_PAGE_SELECTED_${capsName}_ITEMS`,
    clearSelected: `CLEAR_SELECTED_${capsName}_ITEMS`,
  }
  const search = {
    setSearchValue: `SET_${capsName}_SEARCH_VALUE`,
    clearSearchValue: `CLEAR_${capsName}SEARCH_VALUE`,
  }
  const dialog = {
    openDialog: `SET_${capsName}_TABLE_DIALOG_OPEN`,
    closeDialog: `SET_${capsName}_TABLE_DIALOG_CLOSED`,
    setSelectedDialogItem: `SET_${capsName}_TABLE_DIALOG_SELECTED_ITEM`,
  }
  return {
    ...get,
    ...post,
    ...pagination,
    ...cache,
    ...selection,
    ...search,
    ...customTypes,
    ...dialog,
    ...deleteMethod,
  }
}

const getInitialState = (bundleName, extraFields, allowDelete, isVerboseFemale) => ({
  ...extraFields,
  items: [],
  next: null,
  previous: null,
  currentPage: 1,
  total: null,
  totalPages: null,
  loading: false,
  lastFetch: null,
  lastError: null,
  lastErrorData: null,
  openTableViewDialog: false,
  selectedTableItem: null,
  searchValue: '',
  lastSearchValueSet: null,
  cachedItems: {},
  pageSize: 30,
  selectedItems: [],
  allSelected: false,
  isVerboseFemale,
  allowDelete,
})

const getReducer = (bundleName, initialState, types) => {
  return (state = initialState, action) => {
    let index = null
    let newArr = null
    let itemsBuffer = {}
    switch (action.type) {
    case types.list.start:
    case types.retrieve.start:
    case types.create.start:
    case types.update.start:
      return {
        ...state,
        loading: true,
      }
    case types.list.error:
    case types.retrieve.error:
    case types.create.error:
    case types.update.error:
      return {
        ...state,
        lastFetch: Date.now(),
        loading: false,
        lastErrorData: action?.payload || null,
      }
    case types.list.succeed:
      itemsBuffer = state.cachedItems
      action.payload.items?.forEach(item => itemsBuffer[item.id] = item)
      localStorage.setItem(`cached${bundleName}Items`, JSON.stringify(itemsBuffer))
      return {
        ...state,
        ...action.payload,
        lastFetch: Date.now(),
        cachedItems: itemsBuffer,
        loading: false,
      }
    case types.retrieve.succeed:
    case types.update.succeed:
    case types.create.succeed:
      index = state.items.findIndex(item => item.id === action.payload.id)
      itemsBuffer = state.cachedItems
      itemsBuffer[action.payload.id] = action.payload
      localStorage.setItem(`cached${bundleName}Items`, JSON.stringify(itemsBuffer))
      if (index === -1) {
        return {
          ...state,
          cachedItems: itemsBuffer,
          loading: false,
        }
      }
      newArr = [...state.items]
      newArr[index] = action.payload
      return {
        ...state,
        items: newArr,
        cachedItems: itemsBuffer,
        loading: false,
      }
    case types.setPage:
      return {
        ...state,
        currentPage: action.payload,
      }
    case types.setPageSize:
      return {
        ...state,
        pageSize: action.payload,
      }
    case types.setCachedItems:
      return {
        ...state,
        cachedItems: action.payload,
      }
    case types.setSelected:
      newArr = [...state.items]
      newArr.sort()
      action.payload.sort()
      return {
        ...state,
        selectedItems: action.payload,
        allSelected: newArr.length === action.payload.length,
      }
    case types.setAllPageSelected:
      newArr = state.items.map(item => item.id)
      return {
        ...state,
        allSelected: true,
        selectedItems: newArr,
      }
    case types.clearSelected:
      return {
        ...state,
        allSelected: false,
        selectedItems: [],
      }
    case types.setSearchValue:
      return {
        ...state,
        searchValue: action.payload,
        lastSearchValueSet: Date.now(),
      }
    case types.clearSearchValue:
      return {
        ...state,
        searchValue: '',
        lastSearchValueSet: Date.now(),
      }
    case types.openDialog:
      return {
        ...state,
        openTableViewDialog: true,
        selectedTableItem: state.cachedItems[action.payload],
      }
    case types.closeDialog:
      return {
        ...state,
        openTableViewDialog: false,
      }
    case types.setSelectedDialogItem:
      return {
        ...state,
        selectedTableItem: action.payload,
      }
    default:
      return state
    }
  }
}

const getSelectors = (bundleName, camelCaseBundleName) => {
  const selectPageSize = state => state[camelCaseBundleName].pageSize
  const selectPage = state => state[camelCaseBundleName].currentPage
  const selectItems = state => state[camelCaseBundleName].items
  const selectCachedItems = state => state[camelCaseBundleName].cachedItems
  const selectSearchValue = state => state[camelCaseBundleName].searchValue
  const selectRaw = state => state[camelCaseBundleName]
  const selectSelectedItems = state => state[camelCaseBundleName].selectedItems
  const selectAllSelected = state => state[camelCaseBundleName].allSelected
  const selectSelectedItem = state => state[camelCaseBundleName].selectedTableItem
  const selectOpenDialogView = state => state[camelCaseBundleName].openTableViewDialog
  const selectAllowDelete = state => state[camelCaseBundleName].allowDelete
  const selectIsVerboseFemale = state => state[camelCaseBundleName].isVerboseFemale
  return {
    [`select${bundleName}TableItems`]: selectItems,
    [`select${bundleName}Loading`]: state => state[camelCaseBundleName].loading,
    [`select${bundleName}IsVerboseFemale`]: selectIsVerboseFemale,
    [`select${bundleName}CachedItems`]: selectCachedItems,
    [`select${bundleName}TablePageSize`]: selectPageSize,
    [`select${bundleName}TablePage`]: selectPage,
    [`select${bundleName}TableSearchValue`]: selectSearchValue,
    [`select${bundleName}TableRaw`]: selectRaw,
    [`select${bundleName}SelectedItems`]: selectSelectedItems,
    [`select${bundleName}AllSelected`]: selectAllSelected,
    [`select${bundleName}TableSelectedItem`]: selectSelectedItem,
    [`select${bundleName}TableDialogOpen`]: selectOpenDialogView,
    [`select${bundleName}TableAllowDelete`]: selectAllowDelete,
  }
}

const getActionCreators = (bundleName, initialState, types, url, verboseName, allowDelete, permission) => {
  const setPageSize = pageSize => async ({ dispatch, store }) => {
    const currentPageSize = store[`select${bundleName}TablePageSize`]()
    if (currentPageSize === pageSize) return
    await dispatch({ type: types.setPageSize, payload: pageSize })
    await store[`doFetch${bundleName}TableList`]()
  }
  const setPage = page => async ({ dispatch, store }) => {
    const currentPage = store[`select${bundleName}TablePage`]()
    if (currentPage === page) return
    await dispatch({ type: types.setPage, payload: page })
    await store[`doFetch${bundleName}TableList`]()
  }
  const fetchList = () => async ({ dispatch, store }) => {
    dispatch({ type: types.list.start })
    if (permission < store.selectKind()) return
    const access = store.selectAccessToken()
    const search = store[`select${bundleName}TableSearchValue`]()
    const page = store[`select${bundleName}TablePage`]()
    const pageSize = store[`select${bundleName}TablePageSize`]()
    // const filterParams = store.selectFilterParams()
    const filterParams = {}
    let res
    const opts = tokenHeader(access)
    const params = {
      page,
      pageSize,
      search,
      ...filterParams,
    }
    opts.params = params
    try {
      res = await axios.get(`/api/${url}/`, opts)
      dispatch({
        type: types.list.succeed,
        payload: res.data,
      })
    } catch (error) {
      dispatch({ type: types.list.error, payload: error?.response?.data })
      throw error
    }
  }
  const createItem = itemData => async ({ dispatch, store }) => {
    dispatch({ type: types.create.start})
    const access = store.selectAccessToken()
    const opts = tokenHeader(access)
    let res
    try {
      res = await axios.post(`/api/${url}/`, itemData, opts)
      dispatch({
        type: types.create.succeed,
        payload: res.data,
      })
      return res.data
    } catch(error) {
      store.doSetSnackbarFail(`Error al crear ${verboseName}.`)
      dispatch({
        type: types.create.error,
        payload: error?.response?.data,
      })
    }
  }
  const updateItem = itemData => async ({ dispatch, store }) => {
    dispatch({ type: types.update.start})
    const access = store.selectAccessToken()
    const opts = tokenHeader(access)
    let res
    try {
      res = await axios.put(`/api/${url}/${itemData.id}/`, itemData, opts)
      dispatch({
        type: types.update.succeed,
        payload: res.data,
      })
      const verb = store[`select${bundleName}IsVerboseFemale`]() ? 'actualizada' : 'actualizado'
      store.doSetSnackbarInfo(`${toTitleCase(verboseName)} ${verb}`)
      return res.data
    } catch(error) {
      store.doSetSnackbarFail(`Error al guardar ${verboseName}.`)
      dispatch({
        type: types.update.error,
        payload: error?.response?.data,
      })
      throw error
    }
  }
  const deleteItem = id => async ({ store, dispatch }) => {
    dispatch({ type: types.delete })
    const access = store.selectAccessToken()
    const opts = tokenHeader(access)
    try {
      await axios.delete(`/api/${url}/${id}/`, opts)
      await store[`doFetch${bundleName}TableList`]()
      store.doSetSnackbarInfo('Eliminado.')
    } catch (error) {
      store.doSetSnackbarFail('Error al eliminar.')
    }
  }
  const setCachedItems = cached => ({ dispatch }) => dispatch({ type: types.setCachedItems, payload: cached })
  const setSelectedItems = selectedItemsIds => ({ dispatch }) => dispatch({ type: types.setSelected, payload: selectedItemsIds })
  const setAllSelected = () => ({ dispatch }) => dispatch({ type: types.setAllPageSelected })
  const clearSelected = () => ({ dispatch }) => dispatch({ type: types.clearSelected })
  const setSearchValue = searchValue => async ({ store, dispatch }) => {
    dispatch({ type: types.setSearchValue, payload: searchValue })
    await store[`doFetch${bundleName}TableList`]()
  }
  const clearSearchValue = () => ({ dispatch }) => dispatch({ type: types.clearSearchValue })
  const openDialog = id => ({ dispatch }) => dispatch({ type: types.openDialog, payload: id })
  const closeDialog = () => ({ dispatch }) => {
    dispatch({ type: types.closeDialog })
  }
  const clearCached = () => ({ dispatch }) => {
    localStorage.removeItem(`cached${bundleName}Items`)
    dispatch({ type: types.setCachedItems, payload: {} })
  }
  const actionCreatorsObject = {
    [`doFetch${bundleName}TableList`]: fetchList,
    [`doSet${bundleName}TablePage`]: setPage,
    [`doSet${bundleName}TablePageSize`]: setPageSize,
    [`doCreate${bundleName}TableItem`]: createItem,
    [`doUpdate${bundleName}TableItem`]: updateItem,
    [`doSetCached${bundleName}Items`]: setCachedItems,
    [`doSet${bundleName}SelectedItems`]: setSelectedItems,
    [`doSetAll${bundleName}ItemsSelected`]: setAllSelected,
    [`doClear${bundleName}ItemsSelected`]: clearSelected,
    [`doSet${bundleName}SearchValue`]: setSearchValue,
    [`doClear${bundleName}SearchValue`]: clearSearchValue,
    [`doClear${bundleName}CachedItems`]: clearCached,
    [`doOpen${bundleName}TableDialog`]: openDialog,
    [`doClose${bundleName}TableDialog`]: closeDialog,
  }
  if (allowDelete) {
    actionCreatorsObject[`doDelete${bundleName}TableItem`] = deleteItem
  }
  return actionCreatorsObject
}

const getReactors = (bundleName, permission) => {
  const fetchReactor = createSelector(
    `select${bundleName}TableRaw`,
    'selectIsAuthenticated',
    'selectAppTime',
    'selectIsOnline',
    'selectKind',
    (raw, isAuthenticated, appTime, isOnline, kind) => {
      if (permission < kind) return
      if (!isAuthenticated) return
      if (raw.loading) return
      if (!isOnline) return
      let shouldFetch = false
      let timePassedLastFetch = null
      // if there are no entities, fetch
      if (raw.lastFetch) {
        timePassedLastFetch = appTime - raw.lastFetch
        if (raw.items.length === 0 && timePassedLastFetch > 60000) shouldFetch = true
        if (timePassedLastFetch > 60000 * 5) shouldFetch = true
      } else if (!raw.items.length) shouldFetch = true
      // if an error ocurred, wait 5s and retry
      if (raw.lastError) {
        const timePassed = appTime - raw.lastError
        if (timePassed > 10000) {
          shouldFetch = true
        }
      }
      // solve how to get out of next if when search fetch has happened already
      // if (raw.searchValue) {
      //   if (appTime - raw.lastSearchValueSet > 1000) shouldFetch = true
      // }
      if (shouldFetch) return { actionCreator: `doFetch${bundleName}TableList` }
    }
  )
  // const searchFetchReactor = createSelector(
  //   `select${bundleName}TableRaw`,
  //   'selectIsAuthenticated',
  //   'selectAppTime',
  //   (raw, isAuthenticated, appTime, isOnline) => {
  //     if (!isAuthenticated) return
  //     if (raw.loading) return
  //   },
  // )
  return {
    [`reactShouldFetch${bundleName}Items`]: fetchReactor,
  }
}

export default config => {
  const {
    bundleName,
    camelCaseBundleName,
    verboseName,
    url,
    extraFields = {},
    customTypes = {},
    allowDelete = true,
    isVerboseFemale = false,
    permission = 3,
  } = config
  const types = getTypes(bundleName, customTypes)
  const initialState = getInitialState(bundleName, extraFields, allowDelete, isVerboseFemale)
  const reducer = getReducer(bundleName, initialState, types)
  const actionCreators = getActionCreators(bundleName, initialState, types, url, verboseName, allowDelete, permission)
  const selectors = getSelectors(bundleName, camelCaseBundleName, initialState, types)
  const reactors = getReactors(bundleName, permission)
  const init = store => {
    const cachedLocalStorage = localStorage.getItem(`cached${bundleName}Items`)
    if (cachedLocalStorage === null) return
    const cachedEntities = JSON.parse(cachedLocalStorage)
    store[`doSetCached${bundleName}Items`](cachedEntities)
    const isAuth = store.selectIsAuthenticated()
    if (isAuth) store[`doFetch${bundleName}TableList`]()
  }
  return {
    name: camelCaseBundleName,
    reducer,
    ...selectors,
    ...actionCreators,
    ...reactors,
    init,
  }
}
