import React, { useRef, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Avatar, IconButton, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { ConversationHeader } from './ConversationHeader'
import CloseIcon from '@mui/icons-material/Close'
import { toMarkAsRead } from '../../utils/messages'
import CheckIcon from '@mui/icons-material/Check'
import { noop } from '../../utils/misc'
import { dateRange } from '../../utils/dates'
import { localeOptions } from '../../utils/dates'
import Linkify from 'react-linkify'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import { FinishConversation } from './AutomatedResponses'
import { useConnect } from 'redux-bundler-hook'

export const finishConversationMsg = 'finishConversation_2$3$qvuy*tf)lz+=gspv_%x_n9xd=*0=3+zz*57chzkzat(m93'

const customMessageTypes = {
  [finishConversationMsg]: FinishConversation,
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: ['"chatHeader"', '"messages"', '"textBar"'].join('\n'),
    gridTemplateRows: '60px 1fr 60px',
    height: '100%',
    width: '100%',
    maxWidth: '100%',
    minHeight: '100%',
  },
  chatHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: 'solid #ededed 1px',
    height: '60px',
    minHeight: '60px',
    maxHeight: '60px',
  },
  avatarDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    marginLeft: 10,
    gap: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textBar: {
    display: 'flex',
    gridArea: 'textBar',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  messages: {
    gridArea: 'messages',
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    gap: 20,
    backgroundColor: '#e3e3e3',
    maxWidth: '100%',
    overflowY: 'scroll',
  },
  isMeMessageStyles: {
    display: 'flex',
    justifyContent: 'end',
    // width: '100%',
  },
  isTheyMessageStyles: {
    display: 'flex',
    justifyContent: 'start',
    // width: '100%',
  },
  isMeBubbleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    maxWidth: '50%',
    // minWidth: '50%',
    textAlign: 'end',
    gap: 3,
  },
  isTheyBubbleContainer: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    maxWidth: '50%',
    textAlign: 'start',
    gap: 3,
  },
  isMeMessageBubble: {
    fontFamily: 'roboto',
    fontSize: 16,
    borderRadius: 15,
    backgroundColor: '#e8fecc',
    textAlign: 'left',
    padding: 10,
    wordWrap: 'break-word',
  },
  isTheyMessageBubble: {
    fontFamily: 'roboto',
    fontSize: 16,
    borderRadius: 15,
    backgroundColor: '#d1ebfd',
    padding: 10,
    textAlign: 'left',
    width: '100%',
    wordWrap: 'break-word',
  },
  isMeMessageDetail: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    fontFamily: 'roboto',
    fontSize: 10,
    textAlign: 'inherit',
    marginRight: 10,
    alignItems: 'center',
  },
  isTheyMessageDetail: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    fontFamily: 'roboto',
    fontSize: 10,
    textAlign: 'inherit',
    marginLeft: 10,
    alignItems: 'center',
  },
  divider: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  dividerChip: {
    borderRadius: 20,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    color: 'white',
    backgroundColor: 'grey',
    fontFamily: 'roboto',
    fontSize: 12,
    // fontWeight: 'bold',
  },
}))

const ChatWindowComponent = props => {
  const {
    currentMessages,
    userId,
    kind,
    currentConversation,
    doCloseConversations,
    doPostMessageToConversation,
    doMarkAsReadBulk,
    doFetchSelectedConversationMessages,
  } = props
  const hasSelected = !!currentConversation
  const bottomRef = useRef(null)
  const multiple = currentConversation?.users?.length > 2
  const otherUser = !multiple ? currentConversation?.users?.find(user => user.id != userId) : {}
  const [content, setContent] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  let validDates
  let groups = {}
  let dividers = []

  if (Array.isArray(currentMessages) && currentMessages.length) {
    validDates = dateRange(
      new Date(Date.parse(currentMessages[0].createdOn)),
      new Date(Date.parse(currentMessages.at(-1).createdOn)),
    )
    validDates = validDates.map(date => [date, []])
    groups = Object.fromEntries(validDates)
    currentMessages.forEach(message => {
      const dateObj = new Date(Date.parse(message.createdOn))
      if (!groups[dateObj.toLocaleDateString()].length) groups[dateObj.toLocaleDateString()].push({ type: 'dateDivider', dateObj})
      groups[dateObj.toLocaleDateString()].push(message)
    })
    dividers = Object.values(groups)
  }

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({behavior: 'smooth'})
    return async () => {
      const toMark = toMarkAsRead(currentMessages, userId)
      if (!toMark) return
      await doMarkAsReadBulk(toMark)
    }
  }, [currentMessages, currentConversation])
  const classes = useStyles()
  const onSendMessage = async () => {
    if (content === '') return
    const message = {
      sender: userId,
      receiver: otherUser?.id,
      conversation: currentConversation.id,
      content,
    }
    await doPostMessageToConversation(message, setContent)
  }

  return (
    <div className={classes.root}>
      <div className={classes.chatHeader}>
        <div className={classes.avatarDiv}>
          {hasSelected ? (
            <ConversationHeader currentConversation={currentConversation} userId={userId} />
          ) : (
            <>
              <Avatar sx={{ fontFamily: 'roboto' }}>?</Avatar>
              <Typography fontFamily='roboto'>
                Chat no seleccionado
              </Typography>
            </>
          )}
        </div>
        <IconButton onClick={doCloseConversations}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.messages}>
        {dividers.map(data => {
          return data.map(message => {
            if (message.type === 'dateDivider') return (
              <div key={`divider-${message?.id}`} className={classes.divider}>
                <div className={classes.dividerChip}>
                  {message.dateObj.toLocaleDateString('es-MX', localeOptions)}
                </div>
              </div>
            )
            const isMine = message.sender.id === userId
            const CustomContent = customMessageTypes[message.content]
            return (
              <div
                key={message.id}
                className={isMine ? classes.isMeMessageStyles : classes.isTheyMessageStyles}
              >
                <div
                  className={isMine ? classes.isMeBubbleContainer : classes.isTheyBubbleContainer}
                >
                  <div className={isMine ? classes.isMeMessageBubble : classes.isTheyMessageBubble}>
                    <Linkify properties={{ target: '_blank', style: { color: 'blue' } }}>
                      {CustomContent ? <CustomContent /> : message.content}
                    </Linkify>
                  </div>
                  <div className={isMine ? classes.isMeMessageDetail : classes.isTheyMessageDetail}>
                    {message.read && <CheckIcon sx={{ fontSize: 12, fill: isMine && '#34B7F1' }} />}
                    {new Date(Date.parse(message.createdOn)).toLocaleString()}
                  </div>
                </div>
              </div>
            )
          })
        })}
        <div ref={bottomRef} />
      </div>
      {hasSelected ? (
        <div className={classes.textBar}>
          <TextField
            autoFocus
            InputProps={{
              sx: { fontFamily: 'roboto' }
            }}
            value={content}
            onChange={e => setContent(e.target.value)}
            onKeyDown={async e => {
              if (e.key === 'Enter') await onSendMessage()
            }}
            size='small'
            fullWidth
            sx={{ fontFamily: 'roboto' }}
          />
          <IconButton onClick={onSendMessage}>
            <SendIcon />
          </IconButton>
          {kind <= 1 && (
            <Tooltip arrow title='Respuestas automatizadas'>
              <IconButton
                id='automated-res-button'
                aria-controls={open ? 'automated-menu' : undefined}
                aria-haspopup={true}
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <SmartToyIcon />
              </IconButton>
            </Tooltip>
          )}
          <Menu
            id='automated-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'automated-res-button',
            }}
          >
            <MenuItem
              onClick={async () => {
                await doPostMessageToConversation(
                  {
                    sender: userId,
                    receiver: otherUser?.id,
                    conversation: currentConversation.id,
                    content: finishConversationMsg,
                  },
                  noop
                )
                await doFetchSelectedConversationMessages()
              }}
            >
              Terminar conversacion
            </MenuItem>
          </Menu>
        </div>
      ) : <div style={{ backgroundColor: '#e3e3e3' }}></div>}
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectKind',
    'doPostMessageToConversation',
    'doFetchSelectedConversationMessages',
  )
  return <ChatWindowComponent {...connectedProps} {...props} />
}
