import React from 'react'
import TableMobile from '../components/TableMobile'
import { useConnect } from 'redux-bundler-hook'
import Button from '../components/common/Button'
import T from '../components/common/Typography'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditDialog from '../components/TableMobile/EditDialog'
import FileBrowser from '../components/Dialogs/FileBrowser'

const MobileApp = props => {
  const {
    ecgs,
    kind,
    ...rest
  } = props

  const {
    openEcgDialog,
    currentEcg,
    userId,
    loadingSelectedEcg,
    pdfKey,
    ecgDialogHistoricEcgs,
    doFetchEcgDialogData,
    doCloseEcgDialog,
    doUpdateUrl,
    doAddFile,
    doMarkEcgAsRead,
    doMarkEcgAsUnread,
  } = rest
  return (
    <div style={{ maxHeight: '75vh' }}>
      <EditDialog
        open={openEcgDialog}
        onClose={doCloseEcgDialog}
        ecgData={currentEcg}
        loading={loadingSelectedEcg}
        pdfKey={pdfKey}
        doUpdateUrl={doUpdateUrl}
        historicEcgs={ecgDialogHistoricEcgs}
        doFetchEcgDialogData={doFetchEcgDialogData}
        kind={kind}
        doAddFile={doAddFile}
        doMarkEcgAsUnread={doMarkEcgAsUnread}
        doMarkEcgAsRead={doMarkEcgAsRead}
        userId={userId}
      />
      <FileBrowser />
      {kind == 3 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: 5,
            marginRight: 15,
            paddingBottom: 5,
          }}>
          <Button
            icon={AddCircleOutlineIcon}
            height="33px"
            width="160px"
            styles={{
              backgroundColor: '#FFF',
              color: 'rgb(76, 100, 127)'
            }}
            borderRadius='40px'
            iconProps={{sx: {fill: 'red', height: '18px', width: '18px' } }}
            textTransform='none'
          >
            <T.Tiny sx={{fontWeight: '550'}}>
              Nuevo estudio
            </T.Tiny>
            {/* Nuevo Estudio */}
            {/* <p>Nuevo Estudio</p> */}
          </Button>
        </div>
      )}
      <TableMobile
        data={ecgs}
        kind={kind}
        {...rest}
      />
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectEcgs',
    'selectEcgSearchValue',
    'selectUser',
    'selectUserCenter',
    'selectUserId',
    'selectKind',
    'selectEcgsRaw',
    'selectOpenEcgDialog',
    'selectRouteParams',
    'selectUserFlags',
    'selectPdfKey',
    'selectFilterValue',
    'selectCurrentEcg',
    'selectEcgDialogHistoricEcgs',
    'selectLoadingSelectedEcg',
    'selectActiveNotifications',
    'selectEcgsLoading',
    'doOpenUploadComponent',
    'doCloseUploadComponent',
    'doOpenDrawer',
    'doSetSelectedEcgId',
    'doSetSelectedEcg',
    'doOpenEcgDialog',
    'doUpdateUrl',
    'doCloseEcgDialog',
    'doFetchEcgDialogData',
    'doSetSearchValue',
    'doUpdateFilter',
    'doChangeProfileKind',
    'doSetOpenedFromTable',
    'doSetEcgsCurrentPage',
    'doSetEcgsPageSize',
    'doAddFile',
    'doMarkEcgAsRead',
    'doMarkEcgAsUnread',
    'doOpenConversations',
  )

  return <MobileApp {...connectedProps} {...props} />
}
