import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { makeStyles } from '@mui/styles'
import { useDebounce } from '@uidotdev/usehooks'
import { noop } from '../../utils/misc'

const useStyles = makeStyles((theme) => ({
  search: {
    display: 'flex',
    position: 'relative',
    borderRadius: 20,
    borderStyle: 'solid',
    borderColor: '#EAEAEA',
    borderWidth: 3,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints?.up('sm')]: {
      width: '100%',
    },
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.2)',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    opacity: 0.22,
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '90%',
    paddingLeft: 45,
  },
  mobileInputRoot: {
    color: 'inherit',
    width: '90%',
    paddingLeft: 35,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: theme.spacing(8),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },
}))

const SearchBar = props => {
  const classes = useStyles()
  const {
    setSearchValue,
    searchValue,
    placeholder,
    iconSize,
    height,
    width,
    isMobile,
    setIsLoading = noop,
    // setIsIdle = noop,
    debounceTime = 100,
  } = props
  const [renderedTerm, setRenderedTerm] = useState(searchValue)
  const debouncedSearchTerm = useDebounce(renderedTerm, debounceTime)

  useEffect(() => {
    if (debouncedSearchTerm) {
      setSearchValue(debouncedSearchTerm)
    } else {
      setSearchValue('')
    }
  }, [debouncedSearchTerm])

  return (
    <div className={classes.search} style={{height: height, width: width}}>
      <div className={classes.searchIcon}>
        <SearchIcon sx={{ fontSize: iconSize }} />
      </div>
      <InputBase
        placeholder={placeholder}
        classes={{
          root: isMobile ? classes.mobileInputRoot : classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={renderedTerm}
        onChange={e => {
          setRenderedTerm(e.target.value)
          setIsLoading()
        }}
        style={{ fontFamily: 'roboto' }}
      />
    </div>
  )
}

export default SearchBar

SearchBar.propTypes = {
  setSearchValue: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  debounceTime: PropTypes.number,
  setIsLoading: PropTypes.func,
  setIsIdle: PropTypes.func,
}
