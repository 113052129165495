
import createModelBundle from './modelBundle'


const config = {
  bundleName: 'DiscountTag',
  camelCaseBundleName: 'discountTag',
  verboseName: 'e-tag',
  url: 'discountTags',
  allowDelete: false,
  permission: 1,
}

const baseBundle = createModelBundle(config)

export default {
  ...baseBundle,
}
