export const types = {
  OPEN_DRAWER: 'OPEN_DRAWER',
  CLOSE_DRAWER: 'CLOSE_DRAWER',
}

const initialState = {
  open: false,
  saving: false,
  formData: {},
}

export default {
  name: 'drawer',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.OPEN_DRAWER:
      return {
        ...state,
        open: true,
      }
    case types.CLOSE_DRAWER:
      return {
        ...state,
        open: false,
      }
    default:
      return state
    }
  },
  selectDrawerOpen: state => state.drawer.open,
  doOpenDrawer: () => ({ dispatch }) => dispatch({ type: types.OPEN_DRAWER }),
  doCloseDrawer: () => ({ dispatch }) => dispatch({ type: types.CLOSE_DRAWER }),
}
