import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import { useConnect } from 'redux-bundler-hook'
import ConfirmationDialog from '../../../common/ConfirmationDialog'

const FONT_SIZE = '0.9rem'
const MIN_WIDTH = 110
const MIN_HEIGHT = 40

const DoctorActions = props => {
  const {
    id,
    formik,
    onSubmit,
    currentEcg,
    classes,
    confirmationDialogPref,
    doSetSelectedEcg,
    doRejectECG,
    doSetECGRedCode,
    doAcceptECG,
    doFetchECGS,
    doCloseEcgDialog,
    doFetchHistoricData,
    doUpdateUrl,
  } = props

  const confirmationDialog = confirmationDialogPref?.value
  const acceptAction = async () => {
    const valid = await onSubmit(formik.values)
    if (!valid) return
    const res = await doAcceptECG(id)
    if (res) {
      await doSetSelectedEcg(res)
      await doFetchHistoricData(res)
      await doCloseEcgDialog()
      await doUpdateUrl('/')
      await doFetchECGS()
    }
  }
  const rejectAction = async () => {
    const valid = await onSubmit(formik.values)
    if (!valid) return
    const res = await doRejectECG(id)
    if (res) {
      await doSetSelectedEcg(res)
      await doFetchHistoricData(res)
      await doCloseEcgDialog()
      await doUpdateUrl('/')
      await doFetchECGS()
    }
  }
  const redCodeAction = async () => {
    const valid = await onSubmit(formik.values)
    if (!valid) return
    const res = await doSetECGRedCode(id)
    if (res) {
      await doSetSelectedEcg(res)
      await doFetchHistoricData(res)
      await doCloseEcgDialog()
      await doUpdateUrl('/')
      await doFetchECGS()
    }
  }
  const [openAccept, setOpenAccept] = useState(false)
  const [openReject, setOpenReject] = useState(false)
  const [openRedCode, setOpenRedCode] = useState(false)

  return (
    <div className={classes.actionsColumn}>
      <ConfirmationDialog
        open={openAccept}
        setOpen={setOpenAccept}
        truthyAction={acceptAction}
        onClose={() => setOpenAccept(false)}
        title='✅ Diagnosticar ECG'
        description='Confirme para aceptar el estudio.'
      />
      <ConfirmationDialog
        open={openReject}
        setOpen={setOpenReject}
        truthyAction={rejectAction}
        onClose={() => setOpenReject(false)}
        truthyActionText='Rechazar'
        truthyActionStyle={{ backgroundColor: '#F7C107' }}
        title='❌ Rechazar ECG'
        description='Confirme para rechazar el estudio.'
      />
      <ConfirmationDialog
        open={openRedCode}
        setOpen={setOpenRedCode}
        truthyAction={redCodeAction}
        onClose={() => setOpenRedCode(false)}
        truthyActionText='Código rojo'
        truthyActionStyle={{ backgroundColor: '#F94654', color: '#ffffff' }}
        title='🔴 Diagnosticar ECG: Código rojo'
        description='Confirme para marcar el estudio como código rojo.'
      />
      <Button
        name='accept'
        variant='contained'
        onClick={confirmationDialog ? () => setOpenAccept(true) : acceptAction}
        sx={{
          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#87CA42',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Diagnosticar
        </Typography>
      </Button>
      <Button
        name='reject'
        onClick={confirmationDialog ? () => setOpenReject(true) : rejectAction}
        variant='contained'
        sx={{

          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#707070',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Rechazar
        </Typography>
      </Button>
      <Button
        name='redCode'
        variant='contained'
        onClick={confirmationDialog ? () => setOpenRedCode(true) : redCodeAction}
        sx={{

          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#FB4D60',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Código Rojo
        </Typography>
      </Button>
      <Button
        onClick={async () => {
          const valid = await onSubmit(formik.values)
          if (!valid) return
          await doFetchHistoricData(currentEcg)
        }}
        variant='contained'
        sx={{

          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#486581',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Guardar
        </Typography>
      </Button>
      <Button
        onClick={() => {
          doCloseEcgDialog()
          doUpdateUrl('/')
        }}
        variant='contained'
        sx={{

          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#9CA7B2',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Cancelar
        </Typography>
      </Button>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectCurrentEcg',
    'doRejectECG',
    'doSetECGRedCode',
    'doAcceptECG',
    'doEditECG',
    'doSetSelectedEcg',
    'doFetchECGS',
    'doCloseEcgDialog',
    'doFetchHistoricData',
    'doFetchEcgChangeHistory',
  )
  return <DoctorActions {...props} {...connectedProps} />
}
