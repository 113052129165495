import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import T from './Typography'

const useStyles = makeStyles(theme => {
  return {
    root: {
      backgroundColor: theme.palette?.backgroundColor.main || '#F5F5F5',
      textAlignLast: 'center',
      border: 0,
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
    },
    paper: {
      borderRadius: '20px',
    },
    selected: {
      backgroundColor: theme.palette?.secondary?.main ?? '#486581',
      color: 'white',
    }}
})

const Placeholder = ({ children, ...rest }) => {
  return <T.Tiny {...rest }>{children}</T.Tiny>
}

const ECGSelect = props => {
  const classes = useStyles()
  const {
    placeholder,
    options,
    value,
    onValueChange,
    placeholderStyle,
    dense,
    width,
    height,
    disabled,
    displayEmpty,
    styleOverrides = {},
    ...rest
  } = props

  const getOptions = Array.isArray(options) ? options : options()
  const widthValue = width !== undefined ? width : dense ? '100px' : '100%'
  const heightValue = height ? height : dense ? 45 : '100%'

  return (
    <FormControl
      sx={{
        maxWidth: widthValue,
        ...styleOverrides,
      }}
    >
      <Select
        displayEmpty={displayEmpty}
        disabled={disabled}
        variant='outlined'
        sx={{
          borderRadius: '40px 40px 40px 40px',
          borderColor: '#486581',
          backgroundColor: '#FFFFFF',
          height: heightValue,
          minWidth: widthValue,
        }}
        className={classes.root}
        renderValue={
          value !== '' ? undefined : () => <Placeholder style={placeholderStyle}>{placeholder}</Placeholder>
        }
        value={value}
        onChange={onValueChange}
        {...rest}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: 5,
              backgroundColor: '#F5F5F5',
            }
          }
        }}
      >
        {getOptions?.map(child => (
          <MenuItem
            key={child.key}
            value={child.value}
            classes={{ selected: classes.selected }}
          >
            {child.displayValue}
          </MenuItem>
        ))}
      </Select>
      <InputLabel
        htmlFor={rest?.name}
        sx={{
          position: 'absolute',
          left: -5,
          top: -6,
        }}
      >
        {placeholder}
      </InputLabel>
    </FormControl>
  )
}

ECGSelect.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValueChange: PropTypes.func.isRequired,
  dense: PropTypes.bool,
  disabled: PropTypes.bool,
  displayEmpty: PropTypes.bool,
}
ECGSelect.defaultProps = {
  displayEmpty: true,
}

export default ECGSelect
