import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Chip, Typography } from '@mui/material'
import { getPopoverInfo } from '../../utils/ecg'
import { noop } from '../../utils/misc'
import StatusLed from '../common/StatusLed'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    borderRadius: 25,
    backgroundColor: 'white',
    padding: '0 8px',
  },
  row: {
    display: 'grid',
    gridTemplateAreas: `
      "statusLed patientData statusChips"
      "actionButtons actionButtons actionButtons"
    `,
    gridTemplateColumns: '1fr 12fr 5fr',
    gridTemplateRows: '75px auto',
    width: '100%',
  },
  expandMenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  collapse: {
    gridColumn: '1 / 4'
  },
  statusLed: {
    borderRadius: 100,
    width: 30,
    height: '100%',
    marginRight: 8,
  },
  statusChips: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  patientData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  patientDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateTime: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  },
  centerInfo: {
    display: 'flex',
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

const TablePatientDetail = props => {
  const {
    ecgData,
    setOpenDialog = noop,
  } = props

  const classes = useStyles()

  const ts = new Date(ecgData.createdOn)
  const date = ts.toLocaleDateString()
  const time = ts.toLocaleTimeString()
  const interpreted = `${ecgData.withInterpretation ? 'CI' : 'SI'}`
  const centerInfoString = ecgData.centerInfo.name.toUpperCase()
  const { validChips } = getPopoverInfo(ecgData)


  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <StatusLed ecg={ecgData} />
        <div
          className={classes.patientData}
          onClick={() => setOpenDialog(true)}
        >
          <Typography
            sx={{
              fontWeight: 800,
              color: '#707070',
              fontSize: '14px',
              textDecoration: ecgData.rejected ? 'line-through' : 'none',
            }}
          >
            {ecgData?.patient?.name?.toUpperCase()}
          </Typography>
          <div className={classes.patientDetails}>
            <Typography
              sx={{
                fontWeight: 400,
                color: '#707070',
                fontSize: '12px',
                fontFamily: 'roboto'
              }}
            >
              {`${date} - ${time}`}
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                color: '#707070',
                fontSize: '12px',
                fontFamily: 'roboto'
              }}
            >
              {`${centerInfoString} / ${interpreted}`}
            </Typography>
          </div>
        </div>
        <div className={classes.statusChips}>
          {validChips.map(chipData => {
            const { Icon } = chipData
            return (
              <Chip
                key={chipData.label}
                label={chipData.label}
                color={chipData.color}
                icon={<Icon {...chipData.iconProps} />}
                size='small'
                style={{ width: '97px' }}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

TablePatientDetail.propTypes = {
  isEditable: PropTypes.bool,
  ecgData: PropTypes.shape({
    patient: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    createdOn: PropTypes.string.isRequired,
    centerInfo: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
TablePatientDetail.defaultProps = {
  isEditable: false,
}

export default TablePatientDetail
