export const propsReplacer = (propsMap, value) => {
  const replacedPropsEntries = Object.entries(propsMap)
    .map(([propName, propValue]) => (
      propValue === 'VALUE' ? [propName, value] : [propName, propValue]
    ))
  return Object.fromEntries(replacedPropsEntries)
}

export const childrenMapReplacer = (childrenMap, value) => (
  childrenMap.map(child => child === 'VALUE' ? value : child)
)

export const isBoolEmoji = bool => bool ? '✅' : '❌'
export const valueFormatter = (value) => {
  switch (typeof value) {
  case 'boolean':
    return isBoolEmoji(value)
  default:
    return value
  }
}

export const tableDataProcessor = (data, headerFields) => {
  const tableFields = headerFields.map(fieldData => fieldData.field)
  const mainFieldAndNestedMap = headerFields.map(fieldData => ([fieldData.field, fieldData.nestedProps])).filter(fArray => fArray[1] !== undefined)
  const nestedFieldsByMainField = Object.fromEntries(mainFieldAndNestedMap)
  const processedData = data.map(item => {
    const filteredFields = tableFields.map(field => [field, item[field]])
    const cellsData = filteredFields.map(([field, value]) => {
      const fieldArray = nestedFieldsByMainField[field] ?? []
      let finalValue = value
      fieldArray.forEach(nextDepth => {
        finalValue = value[nextDepth]
      })
      const fieldHeaderData = { ...headerFields.find(hField => hField.field === field) }
      const replacedProps = propsReplacer(fieldHeaderData.propsMap, value)
      const formattedValue = valueFormatter(finalValue)
      return {
        key: fieldHeaderData.key,
        component: fieldHeaderData.component,
        props: replacedProps,
        value: formattedValue,
      }
    })
    return {
      cells: cellsData,
      id: item.id,
    }
  })
  return processedData
}
