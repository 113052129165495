import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import * as Yup from 'yup'
import TextField from '../../../components/common/TextField'
import { makeStyles } from '@mui/styles'

const centerFormInitialValues = {
  name: '',
  city: '',
  address: '',
  email: '',
  template: null,
  timezone: null,
}

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const CentersAdminDialog = props => {
  const classes = useStyles()
  const {
    open,
    item = centerFormInitialValues,
    mode = 'edit',
    doCloseCenterTableDialog,
  } = props
  const formik = useFormik({
    initialValues: item,
    onSubmit: async () => {},
    validationSchema: Yup.object({
      name: Yup.string().required('Campo requerido.'),
      city: Yup.string().required('Campo requerido.'),
      address: Yup.string().required('Campo requerido.'),
      email: Yup.string(),
      template: Yup.number().required('Campo requerido.'),
      timezone: Yup.string().required('Campo requerido.'),
    }),
    validateOnChange: true,
  })
  return (
    <Dialog
      open={open}
      onClose={doCloseCenterTableDialog}
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle>{item.name}</DialogTitle>
      <DialogContent>
        <form className={classes.content} onSubmit={formik.handleSubmit}>
          <Typography
            style={{
              color: '#707070',
            }}
          >
            Nombre
          </Typography>
          <TextField
            fullWidth
            placeholder='Nombre'
            adornmentPosition='start'
            name='name'
            id='name'
            onValueChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={Boolean(formik.touched.name && formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name ? (
              formik.errors.name
            ) : null}
            helperTextStyles={{color: 'red'}}
          />
          <Typography
            style={{
              color: '#707070',
            }}
          >
            Ciudad
          </Typography>
          <TextField
            fullWidth
            placeholder='Ciudad'
            adornmentPosition='start'
            name='city'
            id='city'
            onValueChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
            error={Boolean(formik.touched.city && formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city ? (
              formik.errors.city
            ) : null}
            helperTextStyles={{color: 'red'}}
          />
          <Typography
            style={{
              color: '#707070',
            }}
          >
            Dirección
          </Typography>
          <TextField
            fullWidth
            placeholder='Dirección'
            adornmentPosition='start'
            name='address'
            id='address'
            onValueChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            error={Boolean(formik.touched.address && formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address ? (
              formik.errors.address
            ) : null}
            helperTextStyles={{color: 'red'}}
          />
          <Typography
            style={{
              color: '#707070',
            }}
          >
            Email
          </Typography>
          <TextField
            fullWidth
            placeholder='Email'
            adornmentPosition='start'
            name='email'
            id='email'
            onValueChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email ? (
              formik.errors.email
            ) : null}
            helperTextStyles={{color: 'red'}}
          />
          <Typography
            style={{
              color: '#707070',
            }}
          >
            Usuario
          </Typography>
          {mode === 'edit' ? (
            <Typography
              style={{
                fontFamily: 'roboto',
                fontSize: '1rem',
              }}
            >
              {item.user.username}
            </Typography>
          ) : (
            <Typography>None</Typography>
          )}
        </form>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectCenterTableRaw',
    'doCloseCenterTableDialog',
  )
  const { centerTableRaw } = connectedProps
  const { selectedTableItem, openTableViewDialog } = centerTableRaw
  return <CentersAdminDialog {...props} {...connectedProps} item={selectedTableItem} open={openTableViewDialog} />
}
