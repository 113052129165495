import axios from 'axios'
import { tokenHeader } from '../utils'

const typeNames = [
  'OPEN_PREFERENCES',
  'CLOSE_PREFERENCES',
  'PREFERENCES_LOADING',
  'PREFERENCES_IDLE',
  'UPDATE_PREFERENCE',
]

export const types = Object.fromEntries(typeNames.map(type => [type, type]))
const initialState = {
  open: false,
  loading: false,
}

const normalizeUserPreference = userPreference => {
  const newObj = { ...userPreference }
  if (!Number.isInteger(newObj.preference)) newObj.preference = userPreference.preference.id
  return newObj
}

export default {
  name: 'preferences',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.OPEN_PREFERENCES:
      return {
        ...state,
        open: true,
      }
    case types.CLOSE_PREFERENCES:
      return {
        ...state,
        open: false,
      }
    case types.PREFERENCES_LOADING:
      return {
        ...state,
        loading: true
      }
    case types.PREFERENCES_IDLE:
      return {
        ...state,
        loading: false
      }
    default:
      return state
    }
  },
  selectPreferencesOpen: state => state.preferences.open,
  selectPreferencesLoading: state => state.preferences.loading,
  doOpenPreferences: () => ({ dispatch }) => dispatch({ type: types.OPEN_PREFERENCES }),
  doClosePreferences: () => ({ dispatch }) => dispatch({ type: types.CLOSE_PREFERENCES }),
  doUpdateUserPreference: userPreference => async ({ dispatch, store }) => {
    dispatch({ type: types.PREFERENCES_LOADING })
    const id = userPreference.id
    const access = store.selectAccessToken()
    const data = normalizeUserPreference(userPreference)
    try {
      await axios.put(`/api/userPreferences/${id}/`, data, tokenHeader(access))
      await store.doLoadUser()
      store.doSetSnackbarSuccess('Preferencia guardada')
      dispatch({ type: types.PREFERENCES_IDLE })
    } catch (error) {
      store.doSetSnackbarFail('Error al modificar preferencias')
      dispatch({ type: types.PREFERENCES_IDLE })
    }
  },
}
