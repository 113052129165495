import React from 'react'
import { makeStyles } from '@mui/styles'
import { useConnect } from 'redux-bundler-hook'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Typography } from '@mui/material'
import TextField from '../components/common/TextField'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgb(247,247,247)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
}))

const ResetPasswordComponent = props => {
  const { doResetPassword, urlObject } = props
  const { search } = urlObject
  const urlParams = new URLSearchParams(search)
  const token = urlParams.has('token') ? urlParams.get('token') : undefined
  let error = false
  if (token === undefined) {
    error = true
  }
  const classes = useStyles()
  const helperTextStylesError = {
    color: 'red'
  }

  const formik = useFormik({
    initialValues: {
      password1: '',
      password2: '',
    },
    onSubmit: async (values) => {
      await doResetPassword(token, values)
    },
    validationSchema: Yup.object({
      password1: Yup.string().required('Campo requerido'),
      password2: Yup.string().required('Campo requerido'),
    }),
    validateOnChange: true,
  })
  return (
    <div className={classes.root}>
      {error ? (
        <Typography fontSize={20}>El link es incorrecto o está expirado.</Typography>
      ) : (
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Typography fontSize={20}>Reestablecer contraseña</Typography>
          <TextField
            autoFocus
            placeholder='Nueva Contraseña'
            adornmentPosition='start'
            name='password1'
            id='password1'
            onValueChange={formik.handleChange}
            value={formik.values.password1}
            onBlur={formik.handleBlur}
            hidden
            error={Boolean(formik.touched.password1 && formik.errors.password1)}
            helperTextStyles={helperTextStylesError}
            helperText={formik.touched.password1 && formik.errors.password1 ? (
              formik.errors.password1
            ) : null}
            styles={{
              texttransform: 'lowercase',
              minWidth: '50vw',
            }}
            disabled={formik.isSubmitting}
            autoCapitalize='off'
          />
          <TextField
            placeholder='Repita su contraseña'
            adornmentPosition='start'
            name='password2'
            id='password2'
            hidden
            onValueChange={formik.handleChange}
            value={formik.values.password2}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.password2 && formik.errors.password1)}
            helperTextStyles={helperTextStylesError}
            helperText={formik.touched.password2 && formik.errors.password2 ? (
              formik.errors.password2
            ) : null}
            styles={{
              texttransform: 'lowercase',
              minWidth: '50vw',
            }}
            disabled={formik.isSubmitting}
            autoCapitalize='off'
          />
          <Button type='submit' variant='outlined' onClick={formik.handleSubmit}>
            Reestablecer
          </Button>
        </form>
      )}
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'doResetPassword',
    'selectUrlObject',
    'doUpdateUrl',
  )
  return <ResetPasswordComponent {...props} {...connectedProps} />
}
