import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import T from '../common/Typography'
import JSONPretty from 'react-json-pretty'
import ReplayIcon from '@mui/icons-material/Replay'
import 'react-json-pretty/themes/acai.css'

const StatsForNerdsDialog = props => {
  const {
    openNerdStatsDialog,
    server,
    doCloseStatsForNerdsDialog,
    doFetchStatsForNerds,
  } = props
  const {
    statsForNerds,
  } = server
  return (
    <Dialog
      open={openNerdStatsDialog}
      onClose={() => doCloseStatsForNerdsDialog()}
      maxWidth='md'
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 20,
          backgroundColor: 'black',
          minHeight: '60vh',
        }
      }}
    >
      <DialogTitle>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <T.H4 style={{ color: 'white', fontFamily: 'roboto' }}>Stats 4 Nerds 🤓</T.H4>
          <IconButton onClick={doFetchStatsForNerds}>
            <Tooltip arrow placement='top' title='Recargar'>
              <ReplayIcon color='white' />
            </Tooltip>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', borderRadius: 15, backgroundColor: '#1E1E1E', margin: 10, flexDirection: 'column' }}>
          <div style={{ color: 'white', backgroundColor: '#393B40', borderTopLeftRadius: 15, borderTopRightRadius: 15, paddingLeft: 15 }}>
            <pre>GET /api/health/</pre>
          </div>
          <div style={{ padding: 15 }}>
            <JSONPretty id='stats-for-nerds' data={statsForNerds} />
          </div>
        </div>
        <pre style={{ color: 'grey', paddingLeft: 15 }}>
          <small color='white'>Si no eres desarrollador, no deberías ver esto...</small>
        </pre>
      </DialogContent>
    </Dialog>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectOpenNerdStatsDialog',
    'selectServer',
    'doCloseStatsForNerdsDialog',
    'doFetchStatsForNerds',
  )
  return <StatsForNerdsDialog {...props} {...connectedProps} />
}
