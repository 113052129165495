import React, { useRef } from 'react'
import './CodeArea.css'

const CodeEditor = (props) => {
  const { code = '', setCode } = props
  const lineNumbersRef = useRef(null)
  const textareaRef = useRef(null)

  const handleChange = (e) => {
    setCode(e.target.value)
  }

  const handleScroll = () => {
    lineNumbersRef.current.scrollTop = textareaRef.current.scrollTop
  }

  const lineNumbers = code.split('\n').length

  return (
    <div className="code-editor">
      <div className="line-numbers" ref={lineNumbersRef}>
        {[...Array(lineNumbers)].map((_, i) => (
          <div key={i} className="line-number">
            {i + 1}
          </div>
        ))}
      </div>
      <textarea
        className="code-input"
        value={code}
        onChange={handleChange}
        onScroll={handleScroll}
        spellCheck="false"
        ref={textareaRef}
      />
    </div>
  )
}

export default CodeEditor
