import axios from 'axios'
import { types as authTypes } from './auth'
import { createSelector } from 'redux-bundler'
import { tokenHeader } from '../utils'

export const types = {
  GET_BASE_URL: 'GET_BASE_URL',
  GET_PDF_KEY: 'GET_PDF_KEY',
  GET_SERVER_INFO: 'GET_SERVER_INFO',
  GET_SERVER_INFO_FAIL: 'GET_SERVER_INFO_FAIL',
  GET_CLIENT_VERSION: 'GET_CLIENT_VERSION',
  GET_SERVER_INFO_LOADING: 'GET_SERVER_INFO_LOADING',
  SET_SERVER_INFO: 'SET_SERVER_INFO',
  GET_STATS_FOR_NERDS: 'GET_STATS_FOR_NERDS',
  OPEN_STATS_FOR_NERDS: 'OPEN_STATS_FOR_NERDS',
  CLOSE_STATS_FOR_NERDS: 'CLOSE_STATS_FOR_NERDS',
}

const initialState = {
  baseUrl: '',
  pdfKey: '',
  backendVersion: '',
  clientVersion: '',
  openNerdStatsDialog: false,
  statsForNerds: null,
  loading: false,
  lastFetch: null,
  lastError: null,
  error: null,
}

export default {
  name: 'server',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.GET_SERVER_INFO_LOADING:
      return {
        ...state,
        loading: true,
      }
    case types.GET_SERVER_INFO_FAIL:
      return {
        ...state,
        error: true,
        loading: false,
        lastError: Date.now(),
      }
    case types.GET_CLIENT_VERSION:
      return {
        ...state,
        clientVersion: action.payload,
        lastFetch: Date.now(),
        lastError: null,
        error: false,
        loading: false,
      }
    case types.GET_BASE_URL:
      return {
        ...state,
        baseUrl: action.payload?.baseUrl,
        lastFetch: Date.now(),
        lastError: null,
        error: false,
        loading: false,
      }
    case types.GET_PDF_KEY:
      return {
        ...state,
        pdfKey: action.payload?.pdfKey,
        lastFetch: Date.now(),
        lastError: null,
        loading: false,
        error: false,
      }
    case types.GET_SERVER_INFO:
      return {
        ...state,
        ...action.payload,
        lastFetch: Date.now(),
        lastError: null,
        loading: false,
        error: false,
      }
    case types.SET_SERVER_INFO:
      return {
        ...state,
        ...action.payload,
      }
    case types.GET_STATS_FOR_NERDS:
      return {
        ...state,
        statsForNerds: action.payload,
      }
    case types.OPEN_STATS_FOR_NERDS:
      return {
        ...state,
        openNerdStatsDialog: true,
      }
    case types.CLOSE_STATS_FOR_NERDS:
      return {
        ...state,
        openNerdStatsDialog: false,
      }
    default:
      return state
    }
  },
  selectServer: state => state.server,
  selectServerBundleLoading: state => state.server.loading,
  selectPdfKey: state => state.server.pdfKey,
  selectOpenNerdStatsDialog: state => state.server.openNerdStatsDialog,
  selectStatsForNerds: state => state.server.statsForNerds,
  selectClientVersion: state => state.server.clientVersion,
  doOpenStatsForNerdsDialog: () => ({ dispatch }) => dispatch({ type: types.OPEN_STATS_FOR_NERDS }),
  doCloseStatsForNerdsDialog: () => ({ dispatch }) => dispatch({ type: types.CLOSE_STATS_FOR_NERDS }),
  doCacheServerInfo: () => ({ store }) => globalThis.localStorage.setItem('cachedServerInfo', JSON.stringify(store.selectServer())),
  doSetServerInfo: data => ({ dispatch }) => dispatch({ type: types.SET_SERVER_INFO, payload: data }),
  doClearServerInfo: () => ({ dispatch }) => {
    globalThis.localStorage.setItem('cachedServerInfo', JSON.stringify({}))
    dispatch({ type: types.SET_SERVER_INFO, payload: initialState })
  },
  doFetchBaseURL: () => async ({ dispatch, store }) => {
    dispatch({ type: types.GET_SERVER_INFO_LOADING })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/baseUrl', tokenHeader(access))
      dispatch({
        type: types.GET_BASE_URL,
        payload: res.data,
      })
      store.doCacheServerInfo()
    } catch (error) {
      dispatch({ type: types.GET_SERVER_INFO_FAIL })
      console.log(error)
    }
  },
  doFetchPDFKey: () => async ({ dispatch, store }) => {
    dispatch({ type: types.GET_SERVER_INFO_LOADING })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/pdfKey', tokenHeader(access))
      dispatch({
        type: types.GET_PDF_KEY,
        payload: res.data,
      })
      store.doCacheServerInfo()
    } catch (error) {
      dispatch({ type: types.GET_SERVER_INFO_FAIL })
      console.error(error)
    }
  },
  doFetchServerInfo: () => async ({ dispatch, store }) => {
    dispatch({ type: types.GET_SERVER_INFO_LOADING })
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/server', tokenHeader(access))
      store.dispatch({
        type: types.GET_SERVER_INFO,
        payload: res.data,
      })
      store.doCacheServerInfo()
      store.doSetAppIdle()
    } catch (error) {
      dispatch({ type: types.GET_SERVER_INFO_FAIL })
      console.error(error)
      store.doSetAppIdle()
    }
  },
  doFetchClientVersion: () => async ({ dispatch, store }) => {
    dispatch({ type: types.GET_SERVER_INFO_LOADING })
    store.doSetAppLoading()
    let res
    try {
      res = await axios.get('/api/clientVersion')
      const data = res.data
      localStorage.setItem('latestVersion', data.latestClientVersion)
      store.doCacheServerInfo()
      store.doSetAppIdle()
      dispatch({
        type: types.GET_CLIENT_VERSION,
        payload: data.latestClientVersion,
      })
    } catch (error) {
      dispatch({ type: types.GET_SERVER_INFO_FAIL })
      console.error(error)
    }
  },
  doFetchStatsForNerds: () => async ({ store, dispatch }) => {
    const access = store.selectAccessToken()
    let res
    try {
      res = await axios.get('/api/health', tokenHeader(access))
      const data = res.data
      dispatch({
        type: types.GET_STATS_FOR_NERDS,
        payload: data,
      })
    } catch (error) {
      store.doSetSnackbarFail('Error while loading stats 🤦‍ ⚙️')
    }
  },
  doCreateInterceptor: () => ({ store }) => {
    // request interceptor
    axios.interceptors.request.use(request => {
      const accessToken = localStorage.getItem('access')
      if (accessToken) {
        request.headers['Authorization'] = `Bearer ${accessToken}`
      }
      return request
    }, error => {
      return Promise.reject(error)
    })

    // response interceptor
    // FIXME: sometimes there are infinite loops in prod
    axios.interceptors.response.use(
      response => response,
      async error => {
        const route = store.selectRoute()
        const { isPublic = false } = route
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
          if (isPublic) return
          originalRequest._retry = true // Mark the request as retried to avoid infinite loops.
          try {
            store.dispatch({ type: authTypes.REFRESH_TOKEN_START })
            const refresh = localStorage.getItem('refresh') // Retrieve the stored refresh token.
            // if there is no refresh token, logout the user.
            if (!refresh || refresh === 'undefined' || refresh === 'null' || refresh === '') {
              store.dispatch({ type: authTypes.REFRESH_TOKEN_FAIL })
              const clientVersion = localStorage.getItem('latestVersion')
              localStorage.clear()
              localStorage.setItem('latestVersion', clientVersion)
              store.doLogout()
              return Promise.reject(error)
            }
            const response = await axios.post('/api/token/refresh/', {
              refresh,
            })
            if (!response.status >= 400) {
              throw new Error('Error on request')
            }
            const { access: accessToken } = response.data
            localStorage.setItem('access', accessToken)
            localStorage.setItem('refresh', refresh)
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
            await store.doLoadUser()
            store.dispatch({ type: authTypes.REFRESH_TOKEN_SUCCESS, payload: response.data.access })
            return axios(originalRequest) // Retry the original request with the new access token.
          } catch (refreshError) {
            store.dispatch({ type: authTypes.REFRESH_TOKEN_FAIL })
            const clientVersion = localStorage.getItem('latestVersion')
            localStorage.clear()
            localStorage.setItem('latestVersion', clientVersion)
            store.doLogout()
            return Promise.reject(refreshError)
          }
        }
        return Promise.reject(error) // For all other errors, return the error as is.
      }
    )
  },
  doFetchAllServerInfo: () => async ({ store }) => {
    store.doFetchBaseURL()
    store.doFetchPDFKey()
    store.doFetchServerInfo()
    store.doFetchClientVersion()
  },
  reactShouldFetchServer: createSelector(
    'selectServer',
    'selectIsAuthenticated',
    'selectAppTime',
    (server, isAuthenticated, appTime) => {
      if (server.loading) return
      if (server.lastError) {
        if (appTime - server.lastError > 5000) return { actionCreator: 'doFetchAllServerInfo' }
      }
      if (server.lastFetch) {
        if (appTime - server.lastFetch > 60000) return { actionCreator: 'doFetchAllServerInfo' }
      }
    },
  ),
  init: store => {
    store.doCreateInterceptor()
    store.doFetchAllServerInfo()
    const data = globalThis.localStorage.getItem('cachedServerInfo')
    if (data === null) return
    const parsedData = JSON.parse(data)
    store.doSetServerInfo(parsedData)

    if (store.selectIsAuthenticated() && store.selectKind() === 0) {
      store.doFetchStatsForNerds()
    }
  },
  persistActions: [
    types.GET_BASE_URL,
    types.GET_PDF_KEY,
    types.GET_SERVER_INFO,
    types.GET_CLIENT_VERSION,
  ],
}
