import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const FinishConversationComponent = props => {
  const {
    doFinishConversation,
    kind,
  } = props
  const classes = useStyles()
  const buttonText = kind > 1 ? 'Terminar conversación' : 'Terminar conversación manualmente'
  return (
    <div className={classes.root}>
      <Typography></Typography>
      Gracias por contactarnos, esperamos haberle apoyado y si necesita algo más estamos atentos.
      {kind !== 2 && (
        <Button
          variant='outlined'
          color='secondary'
          sx={{ marginTop: 2 }}
          onClick={async () => {
            await doFinishConversation()
          }}
        >
          {kind !== 3 && <WarningAmberIcon sx={{ marginRight: 1 }} fontSize='small' />}
          {buttonText}
        </Button>
      )}
    </div>
  )
}

export default () => {
  const connectedProps = useConnect(
    'selectKind',
    'doFinishConversation',
  )
  return <FinishConversationComponent {...connectedProps} />
}
