export const toMarkAsRead = (messages, userId) => {
  if (!Array.isArray(messages)) return false
  if (messages.length === 0) return false
  const toMark = messages
    .filter(msg => msg.sender.id !== userId)
    .filter(msg => msg.read === false)
    .map(msg => msg.id)
  if (!toMark.length) return false
  return toMark
}
