const typeNames = [
  'OPEN_FILE_DIALOG',
  'CLOSE_FILE_DIALOG',
  'SET_FILE_DIALOG_ACTION_NAME',
  'SET_FILE_DIALOG_LOADING',
  'SET_FILE_DIALOG_IDLE',
  'RESET_FILE_DIALOG_STATE',
  'SET_FILE_DIALOG_TITLE',
  'SET_FILE_DIALOG_RELATED_ECG',
]

export const types = Object.fromEntries(typeNames.map(type => [type, type]))

const initialState = {
  open: false,
  loading: false,
  onClose: null,
  actionName: null,
  title: 'Cargar archivo',
  relatedEcg: null,
}
export default {
  name: 'fileDialog',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.RESET_FILE_DIALOG_STATE:
      return initialState
    case types.OPEN_FILE_DIALOG:
      return {
        ...state,
        open: true,
      }
    case types.CLOSE_FILE_DIALOG:
      return initialState
    case types.SET_FILE_DIALOG_ACTION_NAME:
      return {
        ...state,
        actionName: action.payload,
      }
    case types.SET_FILE_DIALOG_LOADING:
      return {
        ...state,
        loading: true,
      }
    case types.SET_FILE_DIALOG_IDLE:
      return {
        ...state,
        loading: false,
      }
    case types.SET_FILE_DIALOG_TITLE:
      return {
        ...state,
        title: action.payload,
      }
    case types.SET_FILE_DIALOG_RELATED_ECG:
      return {
        ...state,
        relatedEcg: action.payload,
      }
    default:
      return state
    }
  },
  selectFileDialogRaw: state => state.fileDialog,
  selectFileDialogOpen: state => state.fileDialog.open,
  selectFileDialogLoading: state => state.fileDialog.loading,
  selectFileDialogActionName: state => state.fileDialog.actionName,
  selectFileDialogOnClose: state => state.fileDialog.onClose,
  selectFileDialogTitle: state => state.fileDialog.title,
  selectFileDialogRelatedEcg: state => state.fileDialog.relatedEcg,
  doOpenDialog: () => ({ dispatch }) => dispatch({ type: types.OPEN_FILE_DIALOG }),
  doSetDialogTitle: title => ({ dispatch }) => dispatch({ type: types.SET_FILE_DIALOG_TITLE, payload: title }),
  doResetDialogState: () => ({ dispatch }) => dispatch({ type: types.RESET_FILE_DIALOG_STATE }),
  doCloseDialog: () => ({ dispatch }) => dispatch({ type: types.CLOSE_FILE_DIALOG }),
  doSetDialogAction: actionName => ({ dispatch }) => dispatch({ type: types.SET_FILE_DIALOG_ACTION_NAME, payload: actionName }),
  doSetDialogLoading: () => ({ dispatch }) => dispatch({ type: types.SET_FILE_DIALOG_LOADING }),
  doSetDialogIdle: () => ({ dispatch }) => dispatch({ type: types.SET_FILE_DIALOG_IDLE }),
  doSetRelatedEcg: ecg => ({ dispatch }) => dispatch({ type: types.SET_FILE_DIALOG_RELATED_ECG, payload: ecg })
}
