const validConfirmedTypes = ['3x4_1r', '6x2_1r']

const onFileUpload = async (files, ecg) => {
  window.store.doSetDialogLoading()
  const pdf = new FormData()
  pdf.append('ecg', files[0])
  await window.store.doUploadPDF(pdf, ecg.id)
  await window.store.doFetchEcgDialogData()
  await window.store.doFetchEcgChangeHistory()
  await window.store.doFetchECGS()
}

const onConfirmedFileUpload = async (files, ecg) => {
  window.store.doSetDialogLoading()
  const patientNameSlug = ecg?.patient?.name?.replace(' ', '-',)?.toUpperCase()
  const date = ecg.createdOn.split('T')[0]
  const totalFiles = files?.length
  let index = 1
  let newData = null
  for (const f of files) {
    const data = new FormData()
    let docType = false
    validConfirmedTypes.forEach(validType => {
      if (f.name.toLowerCase().includes(validType)) docType = validType
    })
    if (!docType) {
      window.store.doSetSnackbarFail(`Archivo debe contener uno de estos tipos de trazo confirmado: ${validConfirmedTypes}`)
      return
    }
    const fileName = `CONFIRMED__Trazo_confirmado_${docType}_${ecg.id}_${date}_${patientNameSlug}.pdf`
    data.append('file', f)
    data.append('name', fileName)
    newData = await window.store.doUploadEcgDocument(data, ecg.id, `${index}/${totalFiles}`)
    index++
  }
  return newData
}

export const uploadOcrTrace = (files, ecg) => onFileUpload(files, ecg)
export const uploadConfirmedTrace = (files, ecg) => onConfirmedFileUpload(files, ecg)

export default {
  onFileUpload: async (event, id) => {
    const pdf = new FormData()
    pdf.append('ecg', event.target.files[0])
    window.store.doSetAppLoading()
    await window.store.doUploadPDF(pdf, id)
    await window.store.doFetchEcgDialogData()
    window.store.doSetAppIdle()
  },
  onClickTrace: ecg => {
    window.store.doSetDialogAction('uploadOcrTrace')
    window.store.doSetDialogTitle(ecg.hasTrace ? 'Reemplazar trazo sin confirmar' : 'Cargar trazo sin confirmar')
    window.store.doSetRelatedEcg(ecg)
    window.store.doOpenDialog()
  },
  onClickSendReportEmail: async (action, ecg) => {
    await window.store.doSendEcgReportEmail(ecg.id)
  },
  onClickReportQr: async (action, ecg) => {
    await window.store.doGetPublicReportQr(ecg.id)
  },
  onClickConfirmedUpload: ecg => {
    window.store.doSetDialogAction('uploadConfirmedTrace')
    window.store.doSetDialogTitle('Cargar trazo(s) confirmado(s)')
    window.store.doSetRelatedEcg(ecg)
    window.store.doOpenDialog()
  },
  onReportClick: async (action, ecg) => {
    await window.store.doGenerateReport(ecg.id)
  },
  onClickViewTrace: (action, ecg) => {
    window.open(ecg.ecg, '_blank')
  },
  onClickViewConfirmedTrace: ecg => {
    window.store.doSetFileBrowserSetFiles(ecg)
    window.store.doSetFileBrowserOpen()
  },
  onClickDelete: async (action, ecg) => {
    await window.store.doSoftDeleteECG(ecg.id)
    let softDeleted = localStorage.getItem('softDeletedItems')?.split(',')?.map(Number)
    if (softDeleted === undefined || !softDeleted) softDeleted = []
    softDeleted.push(ecg.id)
    softDeleted = softDeleted.filter(e => !Number.isNaN(e))
    localStorage.setItem('softDeletedItems', softDeleted.join(','))
    await window.store.doFetchECGS()
  },
  onEditClick: async (action, ecg) => {
    window.store.doSetOpenedFromTable(true)
    window.store.doSetSelectedEcgId(ecg.id)
    await window.store.doFetchEcgDialogData(ecg)
    await window.store.doFetchEcgChangeHistory(ecg)
    window.store.doOpenEcgDialog()
    window.store.doUpdateUrl(`/estudio/${ecg.id}/`)
  }
}
