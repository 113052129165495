import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
// import { makeStyles } from '@mui/styles'

// Check Select component as reference for overriding Mui CSS Classes 

// const useStyles = makeStyles(theme => {
//   return {
//     root: {
//       backgroundColor: theme.palette?.backgroundColor.main || '#F5F5F5',
//       minWidth: '200px',
//       textAlignLast: 'center',
//       border: 0,
//       boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
//     },
//     paper: {
//       borderRadius: '20px',
//     },
//     selected: {
//       backgroundColor: theme.palette?.secondary?.main ?? '#486581',
//       color: 'white',
//     }}
// })

const Dropdown = props => {
  // const classes = useStyles()
  const {
    anchorEl,
    open,
    options,
    setAnchorEl,
    onClose,
    onChange,
    initialKey,
  } = props

  const [selectedIndex, setSelectedIndex] = React.useState(initialKey)

  const handleMenuItemClick = (event, value) => {
    setSelectedIndex(value)
    setAnchorEl(null)
    onChange(event, value)
  }

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 5,
        }
      }}
    >
      {options.map(option => (
        <MenuItem
          key={option.value}
          selected={option.value === selectedIndex}
          onClick={(event) => handleMenuItemClick(event, option.value)}
          sx={{
            justifyContent: 'start'
          }}
        >
          {option.displayValue}
        </MenuItem>
      ))}
    </Menu>
  )
}

export default Dropdown
