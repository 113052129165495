import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { useConnect } from 'redux-bundler-hook'
import MuiAlert from '@mui/material/Alert'

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={10} ref={ref} {...props} />)

const ECGSnackbar = (props) => {
  const { snackbar, doCloseSnackbar } = props
  const { vertical, horizontal } = snackbar

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={snackbar ? snackbar.openSeconds : 5000}
      onClose={doCloseSnackbar}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert onClose={doCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%', fontFamily: 'roboto' }}>
        {snackbar ? snackbar.message : ''}
      </Alert>
    </Snackbar>
  )
}

export default () => {
  const connectedProps = useConnect(
    'selectSnackbar',
    'doCloseSnackbar',
  )
  return <ECGSnackbar {...connectedProps} />
}
