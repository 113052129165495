import { ReactComponent as Confirmed } from '../assets/static/icons/confirmed.svg'
import { ReactComponent as Delete } from '../assets/static/icons/delete.svg'
import { ReactComponent as Report } from '../assets/static/icons/report.svg'
import { ReactComponent as StatusGray } from '../assets/static/icons/status_gray.svg'
import { ReactComponent as StatusGreen } from '../assets/static/icons/status_green.svg'
import { ReactComponent as StatusRejected } from '../assets/static/icons/status_rejected.svg'
import { ReactComponent as StatusRed } from '../assets/static/icons/status_red.svg'
import { ReactComponent as Trace } from '../assets/static/icons/trace.svg'
import { ReactComponent as Diagnose } from '../assets/static/icons/diagnose.svg'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'

import QrCode2Icon from '@mui/icons-material/QrCode2'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import AssignmentIcon from '@mui/icons-material/Assignment'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import CircleIcon from '@mui/icons-material/Circle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'

export const iconsByKey = {
  trace: Trace,
  report: Report,
  confirmed: Confirmed,
  status: {
    green: StatusGreen,
    gray: StatusGray,
    rejected: StatusRejected,
    red: StatusRed,
  },
  delete: Delete,
  edit: Diagnose,
  reportEmail: AttachEmailIcon,
  reportQr: QrCode2Icon,
}

export const mobileIconsByKey = {
  trace: CloudUploadIcon,
  report: AssignmentIcon,
  confirmed: MonitorHeartIcon,
  status: CircleIcon,
  delete: DeleteForeverIcon,
  edit: LocalHospitalIcon,
  reportEmail: AttachEmailIcon,
}

export const getIconStylesByKey = props => ({
  reportEmail: {
    fill: props.fillColor,
    stroke: props.fillColor,
    color: 'primary',
    '&:hover, &.Mui-focusVisible': {
      fill: props.fillColor,
      stroke: props.fillColor,
    },
  },
  reportQr: {
    fill: props.fillColor,
    stroke: props.fillColor,
    color: 'primary',
    '&:hover, &.Mui-focusVisible': {
      fill: props.fillColor,
      stroke: props.fillColor,
    },
  },
})

export const defaultMuiIconStyles = props => ({
  fill: props.fillColor,
  stroke: props.fillColor,
  '&:hover, &.Mui-focusVisible': {
    fill: props.fillColor,
    stroke: props.fillColor,
  },
})

export const getFillByKey = ecg => {
  const isMatriz = ecg?.centerInfo?.name?.toLowerCase()?.includes('asesores matriz')
  const hasConfirmed3Times4 = Boolean(
    ecg.documentsNames.some(docName => docName.startsWith('CONFIRMED__') && docName.toLowerCase().includes('3x4_1r'))
  )
  return {
    trace: true,
    report: ecg.isReported,
    confirmed: isMatriz ? hasConfirmed3Times4 : ecg.hasConfirmedTrace,
    edit: true,
    reportEmail: ecg.isReported && ecg.hasConfirmedTrace,
    reportQr: ecg.isReported,
  }
}

export const getDisabledByKey = (ecg) => {
  return {
    trace: false,
    report: !ecg.isReported,
    confirmed: false,
    status: false,
    delete: false,
    reportEmail: !ecg.isReported || !ecg.hasConfirmedTrace,
  }
}
