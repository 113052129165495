import { Button, Typography } from '@mui/material'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'

const FONT_SIZE = '0.9rem'
const MIN_WIDTH = 110
const MIN_HEIGHT = 40

const DefaultActions = props => {
  const {
    formik,
    currentEcg,
    classes,
    onSubmit,
    doCloseEcgDialog,
    doFetchHistoricData,
    doFetchEcgChangeHistory,
    doUpdateUrl,
  } = props
  return (
    <div className={classes.actionsRow}>
      <Button
        onClick={async () => {
          const valid = await onSubmit(formik.values)
          if (!valid) return
          await doFetchHistoricData(currentEcg)
          await doFetchEcgChangeHistory(currentEcg)
        }}
        variant='contained'
        sx={{
          marginTop: 2,
          marginBottom: 5,
          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#486581',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Guardar
        </Typography>
      </Button>
      <Button
        onClick={() => {
          doCloseEcgDialog()
          doUpdateUrl('/')
        }}
        variant='contained'
        sx={{
          marginTop: 2,
          marginBottom: 5,
          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#9CA7B2',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Cancelar
        </Typography>
      </Button>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectCurrentEcg',
    'doRejectECG',
    'doSetECGRedCode',
    'doAcceptECG',
    'doEditECG',
    'doSetSelectedEcg',
    'doFetchECGS',
    'doCloseEcgDialog',
    'doFetchHistoricData',
    'doFetchEcgChangeHistory',
  )
  return <DefaultActions {...props} {...connectedProps} />
}
