import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useConnect } from 'redux-bundler-hook'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { makeStyles } from '@mui/styles'
import T from '../../../components/common/Typography'
import TextField from '../../../components/common/TextField'
import Select from '../../../components/common/Select'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { noop } from '../../../utils/misc'
import { chewDataProvisionCenterAPI } from '../../../utils/centers'
import Spinner from '../../../components/common/Spinner'
import { TIMEZONE_CHOICES } from '../../../utils/dates'


const steps = [
  'Selecciona o agrega una Organización',
  'Registrar datos del Centro',
  'Enviar invitación',
]

const OrganizationComponent = props => {
  const { state, setState, styleClass, organizations } = props
  const { newOrg } = state
  return (
    <div className={styleClass}>
      <T.H4>Organización</T.H4>
      <FormControlLabel
        label={newOrg ? 'Nueva' : 'Existente'}
        control={
          <Checkbox checked={newOrg} onChange={e => setState({...state, newOrg: e.target.checked })} />
        }
        labelPlacement='start'
      />
      {newOrg ? (
        <div style={{ minWidth: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 10 }}>
          <TextField
            dense
            value={state.name}
            name='name'
            onValueChange={e => setState({...state, name: e.target.value})}
            placeholder='Nombre'
            width='300px'
          />
          <TextField
            dense
            value={state.key}
            name='key'
            onValueChange={e => setState({...state, key: e.target.value})}
            placeholder='Key'
            width='300px'
          />
          <small style={{ color: 'grey' }}>Hint: Nombre reconocible en minusculas. Una palabra</small>
        </div>
      ) : (
        <Select
          placeholder='Organización existente'
          displayEmpty
          size='small'
          dense
          name='organizationId'
          disabled={newOrg}
          options={organizations.map(organization => (
            { key: organization.id, value: organization.id, displayValue: `[${organization.key}] - ${organization.name}` }
          ))}
          value={state.organizationId}
          onValueChange={e => setState({ ...state, organizationId: e.target.value })}
          width='400px'
        />
      )}
    </div>
  )
}

const CenterDataComponent = props => {
  const { state, setState, styleClass, reportTemplateModelItems } = props
  const { newTemplate } = state
  return (
    <div className={styleClass}>
      <T.H4>Centro</T.H4>
      {/* <p>{JSON.stringify(state)}</p> */}
      <div style={{ minWidth: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 10 }}>
        <TextField
          dense
          value={state.name}
          name='name'
          onValueChange={e => setState({...state, name: e.target.value})}
          placeholder='Nombre'
          width='300px'
        />
        <TextField
          dense
          value={state.city}
          name='city'
          onValueChange={e => setState({...state, city: e.target.value})}
          placeholder='Ciudad'
          width='300px'
        />
        <TextField
          dense
          value={state.address}
          name='address'
          onValueChange={e => setState({...state, address: e.target.value})}
          placeholder='Dirección'
          width='300px'
        />
        <Divider />
        <Typography fontWeight='bold'>Usuario</Typography>
        <TextField
          dense
          value={state.username}
          name='username'
          onValueChange={e => setState({...state, username: e.target.value})}
          placeholder='Nombre de usuario'
          width='300px'
        />
        <small style={{ color: 'grey' }}>Mínimo 8 caracteres</small>
        <TextField
          dense
          value={state.email}
          name='email'
          onValueChange={e => setState({...state, email: e.target.value})}
          placeholder='Email'
          width='300px'
        />
        <small style={{ color: 'grey', fontStyle: 'italic' }}>*Necesario para enviar invitación</small>
        <TextField
          dense
          value={state.phoneNumber}
          name='phone'
          onValueChange={e => setState({...state, phoneNumber: e.target.value})}
          placeholder='Teléfono (opcional)'
          width='300px'
        />
        <Select
          placeholder='Zona Horaria'
          displayEmpty
          size='small'
          dense
          name='timezone'
          options={TIMEZONE_CHOICES.map(tz => (
            { key: tz, value: tz, displayValue: tz }
          ))}
          value={state.timezone}
          onValueChange={e => setState({ ...state, timezone: e.target.value })}
          width='400px'
          styleOverrides={{ marginTop: 2 }}
        />
        <Divider />
        <Typography fontWeight='bold'>Plantilla de reporte</Typography>
        <FormControlLabel
          label={newTemplate ? 'Nueva' : 'Existente'}
          sx={{ flexDirection: 'row', margin: 0 }}
          control={
            <Checkbox checked={newTemplate} onChange={e => setState({...state, newTemplate: e.target.checked })} />
          }
          labelPlacement='start'
        />
        {newTemplate ? (
          <>
            <TextField
              dense
              value={state.templateKey}
              name='templateKey'
              onValueChange={e => setState({...state, templateKey: e.target.value})}
              placeholder='Key de plantilla'
              width='300px'
            />
            <small style={{ color: 'grey' }}>Hint: Nombre reconocible en minusculas. Una palabra</small>
            <TextField
              dense
              value={state.templateNumber}
              name='templateNumber'
              onValueChange={e => setState({...state, templateNumber: e.target.value})}
              placeholder='ID de plantilla en PDF Generator API'
              width='300px'
            />
          </>
        ) : (
          <Select
            placeholder='Plantilla existente'
            displayEmpty
            size='small'
            dense
            name='templateId'
            disabled={newTemplate}
            options={reportTemplateModelItems.map(template => (
              { key: template.id, value: template.id, displayValue: `[${template.key}] - ${template.templateNumber}` }
            ))}
            value={state.templateId}
            onValueChange={e => setState({ ...state, templateId: e.target.value })}
            width='400px'
          />
        )}
      </div>
    </div>
  )
}

const InvitationComponent = props => {
  const {
    state,
    organizations,
    reportTemplateModelItems,
    generateReview,
    styleClass,
    setHasFinishedWithoutInvitation,
    doProvisionCenterCreate,
    doSendNewCenterInvitation,
  } = props
  const reviewData = generateReview(state, organizations, reportTemplateModelItems)
  const [stage, setStage] = useState(0)
  const [loading, setLoading] = useState(0)
  return (
    <div className={styleClass}>
      <T.H4>Resumen</T.H4>
      <TableContainer>
        <Table>
          <TableBody>
            {reviewData.map((reviewElement, index) => (
              <TableRow key={index}>
                <TableCell>
                  {reviewElement.displayValue}
                </TableCell>
                <TableCell sx={{ fontStyle: reviewElement?.isNew ? 'italic' : 'normal' }}>
                  {reviewElement.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <small style={{ color: 'grey', fontStyle: 'italic' }}>*Valores en cursiva serán creados</small>
      {loading ? <Spinner /> : null}
      {stage === 0 && (
        <Button
          variant='outlined'
          sx={{ marginTop: 10 }}
          disabled={loading}
          onClick={async () => {
            setLoading(true)
            const apiReadyData = chewDataProvisionCenterAPI(state)
            setLoading(false)
            console.log(apiReadyData)
            // res should include a user object with the saved email in the DB
            const res = await doProvisionCenterCreate(apiReadyData)
            if (res) {
              localStorage.setItem('provisionCenterLastEmail', res.user.email)
              setStage(1)
              setHasFinishedWithoutInvitation(true)
            }
          }}
        >
          Crear centro
        </Button>
      )}
      {stage === 1 && (
        <div style={{ display: 'flex', gap: 10 }}>
          <Button
            variant='outlined'
            disabled={loading}
            sx={{ marginTop: 10 }}
            onClick={async () => {
              const lastEmail = localStorage.getItem('provisionCenterLastEmail')
              setLoading(true)
              const res = await doSendNewCenterInvitation(lastEmail)
              setLoading(false)
              if (res) {
                setStage(2)
                setHasFinishedWithoutInvitation(false)
              }
            }}
          >
            Enviar invitación
          </Button>
          <Button
            variant='outlined'
            sx={{ marginTop: 10 }}
            disabled={loading}
            onClick={() => {
              setHasFinishedWithoutInvitation(false)
              setStage(2)
            }}
          >
           Continuar sin invitación
          </Button>
        </div>
      )}
      {stage === 2 && (
        <T.H4>Proceso terminado exitosamente.</T.H4>
      )}
    </div>
  )
}

const componentPerStep = [
  OrganizationComponent,
  CenterDataComponent,
  InvitationComponent,
]

const useStyles = makeStyles(() => ({
  content: {},
  navButtonsDiv: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  firstPage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    minHeight: '600px',
    maxHeight: '600px',
  },
  secondPage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    minHeight: '600px',
    maxHeight: '600px',
    gap: 10,
  },
  thirdPage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    minHeight: '600px',
    maxHeight: '600px',
    gap: 10,
  },
}))

const ProvisionCenterDialog = props => {
  const {
    open,
    setOpen,
    doSetSnackbarInfo,
  } = props
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const [hasFinishedWithoutInvitation, setHasFinishedWithoutInvitation] = useState(false)
  const styleClassPerPage = [
    classes.firstPage,
    classes.secondPage,
    classes.thirdPage,
  ]
  const currentClass = styleClassPerPage[step]
  const [firstPage, setFirstPage] = useState({
    newOrg: true,
    organizationId: null,
    name: '',
    key: '',
  })
  const [secondPage, setSecondPage] = useState({
    name: '',
    city: '',
    address: '',
    username: '',
    email: '',
    phoneNumber: '',
    newTemplate: true,
    templateId: null,
    templateKey: '',
    templateNumber: '',
    timezone: 'America/Mexico_City',
  })
  const generateReview = (states, organizations, templates) => {
    const [firstPage, secondPage] = states
    const selectedOrg = organizations.find(org => org.id === firstPage.organizationId) ?? {}
    const selectedTemplate = templates.find(template => template.id === secondPage.templateId) ?? {}
    return [
      {
        value: firstPage.newOrg ? `[${firstPage.key}] ${firstPage.name}` : `[${selectedOrg?.key}] ${selectedOrg?.name}`,
        displayValue: 'Organización',
        isNew: firstPage.newOrg,
      },
      { value: secondPage.name, displayValue: 'Nombre del centro' },
      { value: secondPage.city, displayValue: 'Ciudad' },
      { value: secondPage.address, displayValue: 'Dirección' },
      { value: secondPage.username, displayValue: 'Usuario' },
      { value: secondPage.email, displayValue: 'Email' },
      { value: secondPage.phoneNumber, displayValue: 'Teléfono' },
      {
        value: secondPage.newTemplate
          ? `[${secondPage.templateKey}] ${secondPage.templateNumber}`
          : `[${selectedTemplate?.key}] ${selectedTemplate?.templateNumber}`,
        displayValue: 'Plantilla de Reporte',
        isNew: secondPage.newTemplate,
      },
      { value: secondPage.timezone, displayValue: 'Zona Horaria' },
    ]
  }
  const statePerPage = [
    { state: firstPage, setState: setFirstPage },
    { state: secondPage, setState: setSecondPage },
    { state: [firstPage, secondPage], setState: noop },
  ]
  const selectedPageState = statePerPage[step]
  const StepComponent = componentPerStep[step]
  return (
    <Dialog
      open={open}
      maxWidth='md'
      fullWidth
      // fullScreen
      onClose={() => {
        if (hasFinishedWithoutInvitation) {
          doSetSnackbarInfo('No ha enviado la invitación. ¿Está seguro de que desea salir?')
        } else {
          setOpen(false)
        }
      }}
    >
      <DialogTitle style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <div style={{ display: 'flex' }}>
          <IconButton onClick={() => setOpen(false)}>
            <ArrowBackIcon />
          </IconButton>
          Inscribir Centro
        </div>
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Stepper activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className={classes.navButtonsDiv}>
            <Button
              variant='outlined'
              onClick={() => setStep(step == 0 ? step : step - 1)}
              disabled={step == 0}
            >
              Anterior
            </Button>
            <Button
              variant='outlined'
              onClick={() => setStep(step == 2 ? step : step + 1)}
              disabled={step == 2}
            >
              Siguiente
            </Button>
          </div>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <StepComponent
            {...props}
            {...selectedPageState}
            styleClass={currentClass}
            generateReview={generateReview}
            hasFinishedWithoutInvitation={hasFinishedWithoutInvitation}
            setHasFinishedWithoutInvitation={setHasFinishedWithoutInvitation}
          />
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectReportTemplateModelItems',
    'selectUsers',
    'selectOrganizations',
    'selectReportTemplateModelItems',
    'doSetSnackbarInfo',
    'doSendNewCenterInvitation',
    'doProvisionCenterCreate',
  )
  return <ProvisionCenterDialog {...props} {...connectedProps} />
}
