export const types = {
  FILE_BROWSER_DIALOG_OPEN: 'FILE_BROWSER_DIALOG_OPEN',
  FILE_BROWSER_DIALOG_CLOSE: 'FILE_BROWSER_DIALOG_CLOSE',
  FILE_BROWSER_DIALOG_SET_FILES: 'FILE_BROWSER_DIALOG_SET_FILES',
  FILE_BROWSER_DIALOG_CLEAR_FILES: 'FILE_BROWSER_DIALOG_CLEAR_FILES',
}

const initialState = {
  open: false,
  files: [],
  ecg: null,
}

export default {
  name: 'fileBrowserDialog',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.FILE_BROWSER_DIALOG_OPEN:
      return {
        ...state,
        open: true,
      }
    case types.FILE_BROWSER_DIALOG_CLOSE:
      return {
        ...state,
        ecg: null,
        open: false,
      }
    case types.FILE_BROWSER_DIALOG_SET_FILES:
      return {
        ...state,
        files: action.payload.confirmedTraces,
        ecg: action.payload,
      }
    case types.FILE_BROWSER_DIALOG_CLEAR_FILES:
      return {
        ...state,
        files: [],
        ecg: null,
      }

    default:
      return state
    }
  },
  selectFileBrowserOpen: state => state.fileBrowserDialog.open,
  selectFileBrowserFiles: state => state.fileBrowserDialog.files,
  selectFileBrowserEcg: state => state.fileBrowserDialog.ecg,
  doSetFileBrowserOpen: () => ({ dispatch }) => dispatch({ type: types.FILE_BROWSER_DIALOG_OPEN }),
  doSetFileBrowserClose: () => ({ dispatch }) => dispatch({ type: types.FILE_BROWSER_DIALOG_CLOSE }),
  doSetFileBrowserSetFiles: ecg => ({ dispatch }) => dispatch({ type: types.FILE_BROWSER_DIALOG_SET_FILES, payload: ecg }),
  doSetFileBrowserClearFiles: () => ({ dispatch }) => dispatch({ type: types.FILE_BROWSER_DIALOG_CLEAR_FILES }),
}
