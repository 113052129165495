import React from 'react'
import HistoryElement from './HistoryElement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
    width: '100%',
    maxWidth: '100%',
  },
  historyItemsDiv: {
    padding: 10,
  },
  noDataDiv: {
    display: 'flex',
    gap: 15,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}))

const History = props => {
  const {
    historicEcgs,
    currentId,
  } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {historicEcgs.length > 1 ? (
        <div className={classes.historyItemsDiv}>
          {historicEcgs?.map(ecg => (
            <HistoryElement key={ecg.id} ecg={ecg} currentId={currentId} />
          ))}
        </div>
      ) : (
        <div className={classes.noDataDiv}>
          <FontAwesomeIcon
            icon='fa-sharp fa-solid fa-clipboard'
            size='2x'
            opacity={0.1}
          />
          <Typography sx={{ opacity: 0.2, fontSize: 18 }}>
            No hay más elementos <br /> en el expediente.
          </Typography>
        </div>
      )}
    </div>
  )
}

export default History
