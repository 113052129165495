import React from 'react'
import { makeStyles } from '@mui/styles'
import UserAvatar from './UserAvatar'
import { IconButton, Tooltip, Typography } from '@mui/material'
import MarkunreadIcon from '@mui/icons-material/Markunread'
import { useConnect } from 'redux-bundler-hook'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    borderRadius: 15,
    backgroundColor: 'transparent',
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: '#EEEEEE',
    },
    cursor: 'pointer',
    minHeight: '5rem',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingLeft: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  selected: {
    display: 'flex',
    borderRadius: 15,
    backgroundColor: '#EEEEEE',
    cursor: 'pointer',
    minHeight: '5rem',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
    paddingLeft: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  unreadsBadge: {
    display: 'flex',
    borderRadius: '50%',
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontFamily: 'roboto',
    backgroundColor: '#3b30ff',
    color: 'white',
    fontWeight: 'bolder',
  }
}))

const ConversationItem = props => {
  const {
    conversation,
    userId,
    currentConversation,
    doSetSelectedConversation,
    doMarkTheirsAsRead,
  } = props
  const classes = useStyles()
  const multiple = conversation.users.length > 2
  const otherUser = !multiple ? conversation.users.find(user => user.id != userId) : {}
  const isSelected = currentConversation ? currentConversation.id === conversation.id : false
  return multiple ? (
    <div className={isSelected ? classes.selected : classes.root} onClick={() => doSetSelectedConversation(conversation)}>
      {conversation.users.map(user => (
        <UserAvatar key={user.id} user={user} />
      ))}
      <Typography fontFamily='roboto' sx={{ minWidth: 100 }}>
        {conversation.name}
      </Typography>
    </div>
  ) : (
    <div className={isSelected ? classes.selected : classes.root} onClick={() => doSetSelectedConversation(conversation)}>
      <UserAvatar user={otherUser} />
      <Typography fontFamily='roboto' sx={{ minWidth: 100 }}>
        {otherUser.fullName}
      </Typography>
      {conversation.unreads > 0 && <div className={classes.unreadsBadge}>{conversation.unreads}</div>}
      {conversation.unreads > 0 && (
        <Tooltip arrow placement='top' title='Marcar como leídos'>
          <IconButton onClick={async () => await doMarkTheirsAsRead(conversation)}>
            <MarkunreadIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  )
}

export default props => {
  const connectedProps = useConnect('doMarkTheirsAsRead')
  return <ConversationItem {...props} {...connectedProps} />
}
