import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Typography } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import NotificationBanner from '../components/common/NotificationBanner'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '70vh',
    borderRadius: 10,
  },
  notificationList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  noDataDiv: {
    display: 'flex',
    gap: 20,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    minHeight: '100%',
  },
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    padding: 10,
    top: 0,
    zIndex: 100,
    gap: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#EEEEEE',
  },
}))

const NotificationsDrawer = props => {
  const classes = useStyles()
  const {
    notifications,
    open,
    onOpen,
    onClose,
  } = props

  return (
    <Drawer
      anchor='right'
      BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.1 } }}
      PaperProps={{
        style:
        {
          padding: '0 10 10 10',
          minWidth: '100%',
          backgroundColor: '#EEEEEE',
        },
      }}
      elevation={5}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div className={classes.notificationList}>
        <div className={classes.rowSpaceBetween}>
          <Typography
            fontSize='2rem'
            fontFamily='roboto'
            fontWeight={500}
          >
            Notificaciones
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {notifications?.length ? notifications.map(n => (
          <NotificationBanner
            key={n.id}
            notification={n}
            {...props}
          />
        )) : (
          <div className={classes.noDataDiv}>
            <FontAwesomeIcon
              icon='fa-sharp fa-solid fa-clipboard-check'
              size='3x'
              opacity={0.1}
            />
            <Typography sx={{ opacity: 0.2, fontSize: 20 }}>
              No hay notificaciones.
            </Typography>
          </div>
        )}
      </div>
    </Drawer>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectNotifications',
    'doSnoozeNotification',
    'doUpdateUrl',
    'doOpenEcgDialogOnUrlUpdate',
  )
  return <NotificationsDrawer {...props} {...connectedProps} />
}
