export const styles = theme => ({
  layoutDiv: {
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '130px 1fr 30px',
    minHeight: '100vh',
    maxHeight: '100vh',
    height: '100vh',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    minWidth: '100vw',
    maxWidth: '100vw',
    paddingLeft: 10,
    paddingRight: 10,
    top: 0,
    zIndex: 100
  },
  header1: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    backgroundColor: theme.palette.backgroundColor.main,
    borderRadius: '30px 30px 0 0',
    paddingBottom: '50px',
    paddingTop: 5,
    width: '100vw',
    minWidth: '100vw',
    maxWidth: '100vw',
  },
  menu: {
    backgroundColor: 'white',
    marginTop: '-25px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 40px',
    borderRadius: '30px 30px 0 0',
    // border: 'solid red 1px',
    boxShadow: '0px 0px 1px 0px rgb(0 0 0 / 70%)',
    position: 'sticky',
    zIndex: 100,
    bottom: 0
  },
  logoutButton: {
    marginTop: 'auto',
    marginBottom: 20,
  },
  paragraph: {
    color: 'primary'
  },
  logo: {
    height: 30,
    width: 30
  }
})
