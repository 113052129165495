import { Typography } from '@mui/material'
import React from 'react'
import { useConnect } from 'redux-bundler-hook'
import Spinner from '../components/common/Spinner'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    height: '100vh',
    gap: 100,
    padding: '5%',
    fontFamily: 'Roboto',
  },
}))

const PublicReport = props => {
  const {
    reportError,
    reportLoading,
    report,
  } = props
  const classes = useStyles()
  if (reportLoading) {
    return (
      <div className={classes.root}>
        <Typography variant='h3' fontFamily='Roboto'>
          Cargando...
        </Typography>
        <Spinner />
      </div>
    )
  }
  if (reportError) {
    return (
      <div className={classes.root}>
        <Typography variant='h3' fontFamily='Roboto'>
          Error cargando el reporte
        </Typography>
        <Typography variant='p' fontFamily='Roboto'>
          {reportError}
        </Typography>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Typography variant='h3' fontFamily='Roboto'>
        Reporte
      </Typography>
      <object data={report} type="application/pdf" width="100%" height="95%">
        <p>Link <a href={report}>al PDF.</a></p>
      </object>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectReportLoading',
    'selectReportError',
    'selectReport',
  )
  return <PublicReport {...props} {...connectedProps} />
}
