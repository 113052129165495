import { Divider, Typography } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    height: '80vh',
    gap: 50,
    padding: '5%',
  },
}))

const NotFound = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography fontFamily='monospace' variant='h3'>
        404 - Not Found
      </Typography>
      <Typography fontFamily='monospace' variant='p'>
        El recurso al que intentas acceder no existe.
      </Typography>
      <Divider />
      <Typography fontSize={20}>
        Centro Integral de Diagnóstico Cardiovascular
      </Typography>
    </div>
  )
}

export default NotFound
