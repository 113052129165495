import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import SignalWifiBadSharpIcon from '@mui/icons-material/SignalWifiBadSharp'

const Offline = props => {
  const {
    color = '#5c5c5c',
    fontSize,
    iconSize,
  } = props
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <SignalWifiBadSharpIcon sx={{ fontSize: iconSize, color: color }} />
      <Typography
        fontFamily='roboto'
        textAlign='center'
        color={color}
        sx={{ opacity: 0.7 }}
        fontSize={fontSize}
      >
        Se perdió la conexión a Internet. Por favor intente más tarde.
      </Typography>
    </div>
  )
}

Offline.propTypes = {
  color: PropTypes.string,
  fontSize: PropTypes.number,
  iconSize: PropTypes.number
}

Offline.defaultProps = {
  color: '#5c5c5c',
  fontSize: 16,
  iconSize: 100
}

export default Offline