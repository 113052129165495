import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import { useConnect } from 'redux-bundler-hook'

import ConfirmationDialog from '../../../common/ConfirmationDialog'


const FONT_SIZE = '0.9rem'
const MIN_WIDTH = 110
const MIN_HEIGHT = 40

const DefaultActions = props => {
  const {
    id,
    formik,
    currentEcg,
    classes,
    onSubmit,
    confirmationDialogPref,
    doCloseEcgDialog,
    doFetchHistoricData,
    doFetchEcgChangeHistory,
    doSetSelectedEcg,
    doSetECGRedCode,
    doFetchECGS,
    doUpdateUrl,
  } = props

  const confirmationDialog = confirmationDialogPref?.value
  const redCodeAction = async () => {
    const valid = await onSubmit(formik.values)
    if (!valid) return
    const res = await doSetECGRedCode(id)
    if (res) {
      await doSetSelectedEcg(res)
      await doFetchHistoricData(res)
      await doFetchEcgChangeHistory(res)
      await doCloseEcgDialog()
      await doFetchECGS()
    }
  }
  const [openRedCode, setOpenRedCode] = useState(false)

  return (
    <div className={classes.actionsColumn}>
      <ConfirmationDialog
        open={openRedCode}
        setOpen={setOpenRedCode}
        truthyAction={redCodeAction}
        onClose={() => setOpenRedCode(false)}
        truthyActionText='Código rojo'
        truthyActionStyle={{ backgroundColor: '#F94654', color: '#ffffff' }}
        title='🔴 Código rojo'
        description='Confirme para marcar el estudio como código rojo.'
      />
      <Button
        onClick={async () => {
          const valid = await onSubmit(formik.values)
          if (!valid) return
          await doFetchHistoricData(currentEcg)
          await doFetchEcgChangeHistory(currentEcg)
        }}
        variant='contained'
        sx={{
          marginTop: 2,
          marginBottom: 5,
          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#486581',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Guardar
        </Typography>
      </Button>
      <Button
        name='redCode'
        variant='contained'
        onClick={confirmationDialog ? () => setOpenRedCode(true) : redCodeAction}
        sx={{
          marginTop: 2,
          marginBottom: 5,
          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#FB4D60',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Código Rojo
        </Typography>
      </Button>
      <Button
        onClick={() => {
          doCloseEcgDialog()
          doUpdateUrl('/')
        }}
        variant='contained'
        sx={{
          marginTop: 2,
          marginBottom: 5,
          minWidth: MIN_WIDTH,
          minHeight: MIN_HEIGHT,
          backgroundColor: '#9CA7B2',
        }}
      >
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: FONT_SIZE,
            color: 'white',
          }}
        >
          Cancelar
        </Typography>
      </Button>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectCurrentEcg',
    'doRejectECG',
    'doSetECGRedCode',
    'doAcceptECG',
    'doEditECG',
    'doSetSelectedEcg',
    'doFetchECGS',
    'doCloseEcgDialog',
    'doFetchHistoricData',
    'doFetchEcgChangeHistory',
  )
  return <DefaultActions {...props} {...connectedProps} />
}
