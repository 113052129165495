import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import { noop } from '../../../utils/misc'
import SearchBar from '../../common/SearchBar'
import FormControl from '@mui/material/FormControl'
import Transition from '@mui/material/Fade'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const pageSizes = [5, 10, 15, 20 ,25, 30]

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  tableContainer: {
    minHeight: 30,
    maxHeight: '70vh',
  },
  headerRow: {
    backgroundColor: theme.palette.background.main,
    color: theme.palette.grey.main,
  },
  headerCellTypography: {
    color: theme.palette.grey.main,
    fontWeight: 'light',
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: theme.palette.background.main,
    },
  },
  spinnerDiv: {
    display: 'flex',
    marginTop: '-20%',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableWidgetsDiv: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    width: '100%',
  },
  tablePaginationDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    padding: 10,
    gap: 40,
    width: '50%',
  },
}))

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index
}

const addSelected = (id, selectedItems) => {
  const newArr = [...selectedItems, id]
  const unique = newArr.filter(onlyUnique)
  return unique
}
const removeSelected = (id, selectedItems) => selectedItems.filter(itemId => itemId !== id)

const getOnItemCheckChangeNewSelected = (event, id, selectedItems) => {
  const mutateFunc = event.target.checked ? addSelected : removeSelected
  return mutateFunc(id, selectedItems)
}

const TableComponent = props => {
  const {
    headers = [],
    processedData = [],
    loading,
    size,
    allSelected,
    selectedItems,
    setSelectedItems,
    setAllSelected,
    clearSelected,
    searchValue,
    setSearchValue,
    currentPage,
    total,
    pageSize,
    totalPages,
    setCurrentPage,
    setPageSize,
    openDialog,
    allowDelete,
    deleteItem,
  } = props
  const classes = useStyles()
  const someSelected = selectedItems.length > 0 && !allSelected
  const rows = processedData.map((item, index) => (
    <Transition key={index} in timeout={(index + 1) * 300}>
      <TableRow key={item.id} className={classes.tableRow} hover selected={selectedItems.includes(item.id)}>
        <TableCell key={`checkbox-table-${item.id}`} padding='checkbox'>
          <Checkbox
            checked={!!selectedItems.includes(item.id)}
            onChange={event => {
              const newSelected = getOnItemCheckChangeNewSelected(event, item.id, selectedItems)
              setSelectedItems(newSelected)
            }}
          />
        </TableCell>
        {item.cells.map(cellData => {
          const Component = cellData.component
          return (
            <TableCell key={`cell-field-${cellData.key}-item${item.id}`} {...cellData.cellProps}>
              <Component {...cellData.props} >{cellData.value}</Component>
            </TableCell>
          )
        })}
        {/* TODO: ADD AN EXTRA CELL FOR ACTIONS <select> (three dots) */}
        {allowDelete && (
          <TableCell key={`cell-delete-${item.id}`}>
            <Tooltip arrow placement='top' title='Eliminar'>
              <IconButton onClick={() => deleteItem(item.id)}>
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        <TableCell key={`cell-edit-${item.id}`}>
          <Tooltip arrow placement='top' title='Ver'>
            <IconButton onClick={() => openDialog(item.id)}>
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </Transition>
  ))
  return (
    <div className={classes.root}>
      <div className={classes.tableWidgetsDiv}>
        <SearchBar
          placeholder='Buscar'
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          width='70%'
          loading={loading}
          debounceTime={600}
        />
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table size={size}>
          <TableHead>
            <TableRow key='header-row-table' className={classes.headerRow}>
              <TableCell key='checkbox-cell' padding='checkbox'>
                <Checkbox
                  checked={someSelected || allSelected}
                  checkedIcon={someSelected ? <IndeterminateCheckBoxIcon /> : <CheckBoxIcon />}
                  onChange={event => {
                    const action = event.target.checked ? setAllSelected : clearSelected
                    action()
                  }}
                />
              </TableCell>
              {headers.map(h => (
                <TableCell key={h.key} variant='head'>
                  <Typography fontSize={14} className={classes.headerCellTypography}>
                    {h.displayValue}
                  </Typography>
                </TableCell>
              ))}
              {/* TODO: ADD AN EXTRA CELL FOR ACTIONS <select> (three dots) */}
              {allowDelete && (
                <TableCell key='delete-cell' variant='head'>Eliminar</TableCell>
              )}
              <TableCell key='edit-cell' variant='head' />
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody>
              {rows}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {loading && (
        <div className={classes.spinnerDiv}>
          <CircularProgress color='primary' />
        </div>
      )}
      <div className={classes.tablePaginationDiv}>
        <Typography color='text.disabled' fontSize={14} fontWeight='light'>
          {total} elementos
        </Typography>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            size='small'
            labelId='table-select-page-size'
            value={pageSize}
            onChange={event => setPageSize(event.target.value)}
          >
            {pageSizes.map(pageSize => (
              <MenuItem dense key={pageSize} value={pageSize}>{pageSize}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Pagination
          showFirstButton
          showLastButton
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
        />
      </div>
    </div>
  )
}

TableComponent.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    displayValue: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    sizePercentage: PropTypes.number.isRequired,
  })),
  size: PropTypes.oneOf([
    'small',
    'medium',
  ]),
  allSelected: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.number),
  setSelectedItems: PropTypes.func,
  setAllSelected: PropTypes.func,
  clearSelected: PropTypes.func,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  currentPage: PropTypes.number,
  total: PropTypes.number,
  pageSize: PropTypes.number,
  totalPages: PropTypes.number,
  openDialog: PropTypes.func,
  deleteItem: PropTypes.func,
  allowDelete: PropTypes.bool,
}

TableComponent.defaultProps = {
  size: 'medium',
  selectedItems: [],
  allSelected: false,
  setSelectedItems: noop,
  setAllSelected: noop,
  openDialog: noop,
  deleteItem: noop,
  allowDelete: true,
  clearSelected: noop,
}

export default React.memo(TableComponent)
