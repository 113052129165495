import CheckIcon from '@mui/icons-material/Check'
import ErrorIcon from '@mui/icons-material/Error'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import TimerIcon from '@mui/icons-material/Timer'
import { CircularProgress } from '@mui/material'
import actions from './actions'
import { parametersKeys } from './formVars'
import { getSexValue, noop } from './misc'
import { toTitleCase } from './string'
import { chewAPIEcgDataToForm } from './validationSchemas'

const adminActions = ecg => {
  const isMatriz = ecg?.centerInfo?.name?.toLowerCase()?.includes('asesores matriz')
  const hasConfirmed3Times4 = Boolean(
    ecg.documentsNames.some(docName => docName.startsWith('CONFIRMED__') && docName.toLowerCase().includes('3x4_1r'))
  )
  let confirmed = hasConfirmed3Times4
  const hasConfirmed6Times2 = Boolean(
    ecg.documentsNames.some(docName => docName.startsWith('CONFIRMED__') && docName.toLowerCase().includes('6x2_1r'))
  )
  if (isMatriz) {
    confirmed = hasConfirmed3Times4 && hasConfirmed6Times2
  }

  const missing = []
  if (!hasConfirmed3Times4) missing.push('3x4_1R')
  if (!hasConfirmed6Times2) missing.push('6x2_1R')

  const canUploadTooltipText = isMatriz
    ? `Cargar trazos confirmados. Resta(n): ${missing.join(', ')}`
    : 'Cargar trazo confirmado.'
  const canDownloadTooltipText = isMatriz ? 'Trazos confirmados' : 'Trazo confirmado'
  const confirmedTraceDisplayName = confirmed ? canDownloadTooltipText : canUploadTooltipText
  const confirmedOnClickAction = hasConfirmed3Times4 ? actions.onClickViewConfirmedTrace : actions.onClickConfirmedUpload
  return [
    {
      key: 'trace',
      displayName: ecg.hasTrace ? 'Reemplazar trazo' : 'Cargar trazo',
      onClick: () => actions.onClickTrace(ecg),
      fileInput: true
    },
    {
      key: 'report',
      displayName: 'Reporte',
      onClick: actions.onReportClick,
      fileInput: false,
    },
    {
      key: 'reportQr',
      displayName: 'Generar QR de reporte',
      disabled: !ecg.isReported,
      fileInput: false,
      onClick: actions.onClickReportQr,
    },
    {
      key: 'confirmed',
      displayName: confirmedTraceDisplayName,
      onClick: () => confirmedOnClickAction(ecg),
      fileInput: !hasConfirmed3Times4,
    },
    {
      key: 'status',
      displayName: 'Estatus',
      disabled: true,
      fileInput: false,
      onClick: noop,
    }, {
      key: 'delete',
      displayName: 'Eliminar',
      onClick: actions.onClickDelete,
      mobileIconWidth: 35
    },
  ]
}

const developerActions = ecg => ([
  { key: 'edit', displayName: 'Diagnosticar', fileInput: false, onClick: actions.onEditClick },
  ...adminActions(ecg),
  { key: 'reportEmail', displayName: 'Enviar reporte por email', disabled: false, fileInput: false, onClick: actions.onClickSendReportEmail },
]).filter(opt => !(['status'].includes(opt.key)))

export const validActionsPerKind = {
  0: developerActions,
  1: adminActions,
  2: ecg => [
    { key: 'edit', displayName: 'Diagnosticar', fileInput: false, onClick: actions.onEditClick },
    { key: 'status', displayName: 'Estatus', disabled: true, fileInput: false, onClick: () => { } },
    { key: 'report', displayName: 'Reporte', fileInput: false, onClick: actions.onReportClick },
    { key: 'confirmed', displayName: 'Trazos confirmados', fileInput: false, onClick: () => actions.onClickViewConfirmedTrace(ecg) },
  ],
  3: (ecg, center) => {
    const allowedActions = [
      { key: 'report', displayName: 'Reporte', fileInput: false, onClick: actions.onReportClick },
      {
        key: 'reportQr',
        displayName: 'Generar QR de reporte',
        disabled: !ecg.isReported,
        fileInput: false,
        onClick: actions.onClickReportQr,
      },
      { key: 'confirmed', displayName: 'Trazos confirmados', fileInput: false, onClick: () => actions.onClickViewConfirmedTrace(ecg) },
      { key: 'status', displayName: 'Estatus', disabled: true, fileInput: false, onClick: () => { } },
      { key: 'reportEmail', displayName: 'Enviar reporte por email', disabled: false, fileInput: false, onClick: actions.onClickSendReportEmail },
    ]
    if (center?.canAccessOcr ?? false) {
      allowedActions.unshift({
        key: 'trace',
        displayName: ecg.hasTrace ? 'Reemplazar trazo' : 'Cargar trazo',
        onClick: () => actions.onClickTrace(ecg),
        fileInput: true
      })
    }
    return allowedActions
  },
}

export const getEcgStatus = ecg => {
  if (ecg.redCode) return 'red'
  if (ecg.rejected) return 'rejected'
  if (ecg.isReported) return 'green'
  return 'gray'
}

export const statusLedColors = {
  yellow: '#F7C107',
  green: '#87CA42',
  gray: '#C4C4C4',
  red: '#F94654',
  black: '#555555',
}

export const isBoolEmoji = bool => bool ? '✅' : '❌'

export const getLedColor = ecg => {
  if (ecg.redCode) return statusLedColors.red
  if (ecg.rejected) return statusLedColors.black
  if (ecg.urgency) return statusLedColors.yellow
  if (ecg.accepted) return statusLedColors.green
  if (ecg.hasTrace) return statusLedColors.black
  return statusLedColors.gray
}

export const validParams = [
  { field: 'id', displayValue: 'ID' },
  { field: 'studioId', displayValue: 'Folio' },
  { field: 'withInterpretation', displayValue: 'Interpretación' },
  { field: 'urgency', displayValue: 'Urgente' },
  { field: 'redCode', displayValue: 'Código Rojo' },
  { field: 'height', displayValue: 'Estatura', unit: 'cm' },
  { field: 'weight', displayValue: 'Peso', unit: 'kg' },
  { field: 'bloodPressure', displayValue: 'Presión arterial' },
  { field: 'rythm', displayValue: 'Ritmo' },
  { field: 'pAxis', displayValue: 'Eje de P', unit: '°' },
  { field: 'qrsAxis', displayValue: 'Eje de QRS', unit: '°' },
  { field: 'tAxis', displayValue: 'Eje de T', unit: '°' },
  { field: 'rR', displayValue: 'R-R', unit: 'ms' },
  { field: 'pR', displayValue: 'P-R', unit: 'ms' },
  { field: 'qrs', displayValue: 'QRS', unit: 'ms' },
  { field: 'qtm', displayValue: 'QTm', unit: 'ms' },
  { field: 'qtc', displayValue: 'QTc', unit: 'ms' },
  { field: 'stT', displayValue: 'ST-T' },
  { field: 'pacemaker', displayValue: 'Marcapasos' },
  { field: 'pacemakerKind', displayValue: 'Tipo de Marcapasos' },
  { field: 'checkReason', displayValue: 'Motivo' },
  { field: 'diagnosis', displayValue: 'Diagnóstico' },
  { field: 'notes', displayValue: 'Notas' },
  { field: 'dateReported', displayValue: 'Fecha de reporte', isDate: true },
]

const invalidToTitleCase = ['diagnosis', 'notes']

export const getParametersObj = ecg => {
  const dataArr = []
  dataArr.push({ param: 'Centro', value: ecg.centerInfo.name })
  validParams.forEach(data => {
    let value = ecg[data.field]
    if (value === null) value = '-'
    else if (typeof value === 'boolean') value = isBoolEmoji(value)
    else if (data.isDate) {
      const splitted = value.split('-')
      value = `${splitted[2]} / ${splitted[1]} / ${splitted[0]}`
    }
    else if (typeof value === 'string' && !invalidToTitleCase.includes(data.field)) value = toTitleCase(value)
    const finalVal = value !== '-' ? `${value} ${data.unit || ''}` : '-'
    dataArr.push(
      { param: data.displayValue, value: finalVal }
    )
  })
  return dataArr
}

export const chewDataForAPICreate = (values, reduxVals) => {
  const { userId, organization, centerInfo } = reduxVals
  const newVals = {
    ...values,
    owner: userId,
    centerInfo,
    bloodPressure: `${values.tas}/${values.tad}`,
    patientEmail: values.email,
  }
  delete newVals.tas
  delete newVals.tad
  if (values.isNew) {
    const fullName = `${values.names} ${values.lastNames}`
    const birthDate = new Date(
      values.birthYear,
      values.birthMonth - 1, // js expects a 'month index' from 0-11
      values.birthDay,
    ).toISOString().split('T')[0]

    const patientData = {
      name: fullName,
      sex: values.sex,
      email: values.email,
      thirdEmail: values.thirdEmail,
      owner: userId,
      organization,
      birthDate,
    }
    newVals.patient = patientData
  }
  delete newVals.sex
  delete newVals.isNew
  delete newVals.birthDay
  delete newVals.birthMonth
  delete newVals.birthYear
  delete newVals.names
  delete newVals.lastNames
  delete newVals.email
  delete newVals.thirdEmail

  newVals.lastEcgDate = null
  if (values.previousEcg) {
    const lastEcgDate = new Date(
      values.lastECGYear,
      values.lastECGMonth - 1, // idem
      values.lastECGDay,
    ).toISOString().split('T')[0]

    newVals.lastEcgDate = lastEcgDate
  }
  delete newVals.lastECGDay
  delete newVals.lastECGMonth
  delete newVals.lastECGYear
  return newVals
}

export const chewDataForAPIUpdate = (values) => {
  const newVals = {
    ...values,
    bloodPressure: `${values.tas}/${values.tad}`
  }
  delete newVals.owner
  delete newVals.diagnosisBy
  delete newVals.discountAddedBy
  delete newVals.discountAddedOn
  delete newVals.centerInfo
  delete newVals.tas
  delete newVals.tad
  delete newVals.ecg
  const birthDate = new Date(
    values.birthYear,
    values.birthMonth - 1, // js expects a 'month index' from 0-11
    values.birthDay,
  ).toISOString().split('T')[0]

  const patientData = {
    name: values.name,
    sex: values.sex,
    email: values.email,
    thirdEmail: values.thirdEmail,
    birthDate,
  }
  newVals.patient = patientData
  delete newVals.sex
  delete newVals.birthDay
  delete newVals.birthMonth
  delete newVals.birthYear
  delete newVals.names
  delete newVals.email
  delete newVals.thirdEmail

  newVals.lastEcgDate = null
  if (values.previousEcg) {
    const lastEcgDate = new Date(
      values.lastECGYear,
      values.lastECGMonth - 1, // idem
      values.lastECGDay,
    ).toISOString().split('T')[0]

    newVals.lastEcgDate = lastEcgDate
  }
  delete newVals.lastECGDay
  delete newVals.lastECGMonth
  delete newVals.lastECGYear
  parametersKeys.forEach(key => {
    if (newVals[key] === '-') newVals[key] = -9999
  })
  return newVals
}

const doctorEditableFields = [
  'notes',
  'diagnosis',
  'frequency',
  'frequencyUnit',
  'rythm',
  'pAxis',
  'qrsAxis',
  'tAxis',
  'rR',
  'pR',
  'qrs',
  'qtm',
  'qtc',
  'stT',
]
const centerEditableFields = [
  'name',
  'birthDay',
  'birthMonth',
  'birthYear',
  'sex',
  'email',
  'thirdEmail',
]

export const editableFieldsByKind = {
  0: 'all',
  1: 'all',
  2: doctorEditableFields,
  3: centerEditableFields,
}

export const isEditable = (kind, field) => {
  const editableFields = editableFieldsByKind[kind]
  if (typeof editableFields === 'string' && editableFields === 'all') return true
  if (Array.isArray(editableFields)) {
    if (editableFields.includes(field)) return true
    return false
  }
  else return false
}

export const validEcgDocuments = {
  REPORT: 'Reporte',
  TRACE: 'Trazo sin confirmar',
  '3x4_1R': 'Trazo confirmado  3x4 + 1R',
  '6x2_1R': 'Trazo confirmado  6x2 + 1R',
}

export const getPopoverInfo = ecg => {
  const chipsData = [
    { label: 'Código rojo', color: 'error', shouldRender: ecg.redCode, Icon: ErrorIcon },
    { label: 'Urgente', color: 'warning', shouldRender: ecg.urgency, Icon: TimerIcon },
    { label: 'Rechazado', color: 'default', shouldRender: ecg.rejected, Icon: ThumbDownOffAltIcon },
    { label: 'Listo', color: 'success', shouldRender: ecg.isReported, Icon: CheckIcon },
    {
      label: 'En progreso',
      color: 'info',
      shouldRender: !ecg.isReported && !ecg.rejected && ecg.hasTrace,
      Icon: CircularProgress,
      iconProps: { size: '0.8rem' },
    },
  ]
  const validChips = chipsData.filter(chipData => chipData.shouldRender)
  const bloodPressureValue = ecg.bloodPressure ? ecg.bloodPressure.replace('/', ' / ') : 'N/A'
  const summaryArr = [
    { field: 'Edad', value: ecg.patient.age },
    { field: 'Sexo', value: getSexValue(ecg.patient.sex) },
    { field: 'Presión Arterial', value: bloodPressureValue },
    { field: 'Estatura', value: `${ecg.height} cm` },
    { field: 'Peso', value: `${ecg.weight} kg` },
    { field: 'Nombre del tercero', value: ecg.thirdName },
  ].filter(element => Boolean(element.value))
  return {
    validChips,
    summaryArr,
  }
}

export const hasUnsavedData = (ecg, unsavedEcgs) => {
  const unsavedEcg = unsavedEcgs[ecg?.id] ?? false
  return unsavedEcg
}

export const persistUnsavedECGFormData = (values, newValue, field) => {
  let currentUnsavedsBuffer = window.store.selectUnsavedEcgs()
  const cachedEcgs = window.store.selectCachedEcgs()
  const initialValues = chewAPIEcgDataToForm(cachedEcgs[values.id] ?? {})

  let currentUnsaved = currentUnsavedsBuffer[values.id] ?? {}
  const newValues = { ...values }
  const fieldsChanged = currentUnsaved?.fieldsChanged ?? {}

  if (initialValues[field] === newValue) {
    // If the new value is the same as in initial values,
    // we delete that field from the fieldsChanged in the unsavedsBuffer
    // and determine wether to delete the ecg from the unsaved ones
    delete fieldsChanged[field]
    if (Object.keys(fieldsChanged).length === 0) {
      // if there are no more fields after deleting that one,
      // delete the ecg completely from unsaved
      delete currentUnsavedsBuffer[values.id]
    } else {
      // if there are more fields, just update data
      currentUnsaved['fieldsChanged'] = fieldsChanged
      currentUnsaved[field] = newValue
      currentUnsavedsBuffer[values.id] = currentUnsaved
    }
    window.store.doSetUnsavedEcgs(currentUnsavedsBuffer)
    return
  }
  newValues[field] = newValue
  currentUnsaved = { ...currentUnsaved, ...newValues }
  currentUnsaved['lastChanged'] = Date.now()

  if (fieldsChanged[field] === undefined) currentUnsaved['fieldsChanged'] = { ...fieldsChanged, [field]: true }

  currentUnsavedsBuffer[values.id] = currentUnsaved
  window.store.doSetUnsavedEcgs(currentUnsavedsBuffer)
}
