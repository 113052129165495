/* eslint-disable */

export interface AxiosConfig {
  headers: {
    'Content-Type': string,
    'Authorization': string,
  }
}

export const tokenHeader = (access: string = '', store: any = undefined) => {
  if (access === '') {
    access = store.selectAccessToken()
  }
  const config: AxiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: '',
    },
  }

  if (access) {
    config.headers.Authorization = `Bearer ${access}`
  }

  return config
}

// SET TOKEN HEADER HELPER FUNC FOR FILES
export const tokenHeaderFile = (access: string) => {
  const config: AxiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': '',
    },
  }

  if (access) {
    config.headers.Authorization = `Bearer ${access}`
  }

  return config
}

// SET TOKEN HEADER HELPER FUNC
export const tokenHeaderT = (access: string) => {
  const config: AxiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': '',
    },
  }

  if (access) {
    config.headers.Authorization = `Bearer ${access}`
  }

  return config
}
