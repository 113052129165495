import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import Table from '../../components/CommonTable/Web'
import { useConnect } from 'redux-bundler-hook'
import T from '../../components/common/Typography'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { tableDataProcessor } from '../../components/CommonTable/Web/utils'


const tableHeaderFields = [
  {
    key: 'id',
    displayValue: 'ID',
    field: 'id',
    sizePercentage: 10,
    component: Typography,
    propsMap: {
      color: 'black',
      fontWeight: 'light',
      fontSize: 14,
    },
    cellProps: {
      align: 'center',
    },
  },
  {
    key: 'key',
    displayValue: 'Key',
    field: 'key',
    sizePercentage: 10,
    component: Typography,
    propsMap: {
      color: 'black',
      fontWeight: 'light',
      fontSize: 14,
    },
    cellProps: {
      align: 'center',
    },
  },
  {
    key: 'name',
    displayValue: 'Nombre',
    field: 'name',
    sizePercentage: 10,
    component: Typography,
    propsMap: {
      color: 'black',
      fontWeight: 'light',
      fontSize: 14,
    },
    cellProps: {
      align: 'center',
    },
  },
]

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateAreas: [
      '"title title title title title"',
      '"tabs tabs tabs tabs tabs"',
      '"tabContent tabContent tabContent tabContent tabContent"',
    ].join('\n'),
    gap: 5,
    gridTemplateRows: '50px 60px auto',
    width: '100%',
    height: '100%',
  },
  title: {
    gridArea: 'title',
    display: 'flex',
    justifyContent: 'left',
    gap: 20,
    alignItems: 'center',
  },
  titleText: {
    display: 'flex',
    gap: 15,
    alignItems: 'baseline',
    justifyContent: 'space-between',
    width: '100%',
  },
  tabs: {
    gridArea: 'tabs',
  },
  content: {
    gridArea: 'tabContent',
  },
}))

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}


const Component = props => {
  const {
    doUpdateUrl,
    tableProps,
  } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Tooltip title='Atrás'>
          <IconButton onClick={() => doUpdateUrl('/')}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <div className={classes.titleText}>
          <T.H4 style={{ display: 'flex', gap: 15, alignItems: 'baseline' }}>
            Plantillas de Reporte
          </T.H4>
          <Button
            variant='contained'
            color='primary'
            onClick={() => doUpdateUrl('/reportes/plantillas/simulador/empty')}
          >
            Simulador
          </Button>
        </div>
      </div>
      <div className={classes.content}>
        <Table
          {...props}
          headers={tableHeaderFields}
          {...tableProps}
        />
      </div>
    </div>
  )
}

export default props => {
  const connectedProps = useConnect(
    'selectHTMLReportTemplateAdminTableRaw',
    'selectHTMLReportTemplateAdminCachedItems',
    'doUpdateUrl',
    'doSetHTMLReportTemplateAdminSelectedItems',
    'doSetAllHTMLReportTemplateAdminItemsSelected',
    'doClearHTMLReportTemplateAdminItemsSelected',
    'doSetHTMLReportTemplateAdminSearchValue',
    'doSetHTMLReportTemplateAdminTablePage',
    'doSetHTMLReportTemplateAdminTablePageSize',
    'doOpenHTMLReportTemplateAdminTableDialog',
    'doDeleteHTMLReportTemplateAdminTableItem',
    'doSimulatorSetSelectedHTMLTemplate',
  )
  const {
    hTMLReportTemplateAdminTableRaw: bundleRaw,
    doUpdateUrl,
    hTMLReportTemplateAdminCachedItems,
    doSimulatorSetSelectedHTMLTemplate,
  } = connectedProps
  const onViewRow = id => {
    const selected = hTMLReportTemplateAdminCachedItems[id] ?? null
    console.log(selected)
    if (selected === null) {
      console.log('No se encontró el elemento seleccionado')
      return
    }
    doSimulatorSetSelectedHTMLTemplate(selected)
    doUpdateUrl(`/reportes/plantillas/simulador/${id}`)
  }
  const tableProps = {
    processedData: tableDataProcessor(bundleRaw.items, tableHeaderFields),
    selectedItems: bundleRaw.selectedItems,
    allSelected: bundleRaw.allSelected,
    setSelectedItems: connectedProps.doSetHTMLReportTemplateAdminSelectedItems,
    setAllSelected: connectedProps.doSetAllHTMLReportTemplateAdminItemsSelected,
    clearSelected: connectedProps.doClearHTMLReportTemplateAdminItemsSelected,
    searchValue: bundleRaw.searchValue,
    setSearchValue: connectedProps.doSetHTMLReportTemplateAdminSearchValue,
    setCurrentPage: connectedProps.doSetHTMLReportTemplateAdminTablePage,
    setPageSize: connectedProps.doSetHTMLReportTemplateAdminTablePageSize,
    allowDelete: bundleRaw.allowDelete,
    pageSize: bundleRaw.pageSize,
    totalPages: bundleRaw.totalPages,
    currentPage: bundleRaw.currentPage,
    openDialog: onViewRow,
  }
  return <Component {...props} tableProps={tableProps} {...connectedProps} />
}
