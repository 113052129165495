import React from 'react'
import { makeStyles } from '@mui/styles'
import ECGLogo from '../assets/static/img/logo.png'

import LogoutIcon from '@mui/icons-material/Logout'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'
import Button from '../components/common/Button'
import SettingsIcon from '@mui/icons-material/Settings'
import EngineeringIcon from '@mui/icons-material/Engineering'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import { Badge, IconButton, Tooltip } from '@mui/material'
import { TABS } from '../containers/AdminPanel'

const useStyles = makeStyles(theme => ({
  nav: {
    backgroundColor: theme.palette.primary.main,
    gridArea: 'nav',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(5),
  },
  logo: {
    width: '66px',
    marginTop: 30,
  },
  logoutButtonDiv: {
    marginTop: 'auto',
    marginBottom: 20,
  },
}))

const WebNavbar = props => {
  const classes = useStyles()
  const {
    kind,
    userFlags = [],
    allConversationsUnreads,
    doOpenConversations,
    doOpenPreferences,
    doOpenStatsForNerdsDialog,
    doLogout,
    doUpdateUrl,
  } = props
  const hasMessagingFlag = userFlags?.includes('MESSAGING_V1')
  return (
    <div className={classes.nav}>
      <a href='/'>
        <img src={ECGLogo} className={classes.logo} />
      </a>
      {hasMessagingFlag && (
        <div>
          <Badge badgeContent={allConversationsUnreads} color='secondary'>
            <IconButton onClick={doOpenConversations}>
              <Tooltip arrow placement='top' title='Chats'>
                <SpeakerNotesIcon fontSize='large' color='white' />
              </Tooltip>
            </IconButton>
          </Badge>
        </div>
      )}
      <div>
        <IconButton onClick={doOpenPreferences}>
          <Tooltip arrow placement='top' title='Preferencias'>
            <SettingsIcon fontSize='large' color='white' />
          </Tooltip>
        </IconButton>
      </div>
      {kind <= 1 && (
        <div>
          <IconButton onClick={() => doUpdateUrl(`/administracion/${TABS[0].value}`)}>
            <Tooltip arrow placement='top' title='Administración (experimental)'>
              <ManageAccountsIcon fontSize='large' color='white' />
            </Tooltip>
          </IconButton>
        </div>
      )}
      {kind <= 1 && (
        <div>
          <IconButton onClick={() => doUpdateUrl('/reportes/plantillas')}>
            <Tooltip arrow placement='top' title='Plantillas de Reporte'>
              <IntegrationInstructionsIcon fontSize='large' color='white' />
            </Tooltip>
          </IconButton>
        </div>
      )}
      {kind === 0 && (
        <div>
          <IconButton onClick={doOpenStatsForNerdsDialog}>
            <Tooltip arrow placement='top' title='Stats for nerds'>
              <EngineeringIcon fontSize='large' color='white' />
            </Tooltip>
          </IconButton>
        </div>
      )}
      <div className={classes.logoutButtonDiv}>
        <Button
          icon={LogoutIcon}
          backgroundColor='#ffffff'
          textColor='#707070'
          size='small'
          onClick={() => doLogout(true)}
          styles={{
            '&:hover, &.Mui-focusVisible': {
              backgroundColor: '#d1d1d1',
              opacity: 1,
            },
          }}
        />
      </div>
    </div>
  )
}

export default WebNavbar
