import React from 'react'
import './switch.css'

const Switch = props => {
  const {
    checked,
    onChange,
    name,
    disabled,
  } = props

  return (
    <div>
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          name={name}
          disabled={disabled}
        />
        <span className="slider round"></span>
      </label>
    </div>
  )
}

Switch.defaultProps = {
  disabled: false,
}

export default Switch
