import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import { noop } from '../../utils/misc'
import { parseRgbStringToValues, rgbToHex, shadeRGBVals } from '../../utils/color'
import Offline from './Offline'

const ConfirmationDialog = props => {
  const {
    open,
    onClose,
    isOnline,
    truthyAction,
    truthyActionStyle,
    truthyActionText,
    setOpen,
    title,
    description,
  } = props
  const truthyButtonRgbVals = parseRgbStringToValues(truthyActionStyle?.backgroundColor)
  const shadedVals = shadeRGBVals(truthyButtonRgbVals, 0.80)
  const shadedHex = rgbToHex(shadedVals)

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      {!isOnline ? (
        <Offline />
      ) : (
        <>
          <DialogTitle
            sx={{
              fontSize: 35,
              fontFamily: 'roboto',
              justifyContent: 'center',
              marginBottom: 2,
              textAlign: 'center'
            }}>
            {title}
          </DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 40 }}>
              <Typography sx={{ fontSize: 16, fontFamily: 'roboto', textAlign: 'center' }}>
                {description}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'space-evenly' }}>
              <Button
                onClick={() => setOpen(false)}
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#717171',
                  color: '#ffffff',
                  fontFamily: 'roboto',
                  '&:hover, &.Mui-focusVisible': {
                    backgroundColor: '#3d3d3d',
                    color: 'white',
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={truthyAction}
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#87CA42',
                  color: 'black',
                  fontFamily: 'roboto',
                  '&:hover, &.Mui-focusVisible': {
                    backgroundColor: truthyActionStyle?.backgroundColor ? shadedHex : '#629431',
                  },
                  ...truthyActionStyle,
                }}
              >
                {truthyActionText || 'Aceptar'}
              </Button>
            </div>
          </DialogContent>
        </>)}
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  truthyAction: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}
ConfirmationDialog.defaultProps = {
  description: '',
  truthyActionStyle: {},
  onClose: noop,
}

export default ConfirmationDialog
