export const types = {
  SNACKBAR_SUCCESS: 'SNACKBAR_SUCCESS',
  SNACKBAR_FAIL: 'SNACKBAR_FAIL',
  SNACKBAR_INFO: 'SNACKBAR_INFO',
  SNACKBAR_INFO_CENTER: 'SNACKBAR_INFO_CENTER',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
}

const initialState = {
  open: false,
  message: '',
  severity: 'info',
  openSeconds: 10000,
  vertical: 'bottom',
  horizontal: 'center',
}

export default {
  name: 'snackbar',
  reducer: (state = initialState, action) => {
    switch (action.type) {
    case types.SNACKBAR_SUCCESS:
      return {
        ...state,
        open: true,
        message: action.payload,
        severity: 'success',
        vertical: 'bottom',
        horizontal: 'center',
      }
    case types.SNACKBAR_FAIL:
      return {
        ...state,
        open: true,
        message: action.payload,
        severity: 'error',
        vertical: 'bottom',
        horizontal: 'center',
      }
    case types.CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
      }
    case types.SNACKBAR_INFO:
      return {
        ...state,
        open: true,
        message: action.payload,
        severity: 'info',
        vertical: 'top',
        horizontal: 'right',
      }
    case types.SNACKBAR_INFO_CENTER:
      return {
        ...state,
        open: true,
        message: action.payload,
        severity: 'info',
        vertical: 'bottom',
        horizontal: 'center',
      }
    default:
      return state
    }
  },
  selectSnackbar: state => state.snackbar,
  doSetSnackbarSuccess: message => ({ dispatch }) => {
    dispatch({ type: types.SNACKBAR_SUCCESS, payload: message })
  },
  doSetSnackbarFail: message => ({ dispatch }) => {
    dispatch({ type: types.SNACKBAR_FAIL, payload: message })
  },
  doSetSnackbarInfo: message => ({ dispatch }) => {
    dispatch({ type: types.SNACKBAR_INFO, payload: message })
  },
  doSetSnackbarInfoCenter: message => ({ dispatch }) => {
    dispatch({ type: types.SNACKBAR_INFO_CENTER, payload: message })
  },
  doCloseSnackbar: () => ({ dispatch }) => dispatch({ type: types.CLOSE_SNACKBAR }),
}
